import { TCreateNotificationSettingForm } from './types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, HStack, VStack } from '@chakra-ui/react';
import {
  useContracts,
  useMerchantContractsOptions,
} from '../../hooks/contracts';
import { FC, memo, Suspense, useEffect, useMemo, useState } from 'react';
import { DropdownField, LoadingBox } from '@payler/ui-components';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';
import { TCreateNotificationSetting } from '@payler/api/merchant-cabinet';
import { useToasts } from '../../hooks/use-toasts';
import { useCreateNotificationSetting } from '../../hooks/settings/notifications';
import { Triggers } from './Triggers';
import { Emails } from './Emails';
import { NotificationType } from './NotificationType';
import { NotificationPostUrl } from './NotificationPostUrl';
import { useNotificationResolver } from './useNotificationResolver';

type Props = {
  onCancel?: VoidFunction;
  onSubmit?: VoidFunction;
};

const useDefaultValues = () => {
  const contracts = useContracts();

  return useMemo<TCreateNotificationSettingForm>(
    () => ({
      emails: [],
      contractId: contracts?.[0]?.id ?? 0,
      url: '',
      notificationType: 'Post',
      triggers: [
        'Block',
        'Unblock',
        'Refund',
        'Charge',
        'Repeatpay',
        'Credit',
        'Receipt',
        'Check',
        'Declined',
        'CardSaved',
      ],
    }),
    [contracts],
  );
};

const Comp: FC<Props> = ({ onCancel, onSubmit }) => {
  const [error, setError] = useState('');
  const { t } = useTranslation('notifications');
  const toasts = useToasts();
  const { mutateAsync, isPending: isLoading } = useCreateNotificationSetting();
  const resolver = useNotificationResolver();
  const defaultValues = useDefaultValues();
  const getError = useGetAxiosError();
  const methods = useForm<TCreateNotificationSettingForm>({
    defaultValues,
    resolver,
  });

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      onSubmit?.();
    }
  }, [methods.formState.isSubmitSuccessful, onSubmit]);

  const handleSubmit = methods.handleSubmit(async (values) => {
    setError('');
    await mutateAsync(prepareRequest(values), {
      onSuccess: () => toasts.success(t('created')),
      onError: (e) => {
        setError(getError(e));
        throw e;
      },
    });
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <VStack spacing={2} alignItems="stretch">
          <Contract />
          <Triggers />
          <NotificationType />
          <Emails />
          <NotificationPostUrl />

          {error && <ApiErrorText>{error}</ApiErrorText>}
          <HStack pt={3} spacing={2}>
            <Button
              flex={1}
              variant="secondary"
              onClick={onCancel}
              isDisabled={isLoading}
            >
              {t('cancel')}
            </Button>
            <Button flex={1} onClick={handleSubmit} isLoading={isLoading}>
              {t('create')}
            </Button>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  );
};

export const CreateNotificationSettingForm = memo((props: Props) => (
  <Suspense fallback={<LoadingBox h="700px" />}>
    <Comp {...props} />
  </Suspense>
));

export default CreateNotificationSettingForm;

const Contract = () => {
  const { t } = useTranslation();
  const options = useMerchantContractsOptions();
  return (
    <DropdownField
      options={options}
      fieldName="contractId"
      floating
      label={t('users:contracts')}
      hideClearButton
    />
  );
};

const prepareRequest = (
  formData: TCreateNotificationSettingForm,
): TCreateNotificationSetting => {
  return {
    contractId: formData.contractId,
    notificationType: formData.notificationType,
    notificationReceivers:
      formData.notificationType === 'Email' ? formData.emails : [formData.url],
    triggers: formData.triggers,
  };
};
