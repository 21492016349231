import { TInterfaceLanguage } from '@payler/api/merchant-cabinet';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import createLogger from 'debug';
import { ALL_LANGS } from '../const';

const log = createLogger('ConfigProvider');

const LANGUAGE_KEY = 'lang';
const APP_FLAGS = ['reports.ru_global_splitting'] as const;
type TAppFlags = Record<(typeof APP_FLAGS)[number], boolean>;
export type TMerchantCabinetLanguage = 'en' | 'ru';
export type TMerchantCabinetEnvConfig = {
  apiServer: string;
  googleRecaptchaKey: string;
  googleTagManagerId: string;
  disableGoogleRecaptchaAtLogin?: boolean;
  flags: TAppFlags;
};

export type TMerchantCabinetAppContext = {
  /**
   * Сервер
   * @example http://mc.charlie30.xyz
   */
  apiServer: string;
  /**
   * Язык
   */
  language: TMerchantCabinetLanguage;
  /**
   * язык для api
   */
  culture: TInterfaceLanguage;
  /**
   * ключ для google recaptcha
   */
  googleRecaptchaKey: string;
  /**
   * id для google tag manager
   */
  googleTagManagerId: string;
  /**
   * Не слать токен рекапчи при логине
   */
  disableGoogleRecaptchaAtLogin?: boolean;
  /**
   * Флаги для удобного управления функционалом на разных средах
   */
  featureFlags: TAppFlags;
  /**
   * Установить язык системы
   */
  setLanguage: (lang: TMerchantCabinetLanguage) => void;
};

const ctx = createContext<TMerchantCabinetAppContext>({
  apiServer: '',
  language: 'ru',
  culture: 'ru-RU',
  googleRecaptchaKey: '',
  googleTagManagerId: '',
  setLanguage: () => {},
  featureFlags: {} as TAppFlags,
});

export const useMerchantCabinetConfig = () => useContext(ctx);
export const useCheckMerchantCabinetFeatureFlag = (
  key: (typeof APP_FLAGS)[number],
) => useContext(ctx).featureFlags[key];

type ProviderProps = {
  env: TMerchantCabinetEnvConfig;
  /**
   * Для storybook
   */
  skipLanguageInit?: boolean;
};

export const ConfigProvider: FCC<ProviderProps> = ({
  children,
  env,
  skipLanguageInit,
}) => {
  const [languageInitialized, setLanguageInitialized] =
    useState(skipLanguageInit);

  const [config, setConfig] = useState<TMerchantCabinetAppContext>(() =>
    createConfig(env),
  );

  const {
    i18n: { language: i18lang, changeLanguage },
  } = useTranslation();

  const setLanguage = useCallback(
    (language: TMerchantCabinetLanguage) => {
      if (config.language === language) return;
      if (!ALL_LANGS.includes(language)) {
        language = ALL_LANGS[0] as TMerchantCabinetLanguage;
      }
      const culture = getCulture(language);
      localStorage.setItem(LANGUAGE_KEY, language);
      setConfig((v) => ({
        ...v,
        culture,
        language,
      }));
    },
    [config.language],
  );

  useEffect(() => {
    if (!languageInitialized) {
      if (config.language !== i18lang) {
        log('useEffect changeLanguage %s => %s', i18lang, config.language);
        changeLanguage(config.language)
          .then(() => {
            setLanguageInitialized(true);
            setLanguage(config.language);
          })
          .catch(() => log('oops'));
      } else {
        log('useEffect changeLanguage noop (%s)', config.language);
        setLanguageInitialized(true);
      }
    }
  }, [
    changeLanguage,
    config.language,
    i18lang,
    languageInitialized,
    setLanguage,
  ]);

  useEffect(() => {
    const l = i18lang as TMerchantCabinetLanguage;
    setLanguage(l);
    setLanguageInitialized(true);
    log('useEffect setConfig');
  }, [i18lang, languageInitialized, setLanguage]);

  const ctxValue = useMemo(
    () => ({ ...config, setLanguage }),
    [config, setLanguage],
  );

  if (!languageInitialized) return null;
  return <ctx.Provider value={ctxValue}>{children}</ctx.Provider>;
};

export function getCulture(
  language?: TMerchantCabinetLanguage,
): TInterfaceLanguage {
  switch (language) {
    case 'ru':
      return 'ru-RU';
    case 'en':
    default:
      return 'en-US';
  }
}

export function getLanguage(
  language?: TInterfaceLanguage,
): TMerchantCabinetLanguage {
  switch (language) {
    case 'ru-RU':
      return 'ru';
    case 'en-US':
    default:
      return 'en';
  }
}

function createConfig(
  env: TMerchantCabinetEnvConfig,
): TMerchantCabinetAppContext {
  const languageSaved = localStorage.getItem(LANGUAGE_KEY) ?? ALL_LANGS[0];
  const language = (
    ALL_LANGS.includes(languageSaved ?? '') ? languageSaved : ALL_LANGS[0]
  ) as TMerchantCabinetLanguage;

  const result: TMerchantCabinetAppContext = {
    language,
    culture: getCulture(language),
    apiServer: env.apiServer.replace(/\/$/, ''),
    googleRecaptchaKey: env.googleRecaptchaKey,
    googleTagManagerId: env.googleTagManagerId,
    disableGoogleRecaptchaAtLogin: env.disableGoogleRecaptchaAtLogin ?? false,
    featureFlags: env.flags ?? {},
    setLanguage: () => {},
  };
  log('createConfig: %O', result);
  return result;
}
