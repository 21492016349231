import { chakra } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export const HelpText = chakra('div', {
  baseStyle: {
    color: 'primary.350',
    mt: 1,
    textStyle: TextStyles.Caption12Regular,
    px: 1.5,
  },
});
