import { Box, useBreakpointValue } from '@chakra-ui/react';
import Menu from '../../components/Menu/Menu';
import { Outlet } from 'react-router-dom';
import { MENU_WIDTH, MOBILE_HEADER_HEIGHT } from './const';
import { breakpoints, emotionColors } from '@payler/ui-theme';
import styled from '@emotion/styled';
import MobileMenu from '../../components/Menu/MobileMenu';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import { ErrorBoundary } from '@payler/ui-components';
import ErrorPage from '../../pages/ErrorPage';

const Wrap = styled.main`
  display: grid;
  grid-template-areas: 'nav' 'content';
  * {
    -webkit-tap-highlight-color: transparent;
  }
`;

export function BaseLayout() {
  return (
    <Wrap>
      <Content>
        <ErrorBoundary errorPage={<ErrorPage />}>
          <Outlet />
        </ErrorBoundary>
      </Content>
      <ErrorBoundary errorPage={<ErrorPage />}>
        <Nav />
      </ErrorBoundary>
    </Wrap>
  );
}

const Nav = () => {
  const desktop = useBreakpointValue({ base: false, sm: true });
  return desktop ? <DesktopMenu /> : <MobileMenu />;
};

const Content = styled.div`
  grid-area: content;
  min-height: calc(100vh - ${MOBILE_HEADER_HEIGHT}px);
  padding: 16px;
  background-color: ${emotionColors.bg};
  @media (min-width: ${breakpoints.sm}) {
    min-height: 100vh;
    padding: 40px 24px 24px ${MENU_WIDTH + 24}px;
  }
`;

const DesktopMenu = () => {
  return (
    <Box
      gridArea="nav"
      position="fixed"
      top={0}
      left={0}
      height="100vh"
      width={`${MENU_WIDTH}px`}
    >
      <Menu />
    </Box>
  );
};

export default BaseLayout;
