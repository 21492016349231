import { chakra, HStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import React, { FC } from 'react';
import { MayBe } from '@payler/api/merchant-cabinet';

/** Строка в шаблоне фильтров */
export const TemplateLabeledValue: FC<{
  label: string;
  value?: MayBe<string | number | JSX.Element>;
}> = ({ label, value }) => {
  if (!value) return null;
  return (
    <HStack justifyContent="space-between">
      <chakra.span
        __css={{
          textStyle: TextStyles.Caption12Regular,
          color: 'primary.350',
          mr: 2,
          flexShrink: 0,
        }}
      >
        {label}
      </chakra.span>
      <chakra.span
        __css={{
          textStyle: TextStyles.Caption12Regular,
          color: 'primary.500',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {value}
      </chakra.span>
    </HStack>
  );
};
