import { useCallback } from 'react';
import { LoginParams } from '@payler/api/merchant-cabinet';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useMerchantCabinetConfig } from '../../config/ConfigProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useResetRecoil } from '../../config/AppState';
import { setTokenAndDispatchEvent } from '../../context/AuthContextProvider';
import { useApi } from '../../context/ApiContextProvider';

export const useLogin = () => {
  const api = useApi();
  const { disableGoogleRecaptchaAtLogin } = useMerchantCabinetConfig();
  const { executeRecaptcha } = useGoogleReCaptcha();
  return useMutation({
    mutationFn: async ({ email, password }: LoginParams) => {
      let captcha;
      if (!disableGoogleRecaptchaAtLogin && executeRecaptcha) {
        captcha = await executeRecaptcha('login');
      }
      return api.login({
        email,
        password,
        captcha,
      });
    },
    onSuccess: ({ token }) => {
      setTokenAndDispatchEvent(token);
    },
  });
};

export const useLogout = () => {
  const reset = useResetRecoil();
  const client = useQueryClient();
  return useCallback(() => {
    setTokenAndDispatchEvent(null);
    setTimeout(() => {
      client.clear();
      reset();
    }, 0);
  }, [client, reset]);
};

export const useResetPassword = () => {
  const api = useApi();
  return useMutation({
    mutationFn: (email: string) => api.resetPassword(email),
  });
};

export const useSetNewPassword = (mode: 'reset' | 'confirm' = 'reset') => {
  const api = useApi();
  return useMutation({
    mutationFn: (params: { userId: string; code: string; password: string }) =>
      api.submitNewPassword(params, mode),
  });
};
