import { Box, Button } from '@chakra-ui/react';
import React from 'react';
import { SuccessBigIcon } from '@payler/ui-icons';
import { NotificationWithIcon } from '@payler/ui-components';

export const AuthSuccess: React.FC<{
  onSubmitSuccess: () => void;
  title: string;
  text: string;
  submitText: string;
}> = ({ onSubmitSuccess, title, text, submitText }) => {
  return (
    <Box
      mt={{ base: 24, sm: 16 }}
      display="flex"
      flexDirection="column"
      alignItems="center"
      w="100%"
    >
      <NotificationWithIcon title={title} text={text} icon={SuccessBigIcon} />
      <Box
        display="flex"
        w="100%"
        position={{ base: 'fixed', sm: 'initial' }}
        left="0"
        right="0"
        bottom={3}
        pl={{ base: 2, sm: 0 }}
        pr={{ base: 2, sm: 0 }}
      >
        <Button
          onClick={onSubmitSuccess}
          mt={6}
          type="submit"
          variant="primary"
          w="100%"
        >
          {submitText}
        </Button>
      </Box>
    </Box>
  );
};

export default AuthSuccess;
