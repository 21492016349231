import { TCurrency } from '@payler/api/merchant-cabinet';
import createLogger from 'debug';
import { TMerchantCabinetLanguage } from '../config/ConfigProvider';
import { useCallback, useMemo } from 'react';
import { isNil } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../context/ApiContextProvider';

const log = createLogger('state:currencies-info');

const CURRENCIES_STALE_TIME = 24 * 60 * 6e5;
const useCurrenciesQuery = () => {
  const api = useApi();
  return useQuery({
    queryKey: ['currencies'],
    queryFn: () => {
      return api.getCurrenciesInfo();
    },
    staleTime: CURRENCIES_STALE_TIME,
  });
};

/**
 * Коды всех валют
 */
export const useAllCurrencies = () => {
  const { data } = useCurrenciesQuery();
  return data?.map((x) => x.code) ?? [];
};

/**
 * Разрядность валют
 */
const useCurrencyDecimalsInfo = (): Partial<Record<TCurrency, number>> => {
  const { data } = useCurrenciesQuery();
  return useMemo(
    () =>
      (data ?? []).reduce(
        (prev, current) => ({ ...prev, [current.code]: current.decimalsCount }),
        {},
      ),
    [data],
  );
};

/**
 * Символы валют
 */
const useCurrencySymbolsInfo = (): Partial<Record<TCurrency, string>> => {
  const { data } = useCurrenciesQuery();
  return useMemo(() => {
    return (data ?? []).reduce(
      (prev, current) => ({ ...prev, [current.code]: current.symbol }),
      {},
    );
  }, [data]);
};

/**
 * Локализация валют
 */
export const useCurrencyTranslation = () => {
  const { data = [] } = useCurrenciesQuery();
  return useMemo(() => {
    const ru: Partial<{ [key in TCurrency]: string }> = data.reduce(
      (prev, current) => ({
        ...prev,
        [current.code]: current.names.find((x) => x.cultureName === 'ru-RU')
          ?.name,
      }),
      {},
    );
    const en: Partial<{ [key in TCurrency]: string }> = data.reduce(
      (prev, current) => ({
        ...prev,
        [current.code]: current.names.find((x) => x.cultureName === 'en-US')
          ?.name,
      }),
      {},
    );

    const res: TCurrencyTranslation = { ru, en };
    log('currencyTranslationSelector: %O', res);
    return res;
  }, [data]);
};

type TCurrencyTranslation = {
  [key in TMerchantCabinetLanguage]: Partial<{ [key in TCurrency]: string }>;
};

/**
 * функции для работы со словарём валют
 */
export const useCurrencyCallbacks = () => {
  const symbols = useCurrencySymbolsInfo();
  const data = useCurrencyDecimalsInfo();
  const getCurrencyDecimalsCount = useCallback(
    (currency?: TCurrency | null) => {
      if (isNil(currency)) {
        log('WARN: getCurrencyDecimalsCount currency undefined or null');
        return 2;
      }
      return data[currency] ?? 2;
    },
    [data],
  );
  const getCurrencyUnitRate = useCallback(
    (currency?: TCurrency | null) => {
      if (isNil(currency)) {
        log('WARN: getCurrencyUnitRate currency undefined or null');
      }
      return 10 ** getCurrencyDecimalsCount(currency);
    },
    [getCurrencyDecimalsCount],
  );

  const getCurrencySymbol = useCallback(
    (currency?: TCurrency | null) => {
      if (isNil(currency)) {
        log('WARN: getCurrencySymbol currency undefined or null');
        return '';
      }
      return symbols[currency] ?? '';
    },
    [symbols],
  );
  return {
    getCurrencyUnitRate,
    getCurrencyDecimalsCount,
    getCurrencySymbol,
  };
};
