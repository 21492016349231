export const usersEn = {
  cancel: 'Cancel',
  contracts: 'Available accounts',
  created: 'User successfully added',
  createUser: 'Add',
  delete: 'Delete',
  deleteDescription: 'Are you sure you want to delete this user?',
  deleteTitle: 'Delete user',
  email: 'Email',
  invalidEmail: 'Enter a valid e-mail',
  invalidName: 'Enter a full name',
  name: 'User',
  newUser: 'New user',
  options: 'Available options',
  updated: 'Data edited successfully',
  updateUser: 'Save',
};

type TUsersTranslation = typeof usersEn;

export const usersRu: TUsersTranslation = {
  cancel: 'Отмена',
  contracts: 'Доступные аккаунты',
  created: 'Пользователь успешно добавлен',
  createUser: 'Добавить',
  delete: 'Удалить',
  deleteDescription: 'Вы действительно хотите удалить этого пользователя?',
  deleteTitle: 'Удаление пользователя',
  email: 'Email',
  invalidEmail: 'Введите корректный email',
  invalidName: 'Укажите имя',
  name: 'Пользователь',
  newUser: 'Новый пользователь',
  options: 'Доступные опции',
  updated: 'Данные успешно отредактированы',
  updateUser: 'Сохранить',
};
