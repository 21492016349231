import en from './en';
import ru from './ru';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import createLogger from 'debug';

const log = createLogger('i18n');

export const i18nResources = {
  en,
  ru,
} as const;

i18n
  .use(initReactI18next)
  .init({
    lng: 'ru',
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'common',
    resources: i18nResources,
    fallbackLng: 'en',
  })
  .then(() => log('initialized'));
