import { TTransactionWithHistory } from '@payler/api/merchant-cabinet';
import { chakra, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import TimelineItem, { Timeline } from '../Timeline/Timeline';
import dayjs from 'dayjs';
import { Card, CardContent, CardHead, CardTitle } from '@payler/ui-components';

export const PaymentHistory = ({
  history,
}: {
  history: TTransactionWithHistory['history'];
}) => {
  const { t } = useTranslation('payments');
  const total = history.length;
  return (
    <Card>
      <CardHead>
        <CardTitle>{t('historyTitle')}</CardTitle>
      </CardHead>
      <CardContent p={0}>
        <Timeline>
          {history.map((h, i) => {
            const error = !!(h.paylerError ?? h.processingError);
            const dt = dayjs(h.createdAt).format('DD.MM.YYYY HH:mm');
            return (
              <TimelineItem key={i} last={total === i + 1} error={error}>
                <Text
                  textStyle={TextStyles.Caption12Regular}
                  color="primary.350"
                >
                  {dt}
                </Text>
                <VStack alignItems="flexStart" spacing={1}>
                  {h.status && (
                    <Text
                      color="primary.500"
                      textStyle={TextStyles.Subtitle14Regular}
                    >
                      {h.status}
                    </Text>
                  )}
                  {h.paylerError && (
                    <Text
                      color="primary.500"
                      textStyle={TextStyles.Subtitle14Regular}
                    >
                      <chakra.span textStyle={TextStyles.Subtitle14Semibold}>
                        {t('paylerError')}
                      </chakra.span>{' '}
                      {h.paylerError}
                    </Text>
                  )}
                  {h.processingError && (
                    <Text
                      color="primary.500"
                      textStyle={TextStyles.Subtitle14Regular}
                    >
                      <chakra.span textStyle={TextStyles.Subtitle14Semibold}>
                        {t('processingError')}
                      </chakra.span>{' '}
                      {h.processingError}
                    </Text>
                  )}
                </VStack>
              </TimelineItem>
            );
          })}
        </Timeline>
      </CardContent>
    </Card>
  );
};

export default PaymentHistory;
