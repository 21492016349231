import { createRoot } from 'react-dom/client';
import App from './app/App';
import * as Sentry from '@sentry/react';
import { Replay } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: 'https://e47b36c8f40c4cec9c63f0be3c478498@o4504331747262464.ingest.sentry.io/4504820049248256',
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['https://my.payler.com/'],
    }),
    new Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
