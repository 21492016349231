import { chakra, StackProps, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

const truncateStyles = {
  h: '22px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const LabeledValue = ({
  label,
  children,
  ellipsis,
  ...props
}: { label: string | undefined; ellipsis?: boolean } & Omit<
  StackProps,
  'direction'
>) => {
  const valueProps = ellipsis ? truncateStyles : {};
  return (
    <VStack alignItems="stretch" spacing={0} {...props}>
      <Label>{label}</Label>
      <Value {...valueProps}>{children}</Value>
    </VStack>
  );
};

const Label = chakra('div', {
  baseStyle: {
    textStyle: TextStyles.Caption12Regular,
    color: 'primary.350',
  },
});

const Value = chakra('div', {
  baseStyle: {
    textStyle: TextStyles.tables,
    color: 'primary.500',
    minH: '22px',
  },
});
