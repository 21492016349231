import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  ScrollBarWithFade,
} from '@payler/ui-components';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  chakra,
  HStack,
  Text,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FullWidthBlock, PageTitle } from '../layouts';
import React, { Suspense } from 'react';
import styled from '@emotion/styled';
import { breakpoints, TextStyles } from '@payler/ui-theme';
import { RecreateInvoiceModal } from '../modals/RecreateInvoiceModal';
import { WithdrawForm } from '../forms/WithdrawForm/WithdrawForm';
import { useBalancesQuery, useSelectedContract } from '../hooks/contracts';
import NumberFormat from 'react-number-format';
import WithdrawalsTable from '../tables/PaymentsTable/WithdrawalsTable';
import { useCurrencyCallbacks } from '../hooks/currencies-info';
import { Helmet } from 'react-helmet-async';

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: 'balances' 'form' 'table';
  grid-gap: 16px;
  @media (min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-template-areas: 'balances balances' 'form table';
    align-items: flex-start;
  }
`;

export const WithdrawalsPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={t('titles:withdraw')} />
      <Suspense fallback={null}>
        <InvoicesPageHeader />
      </Suspense>

      <Suspense fallback={null}>
        <Balances />
      </Suspense>

      <Wrap>
        <TableCard />
        <FormCard />
      </Wrap>
      <Suspense fallback={null}>
        <RecreateInvoiceModal />
      </Suspense>
    </>
  );
};

const Balances = () => {
  const contract = useSelectedContract();
  const { data: balances } = useBalancesQuery(contract?.id);
  const { getCurrencyUnitRate, getCurrencyDecimalsCount, getCurrencySymbol } =
    useCurrencyCallbacks();
  if (!balances) return null;
  return (
    <FullWidthBlock gridArea="balances">
      <ScrollBarWithFade>
        <HStack spacing={2} minW="100%" justifyContent="space-between">
          {balances.map((x, i) => (
            <Card key={i} minW="250px" borderRadius={1.5} flex={1}>
              <CardContent>
                <chakra.h5
                  textStyle={TextStyles.Caption12Regular}
                  color="primary.350"
                  mb={1}
                >
                  {x.name}
                </chakra.h5>
                <chakra.p textStyle={TextStyles.h4}>
                  <NumberFormat
                    displayType="text"
                    suffix={`\u00a0${getCurrencySymbol(x.currencyCode)}`}
                    decimalScale={getCurrencyDecimalsCount(x.currencyCode)}
                    fixedDecimalScale
                    thousandSeparator={' '}
                    value={x.amount / getCurrencyUnitRate(x.currencyCode)}
                  />
                </chakra.p>
              </CardContent>
            </Card>
          ))}
        </HStack>
      </ScrollBarWithFade>
    </FullWidthBlock>
  );
};

const TableCard = () => {
  const { t } = useTranslation();
  return (
    <FullWidthBlock gridArea="table">
      <Card>
        <CardHead>
          <CardTitle>{t('withdrawals:listTitle')}</CardTitle>
        </CardHead>
        <CardContent p={0}>
          <WithdrawalsTable />
        </CardContent>
      </Card>
    </FullWidthBlock>
  );
};

const FormCard = () => {
  const { t } = useTranslation();
  return (
    <FullWidthBlock gridArea="form">
      <Card>
        <CardHead>
          <CardTitle>{t('withdrawals:formTitle')}</CardTitle>
        </CardHead>
        <CardContent>
          <WithdrawForm />
        </CardContent>
      </Card>
    </FullWidthBlock>
  );
};

const InvoicesPageHeader = () => {
  const { t } = useTranslation();
  const contract = useSelectedContract();
  return (
    <HStack
      alignItems="flex-end"
      justifyContent="space-between"
      mb={{ base: 2, sm: 5 }}
    >
      <div>
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              {t('home')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Text}>{contract?.name}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Text}>{t('withdrawals:title')}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageTitle mb={0}>{t('withdrawals:title')}</PageTitle>
      </div>
    </HStack>
  );
};

export default WithdrawalsPage;
