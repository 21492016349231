import React, { FC } from 'react';
import { Box, CloseButton, useBreakpointValue } from '@chakra-ui/react';

export const MobileModalCloseButton: FC<{ close: VoidFunction }> = ({
  close,
}) => {
  const visible = useBreakpointValue({ base: true, sm: false });
  if (!visible) return null;
  return (
    <Box p={1.5} borderBottom="0.5px solid" borderColor="primary.100">
      <CloseButton onClick={close} />
    </Box>
  );
};

export default MobileModalCloseButton;
