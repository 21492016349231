import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  IconButton,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import { PageTitle } from '../layouts';
import { Link } from 'react-router-dom';
import { PlusCircleIcon16 } from '@payler/ui-icons';
import { ReportsTable } from '../tables/ReportsTable/ReportsTable';
import { DeleteReportModal } from '../modals/DeleteReportModal';
import {
  NewReportModal,
  useShowNewReportModal,
} from '../modals/NewReportModal';
import { useTranslation } from 'react-i18next';
import { useUserPermissions } from '../hooks/user/users';
import { Helmet } from 'react-helmet-async';

export const ReportsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={t('titles:reports')} />
      <Header />
      <ReportsTable />
      <DeleteReportModal />
      <NewReportModal />
    </>
  );
};

const Header = () => {
  const showNewReportModal = useShowNewReportModal();
  const { canCreateReports } = useUserPermissions();
  const mobile = useBreakpointValue({ base: true, sm: false });
  const { t } = useTranslation('reports');

  return (
    <HStack
      alignItems="flex-end"
      justifyContent="space-between"
      mb={{ base: 2, sm: 5 }}
    >
      <div>
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              {t('common:home')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/reports">
              {t('title')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageTitle mb={0}>{t('title')}</PageTitle>
      </div>
      {mobile ? (
        <Tooltip
          label={!canCreateReports ? t('common:noAccess') : ''}
          shouldWrapChildren
        >
          <IconButton
            disabled={!canCreateReports}
            aria-label={t('newReport')}
            icon={<PlusCircleIcon16 />}
            p={3}
            py={2}
            onClick={showNewReportModal}
          />
        </Tooltip>
      ) : (
        <Tooltip
          label={!canCreateReports ? t('common:noAccess') : ''}
          shouldWrapChildren
        >
          <Button disabled={!canCreateReports} onClick={showNewReportModal}>
            {t('newReport')}
          </Button>
        </Tooltip>
      )}
    </HStack>
  );
};

export default ReportsPage;
