import { TPayout } from '@payler/api/merchant-cabinet';
import { useTranslation } from 'react-i18next';
import { HStack, Text, Tooltip, useClipboard, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { CardTypeIcon } from './CardTypeIcon';
import React, { MouseEventHandler, useCallback } from 'react';

export const CardAndOrderIdCell = ({ data }: { data: TPayout }) => {
  const { t } = useTranslation();
  const { hasCopied, onCopy } = useClipboard(data.merchantOrderId ?? '');
  const handleCopy: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      onCopy();
    },
    [onCopy],
  );
  return (
    <VStack alignItems="flex-end" spacing={0}>
      <HStack>
        <Text textStyle={TextStyles.tables}>
          {(data.panMask ?? '').replace(/x/g, '·')}{' '}
        </Text>
        <CardTypeIcon type={data.cardTypeName} />
      </HStack>
      <Tooltip
        isOpen={hasCopied || undefined}
        label={hasCopied ? t('copied') : t('clickToCopy')}
        hasArrow
        placement="top"
      >
        <Text
          cursor="pointer"
          textStyle={TextStyles.Caption12Regular}
          color="primary.350"
          maxW={{ base: '140px', lg: '350px' }}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          onClick={handleCopy}
        >
          ID:&nbsp;{data.merchantOrderId}
        </Text>
      </Tooltip>
    </VStack>
  );
};
