import { Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import TimelineItem, { Timeline } from '../Timeline/Timeline';
import dayjs from 'dayjs';
import { Card, CardContent, CardHead, CardTitle } from '@payler/ui-components';
import { TFasterPayoutComputedHistoryItem } from '../../hooks/faster-payouts/types';

export const FasterPayoutHistory = ({
  history,
}: {
  history: TFasterPayoutComputedHistoryItem[];
}) => {
  const { t } = useTranslation('fasterPayouts');
  const total = history.length;
  return (
    <Card>
      <CardHead>
        <CardTitle>{t('history')}</CardTitle>
      </CardHead>
      <CardContent p={0}>
        <Timeline>
          {history.map((h, i) => {
            const error = h.status === 'Failed';
            const dt = dayjs(h.createdAt).format('DD.MM.YYYY HH:mm:ss');
            return (
              <TimelineItem key={i} last={total === i + 1} error={error}>
                <Text
                  textStyle={TextStyles.Caption12Regular}
                  color="primary.350"
                >
                  {dt}
                </Text>
                <VStack alignItems="flexStart" spacing={1}>
                  <Text
                    color="primary.500"
                    textStyle={TextStyles.Subtitle14Regular}
                  >
                    {h.typeDescription} {h.statusDescription.toLowerCase()}
                  </Text>
                </VStack>
              </TimelineItem>
            );
          })}
        </Timeline>
      </CardContent>
    </Card>
  );
};

export default FasterPayoutHistory;
