import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Fade,
  HStack,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FullWidthBlock, PageTitle } from '../../layouts';
import React, { Suspense } from 'react';
import styled from '@emotion/styled';
import { useUserInfo, useUserPermissions } from '../../hooks/user/users';
import UserCard from '../../components/UserCard/UserCard';
import {
  CreateUserModal,
  useShowCreateUserModal,
} from '../../modals/CreateUserModal';
import { DeleteUserModal } from '../../modals/DeleteUserModal';
import { LoadingBox } from '@payler/ui-components';
import { Helmet } from 'react-helmet-async';

export const Wrap = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

export const UsersPage = () => {
  return (
    <Wrap>
      <Suspense fallback={null}>
        <UsersPageHeader />
      </Suspense>
      <FullWidthBlock>
        <Suspense fallback={null}>
          <MobileButtons />
        </Suspense>
        <Suspense fallback={<LoadingBox />}>
          <UsersList />
        </Suspense>
      </FullWidthBlock>
      <CreateUserModal />
      <DeleteUserModal />
    </Wrap>
  );
};

const UsersList = () => {
  const users = useUserInfo()?.childUsers ?? [];
  return (
    <Stack flexDir="column" spacing={2}>
      {users.map((x) => (
        <UserCard key={x.id} user={x} />
      ))}
    </Stack>
  );
};

const UsersPageHeader = () => {
  const { canCreateUsers } = useUserPermissions();
  const showModal = useShowCreateUserModal();
  const desktop = useBreakpointValue({ base: false, md: true });
  const { t } = useTranslation();
  if (!desktop) return null;
  return (
    <HStack
      alignItems="flex-start"
      justifyContent="space-between"
      mb={5}
      w="100%"
      maxW="1200px"
    >
      <Helmet
        title={`${t('titles:settings')} :: ${t('settings:users.title')}`}
      />
      <div>
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              {t('home')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/settings">
              {t('settings:title')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/settings/users">
              {t('settings:users.title')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageTitle mb={0}>{t('settings:users.title')}</PageTitle>
      </div>
      {canCreateUsers && (
        <Button onClick={showModal}>{t('users:createUser')}</Button>
      )}
    </HStack>
  );
};

const MobileButtons = () => {
  const { canCreateUsers } = useUserPermissions();
  const showModal = useShowCreateUserModal();
  const visible = useBreakpointValue({ base: true, md: false });
  const { t } = useTranslation();
  if (!canCreateUsers) return null;
  if (!visible) return null;
  return (
    <Fade in={visible}>
      <Box justifyContent="stretch" bg="brands.500" p={2}>
        <Button
          variant="link"
          color="secondary.500"
          onClick={showModal}
          w="100%"
        >
          {t('users:createUser')}
        </Button>
      </Box>
    </Fade>
  );
};

export default UsersPage;
