import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';
import { useCallback } from 'react';

export const useShowPermissionsWarning = () => {
  const { t } = useTranslation();
  const toast = useToast();
  return useCallback(() => {
    toast({
      position: 'top-right',
      status: 'error',
      description: t('noAccess'),
      isClosable: true,
    });
  }, [t, toast]);
};
