import { MayBe, TInvoiceTemplate } from '@payler/api/merchant-cabinet';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';
import { useApi } from '../../context/ApiContextProvider';
import { DEFAULT_PAYMENT_PAGE } from '../../const';
import {
  useDefaultInvoicePaymentPageType,
  useInvoicePaymentPageTypeChecker,
} from './queries';

export const useDeleteInvoiceTemplateMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const contractId = useURLContractId();
  return useMutation({
    mutationFn: (id: number) => api.deleteTemplate(id),
    onSuccess: (ok, id) => {
      if (ok) {
        queryClient.setQueryData<TInvoiceTemplate[]>(
          ['invoices', 'templates', contractId],
          (old) => old?.filter((x) => x.id !== id),
        );
      }
    },
  });
};

export const useSaveInvoiceTemplateMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  const contractId = useURLContractId();
  if (!contractId) throw new Error('no contractId');
  return useMutation({
    mutationFn: (template: Omit<TInvoiceTemplate, 'id'>) =>
      api.saveInvoicesTemplate(template, contractId),
    onSuccess: (newTemplate) => {
      queryClient.setQueryData<TInvoiceTemplate[]>(
        ['invoices', 'templates', contractId],
        (old) => (old ? [...old, newTemplate] : old),
      );
    },
  });
};

export const useTemplatePaymentPageType = (
  templatePaymentPageType: MayBe<string>,
) => {
  const hasPaymentPageTypeInList = useInvoicePaymentPageTypeChecker();
  const defaultInvoicePaymentPageType = useDefaultInvoicePaymentPageType();

  /**
   *  Если указанный в шаблоне вид платёжной страницы имеет пустую строку или
   *  не существует вовсе или имеет значение `default`,
   *  то тогда в списке выбирается `default`
   */
  if (
    !templatePaymentPageType ||
    templatePaymentPageType === DEFAULT_PAYMENT_PAGE
  ) {
    return DEFAULT_PAYMENT_PAGE;
  }

  /**
   * Если указанный в шаблоне вид платёжной страницы существует, тогда мы заполняем им
   * Иначе тогда мы заполняем вид платёжной страницы из параметров
   * `lastInvoiceParams` или `default` если такой страницы больше нет
   */
  return hasPaymentPageTypeInList(templatePaymentPageType)
    ? templatePaymentPageType
    : defaultInvoicePaymentPageType;
};
