import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormOption } from '../components/FormOption/FormOption';

export interface CheckboxFieldProps
  extends Omit<CheckboxProps, 'defaultValue'> {
  name: string;
  defaultValue?: boolean;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { name, defaultValue, ...rest } = props;
  const errorMessage = errors[name]?.message;
  return (
    <FormOption
      isInvalid={!!errorMessage}
      errorMessage={errorMessage as string | undefined}
    >
      <Controller
        control={control}
        name={name}
        defaultValue={!!defaultValue}
        render={({ field: { onChange, value, ref } }) => (
          <Checkbox {...rest} onChange={onChange} ref={ref} isChecked={value} />
        )}
      />
    </FormOption>
  );
};
CheckboxField.displayName = 'CheckboxField';
