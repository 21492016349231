import { chakra, CSSWithMultiValues, VStack } from '@chakra-ui/react';

export const TemplateWrap = chakra(VStack, {
  baseStyle: {
    alignItems: 'stretch',
    spacing: 1 as CSSWithMultiValues,
    border: '0.5px solid',
    borderRadius: 1,
    borderColor: 'primary.50',
    bg: 'primary.25',
    p: 1,
  },
});
