import { useMerchantCabinetConfig } from '../config/ConfigProvider';
import { createContext, useContext, useMemo } from 'react';
import { ApiMerchantCabinet } from '@payler/api/merchant-cabinet';

import createLogger from 'debug';

const log = createLogger('ApiContextProvider');

type TApiContext = {
  api?: ApiMerchantCabinet;
};

const ApiContext = createContext<TApiContext>({});

export const ApiContextProvider: FCC = ({ children }) => {
  const { apiServer, culture } = useMerchantCabinetConfig();
  const ctx = useMemo(() => {
    const api = new ApiMerchantCabinet(apiServer, culture);
    log('ApiContextProvider api: %O, culture: %s', api, culture);
    return {
      api,
    };
  }, [apiServer, culture]);
  return <ApiContext.Provider value={ctx}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const { api } = useContext(ApiContext);
  if (!api) throw new Error('useApi should be used inside ApiContextProvider');
  return api;
};
