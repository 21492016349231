import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Icon,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FullWidthBlock, PageTitle } from '../layouts';
import React from 'react';
import styled from '@emotion/styled';
import { breakpoints } from '@payler/ui-theme';
import FasterPayoutsTable from '../tables/FasterPayoutsTable/FasterPayoutsTable';
import { Card, CardContent, CardHead, CardTitle } from '@payler/ui-components';
import { FasterPayoutsFiltersForm } from '../forms/FasterPayoutsFilters/FasterPayoutsFiltersForm';
import {
  FasterPayoutsFiltersModal,
  useShowFasterPayoutsModal,
} from '../modals/FasterPayoutsFiltersModal';
import { Filter2Icon } from '@payler/ui-icons';
import { Helmet } from 'react-helmet-async';
import { useSelectedContract } from '../hooks/contracts';

const Wrap = styled.div`
  @media (min-width: ${breakpoints.lg}) {
    display: grid;
    grid-template-columns: 1fr 320px;
    grid-template-areas: 'table filters';
    grid-column-gap: 16px;
    align-items: flex-start;
  }
`;

export const FasterPayoutsPage = () => {
  const { t } = useTranslation('fasterPayouts');
  return (
    <>
      <Helmet title={t('titles:fasterPayouts')} />
      <FasterPayoutsPageHeader />
      <MobileFiltersButton />
      <Wrap>
        <FullWidthBlock
          bg="secondary.500"
          borderRadius={{ base: 0, sm: 1.5 }}
          border="0.5px solid"
          borderColor="primary.100"
          gridArea="table"
        >
          <Card>
            <CardHead>
              <CardTitle>{t('previousPayments')}</CardTitle>
            </CardHead>
            <CardContent p={0}>
              <FasterPayoutsTable />
            </CardContent>
          </Card>
        </FullWidthBlock>
        <FasterPayoutsFiltersDesktop />
      </Wrap>
      <FasterPayoutsFiltersModal />
    </>
  );
};

const MobileFiltersButton = () => {
  const showModal = useShowFasterPayoutsModal();
  const { t } = useTranslation();
  const visible = useBreakpointValue({ base: true, lg: false });
  if (!visible) return null;
  return (
    <Button
      onClick={showModal}
      mb={2}
      w="100%"
      variant="secondary"
      leftIcon={<Icon as={Filter2Icon} color="primary.350" />}
    >
      {t('filters:title')}
    </Button>
  );
};

const FasterPayoutsFiltersDesktop = () => {
  const visible = useBreakpointValue({ base: false, lg: true });
  if (!visible) return null;
  return (
    <Box
      gridArea="filters"
      bg="secondary.500"
      border="0.5px solid"
      borderColor="primary.100"
      borderRadius={1.5}
    >
      <FasterPayoutsFiltersForm />
    </Box>
  );
};

const FasterPayoutsPageHeader = () => {
  const { t } = useTranslation();
  const contract = useSelectedContract();
  return (
    <Stack
      alignItems={{ base: 'flex-start', lg: 'flex-end' }}
      justifyContent="space-between"
      mb={{ base: 2, sm: 5 }}
      direction={{
        base: 'column',
        lg: 'row',
      }}
      spacing={{ base: 2, md: 3, lg: 2 }}
    >
      <div>
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              {t('home')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Text}>{contract?.name}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Text}>
              {t('fasterPayouts:title')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle mb={0}>{t('fasterPayouts:title')}</PageTitle>
      </div>
    </Stack>
  );
};
