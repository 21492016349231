import { useContracts } from './contracts';
import { useCallback } from 'react';
import { uniq } from 'lodash';

/**
 * Возвращает функцию, возвращающую список валют по ID контрактов
 */
export const useGetContractsCurrencies = (isApm?: boolean) => {
  const allContracts = useContracts();
  const targetKey = !isApm ? 'currencyCodes' : 'apmPaymentsCurrencyCodes';
  return useCallback(
    (contractIds: Array<number | undefined> | undefined) => {
      if (!contractIds) return [];
      const contracts =
        allContracts?.filter((c) => contractIds.includes(c.id)) ?? [];
      return uniq(contracts.flatMap((x) => x[targetKey]));
    },
    [allContracts, targetKey],
  );
};
