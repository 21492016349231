export const settingsEn = {
  title: 'Settings',
  users: {
    title: 'Users',
  },
  account: {
    toast: {
      account: {
        success: 'Data edited successfully',
      },
      password: {
        success: 'Password changed successfully',
      },
    },
    form: {
      email: 'Email',
      lang: 'Interface language',
      currentPassword: 'Current password',
      password: 'New password',
      confirmPassword: 'Repeat new password',
    },
    title: 'Account',
    passwordHelp:
      'Password must be at least 8 characters long, contain letters and numbers',
  },
  payment: {
    title: 'Payment',
    toast: {
      success: 'Payment settings successfully edited',
    },
    form: {
      ips: 'Allowed IP addresses',
      lang: 'Terminal interface',
      url: 'Return to store URL',
      paymentType: 'Type of payment',
      currentPassword: 'Current payment password',
      newPassword: 'New payment password',
      confirmPassword: 'Payment password confirmation',
    },
    paymentTypeOptions: {
      singleStage: 'One stage',
      twoStage: 'Two stage',
    },
    paymentTypeHelp:
      'Depending on the selected type of payment, blocking or debiting of funds occurs when paying the invoice',
    urlHelp:
      'The address to which the user will be returned after payment. For example, https://supershop.com/finish?order_id={order_id}',
    langHelp:
      'This setting applies exclusively to the payment form and notifications',
    ipsHelp:
      'A list of IP addresses from which you can access the Payler API. If the list is empty, then access to the API will be possible from any address. For added security, it’s best to specify the IP address of your server. Addresses should be separated with a comma. Example: 89.222.176.17, 89.222.228.7',
  },
};

type TSettingsTranslation = typeof settingsEn;

export const settingsRu: TSettingsTranslation = {
  title: 'Настройки',
  users: {
    title: 'Пользователи',
  },
  account: {
    toast: {
      account: {
        success: 'Данные успешно отредактированы',
      },
      password: {
        success: 'Пароль успешно изменен',
      },
    },
    form: {
      email: 'Электронная почта',
      lang: 'Язык интерфейса',
      currentPassword: 'Текущий пароль',
      password: 'Новый пароль',
      confirmPassword: 'Повторите новый пароль',
    },
    title: 'Аккаунт',
    passwordHelp:
      'Пароль должен быть длиной не менее 8 символов, содержать буквы и цифры.',
  },
  payment: {
    title: 'Оплата',
    toast: {
      success: 'Настройки оплаты успешно отредактированы',
    },
    form: {
      ips: 'Разрешённые IP-адреса',
      lang: 'Язык терминала',
      url: 'URL возврата в магазин',
      paymentType: 'Тип платежа',
      currentPassword: 'Текущий платёжный пароль',
      newPassword: 'Новый платёжный пароль',
      confirmPassword: 'Подтверждение платежного пароля',
    },
    paymentTypeOptions: {
      singleStage: 'Одностадийный',
      twoStage: 'Двухстадийный',
    },
    paymentTypeHelp:
      'В зависимости от выбранного типа платежа происходит блокировка или списание средств при оплате выставленного счета',
    urlHelp:
      'Адрес, на который будет возвращён пользователь после совершения оплаты. Например, https://supershop.ru/finish?order_id=&#123;order_id&#125;',
    langHelp:
      'Данная настройка касается исключительно платежной формы и уведомлений',
    ipsHelp:
      'Cписок IP-адресов, с которых возможно обращение к API Пэйлера. Если оставить пустым, то обращение к API будет возможно с любого адреса. В целях дополнительной безопасности лучше указать IP-адрес вашего сервера. Список адресов указывается через запятую. Пример: 89.222.176.17, 89.222.228.7',
  },
};
