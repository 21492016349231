import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';

/**
 * Выпилить как только пофиксят баг
 * @link {https://github.com/remix-run/react-router/discussions/8465}
 */
export const useNavigateRef = () => {
  const navigate = useNavigate();
  const navRef = useRef<NavigateFunction>(navigate);

  useEffect(() => {
    navRef.current = navigate;
  }, [navigate]);

  return navRef;
};
