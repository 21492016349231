export const rolesEn = {
  claims: {
    NEW_CREDIT: 'Withdraw',
    NEW_PAYMENT: 'Invoice',
    REPORTS: 'Reports',
    PAYMENTS: 'Payments',
    SETTING_NOTIFICATION: 'Settings - Notifications',
    SETTING_PAYMENT: 'Settings - Payment',
  },
  claimValues: {
    ALL: 'All operations',
    READ: 'Read only',
  },
  MC_ADMIN: 'Administrator',
  MC_USER: 'User',
};

type TRolesTranslation = typeof rolesEn;

export const rolesRu: TRolesTranslation = {
  claims: {
    NEW_CREDIT: 'Сделать выплату',
    NEW_PAYMENT: 'Выставление счёта',
    REPORTS: 'Отчеты',
    PAYMENTS: 'Платежи',
    SETTING_NOTIFICATION: 'Настройки - Уведомления',
    SETTING_PAYMENT: 'Настройки - Оплата',
  },
  claimValues: {
    ALL: 'Все операции',
    READ: 'Только чтение',
  },
  MC_ADMIN: 'Администратор',
  MC_USER: 'Пользователь',
};
