import { TTransactionStatus } from '@payler/api/merchant-cabinet';

export function getTransactionStatusBadgeVariant(status: TTransactionStatus) {
  switch (status) {
    case 'PreAuthorized3DS':
    case 'Created':
    case 'Authorized':
    case 'Registered':
    case 'PendingOnlyDebit':
    case 'PendingOnlyCredit':
    case 'Pending':
      return 'yellow';
    case 'Reversed':
    case 'Charged':
    case 'Refunded':
    case 'Credited':
      return 'green';
    case 'Rejected':
    case 'RejectedOnlyDebit':
    case 'RejectedOnlyCredit':
      return 'red';
    default:
      return undefined;
  }
}
