export const cryptoPaymentsEn = {
  previousPayments: 'Previous payments',
  title: 'Crypto Payments',
  status: {
    Created: 'Created',
    Pending: 'Pending',
    Completed: 'Completed',
    Failed: 'Failed',
    Returned: 'Returned',
    Succeed: 'Succeed',
  },
  dataTitle: 'Payment details',
  payoutId: 'Payout ID',
  history: 'Operations history',
  requisites: 'Requisites',
  tooltip: 'Faster payment',
  spbOrderId: 'ID платежа в НСПК',
  refund: 'Refund',
  downloadPDF: 'Download PDF',
  sessionId: 'Session ID',
  clientId: 'Client ID',
  paymentId: 'Payment ID',
  direction: 'Direction',
  paymentMethod: 'Payment Method',
  statusDescription: 'Status',
  amount: 'Amount',
  currencyCode: 'Currency Code',
  currency: 'Currency',
  date: 'Date',
  network: 'Network',
  fee: 'Fee',
  paymentType: 'Payment Type',
  rejectionReason: 'Rejection Reason',
  txId: 'TX ID',
};

type TCryptoPaymentsTranslation = typeof cryptoPaymentsEn;

export const cryptoPaymentsRu: TCryptoPaymentsTranslation = {
  previousPayments: 'Предыдущие платежи',
  title: 'Crypto Платежи',
  status: {
    Completed: 'Успешный',
    Failed: 'Неуспешный',
    Returned: 'Возвращён',
    Created: 'Создан',
    Pending: 'В обработке',
    Succeed: 'Успешный',
  },
  dataTitle: 'Данные платежа',

  history: 'История операций',
  payoutId: 'ID выплаты',
  requisites: 'Реквизиты',
  tooltip: 'Платёж СБП',
  spbOrderId: 'ID платежа в НСПК',
  downloadPDF: 'Скачать PDF',
  refund: 'Вернуть',
  sessionId: 'ID Сессии',
  clientId: 'ID Клиента',
  paymentId: 'ID Платежа',
  direction: 'Направление',
  paymentMethod: 'Способ оплаты',
  statusDescription: 'Статус',
  amount: 'Сумма',
  currencyCode: 'Код валюты',
  currency: 'Валюта',
  date: 'Дата',
  network: 'Сеть',
  fee: 'Комиссия',
  paymentType: 'Тип платежа',
  rejectionReason: 'Причина отказа',
  txId: 'TX ID',
};
