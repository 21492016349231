import {
  TFasterPayoutCurrency,
  TFasterPayoutsTemplate,
} from '@payler/api/merchant-cabinet';
import { TFasterPayoutsURLParams } from './types';
import dayjs from 'dayjs';
import { isNil, omitBy } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FPS_CURRENCY_OPTIONS } from '../../const';
import { TFasterPayoutsFiltersForm } from '../../forms/FasterPayoutsFilters/types';
import { useUIDateFormat } from '../use-ui-dateFormat';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';
import { useApi } from '../../context/ApiContextProvider';

const templateToFilters = (
  template: TFasterPayoutsTemplate,
  currencies: TFasterPayoutCurrency[],
  dateFormat: string,
): TFasterPayoutsURLParams => {
  const tmp: TFasterPayoutsURLParams = {
    Currency:
      template.currencyCode && currencies.includes(template.currencyCode)
        ? template.currencyCode
        : undefined,
    AmountFrom: template.amountFrom
      ? Number(template.amountFrom).toString()
      : undefined,
    AmountTo: template.amountTo
      ? Number(template.amountTo).toString()
      : undefined,
    Statuses: template.statuses ?? undefined,
    DateFrom: template.dateFrom
      ? dayjs(template.dateFrom).format(dateFormat)
      : undefined,
    DateTo: template.dateTo
      ? dayjs(template.dateTo).format(dateFormat)
      : undefined,
  };
  return omitBy(tmp, isNil);
};

export const useApplyFasterPayoutsTemplate = () => {
  const [, setParams] = useSearchParams();
  const { PageSize } = usePagerPropsFromUrl();
  const dateFormat = useUIDateFormat();
  return useCallback(
    (template: TFasterPayoutsTemplate) => {
      const params = templateToFilters(
        template,
        FPS_CURRENCY_OPTIONS,
        dateFormat,
      );
      if (PageSize !== 10) {
        params.PageSize = PageSize.toString();
      }
      //Page by default is 0
      setParams(params);
    },
    [PageSize, setParams, dateFormat],
  );
};

export const useDeleteFasterPayoutTemplateMutation = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  const contractId = useURLContractId();
  return useMutation({
    mutationFn: (id: number) => {
      return api.deleteTemplate(id);
    },
    onSuccess: (data, id) => {
      queryClient.setQueryData(
        ['faster-payouts', 'templates', contractId],
        (prev) => (prev as TFasterPayoutsTemplate[]).filter((x) => x.id !== id),
      );
    },
  });
};

export const useSaveFasterPayoutsTemplate = () => {
  const contractId = useURLContractId();
  const queryClient = useQueryClient();
  const api = useApi();
  return useMutation({
    mutationFn: (filters: TFasterPayoutsFiltersForm) => {
      if (!contractId) throw new Error('no contractId');
      return api.saveFasterPayoutsTemplate(filters, contractId);
    },
    onSuccess: (data) => {
      queryClient.setQueryData<TFasterPayoutsTemplate[]>(
        ['faster-payouts', 'templates', contractId],
        (prev) => (prev ? [...prev, data] : [data]),
      );
    },
  });
};
