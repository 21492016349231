import React, { FC } from 'react';
import { chakra } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export type ModalTitleProps = {
  title: string;
};
export const ModalTitle: FC<ModalTitleProps> = ({ title }) => {
  return (
    <chakra.h1 mb={{ base: 2, md: 4 }} textStyle={TextStyles.h1}>
      {title}
    </chakra.h1>
  );
};
