export const paymentsEn = {
  amount: 'Amount',
  amountError: 'Please enter a valid amount',
  antifraud: {
    ACCEPT: 'Confirmed by',
    decision: 'Decision',
    label: 'Antifraud',
    reason: 'Reason',
    REJECT: 'Rejected',
    VERIFY: 'Sent to 3DS',
  },
  bankName: 'Bank',
  card: 'Card',
  cardHolder: 'Cardholder name',
  charge: 'Debit',
  chargeTitle: 'Debit payment',
  country: 'Country',
  downloadPDF: 'Download PDF',
  email: 'Operation creator',
  historyTitle: 'Transaction history',
  ip: 'Payer IP',
  linkPayment: 'Link payment',
  operations: 'Payment operations',
  orderId: 'Payment ID',
  paylerError: 'Error:',
  paymentPassword: 'Payment password',
  paymentPasswordRequired: 'Enter password',
  payPageParams: 'Additional payment parameters',
  processingError: 'Error from the bank:',
  recurringPayment: 'Recurring payment',
  refund: 'Refund',
  refunded: 'Refunded',
  refundTitle: 'Refund a payment',
  reject: 'Reject',
  rejectedByAntifraud: 'Rejected by antifraud',
  rejectTitle: 'Reject the payment',
  requisites: 'Bank details',
  status: 'Status',
  support: '24/7 support: +7 (495) 374-58-74, extension 201',
  transactionTitle: 'Payment details',
  transactionType: 'Authorization',
  userData: 'User data',
  userEnteredParams: `Payer's data`,
  alertsTitle: 'Alerts',
  payoutsTitle: 'Withdrawals',
  saveToReport: 'Save to report',
  title: 'Payments',
  description: 'Description',
};

type TPaymentsTranslation = typeof paymentsEn;

export const paymentsRu: TPaymentsTranslation = {
  amount: 'Сумма',
  amountError: 'Укажите корректную сумму',
  antifraud: {
    label: 'Антифрод',
    decision: 'Решение',
    reason: 'Причина',
    VERIFY: 'Отправлен на 3DS',
    ACCEPT: 'Подтверждён',
    REJECT: 'Отклонён',
  },
  bankName: 'Банк',
  card: 'Карта',
  cardHolder: 'Имя владельца',
  charge: 'Списать',
  chargeTitle: 'Списать платёж',
  country: 'Страна',
  downloadPDF: 'Скачать PDF',
  email: 'Создатель операции',
  historyTitle: 'История операций',
  ip: 'IP плательщика',
  linkPayment: 'Выставлено по ссылке',
  operations: 'Операции по платежу',
  orderId: 'ID платежа',
  paylerError: 'Ошибка:',
  paymentPassword: 'Платёжный пароль',
  paymentPasswordRequired: 'Укажите пароль',
  payPageParams: 'Доп. параметры платежа',
  processingError: 'Ошибка от банка:',
  recurringPayment: 'Рекуррентный платёж',
  refund: 'Вернуть',
  refunded: 'Возвращено',
  refundTitle: 'Вернуть платёж',
  reject: 'Отклонить',
  rejectedByAntifraud: 'Отклонено антифродом',
  rejectTitle: 'Отклонить платёж',
  requisites: 'Реквизиты',
  status: 'Статус',
  support: 'Круглосуточная поддержка: +7 (495) 374-58-74 доб. 201',
  transactionTitle: 'Данные платежа',
  transactionType: 'Авторизация',
  userData: 'Пользовательские параметры',
  userEnteredParams: `Параметры плательщика`,
  alertsTitle: 'Оповещения',
  payoutsTitle: 'Выплаты',
  saveToReport: 'Сохранить в отчёт',
  title: 'Платежи',
  description: 'Описание',
};
