import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

const normalizePageSize = (value: string | null) => {
  if (!value) return 10;
  const pageSize = Number(value);
  if (isNaN(pageSize)) return 10;
  if (pageSize <= 10) return 10;
  if (pageSize <= 20) return 20;
  if (pageSize <= 30) return 30;
  if (pageSize <= 40) return 40;
  return 50;
};

const normalizePage = (value: string | null) => {
  if (!value) return 1;
  const num = Math.round(Number(value));
  if (isNaN(num)) return 1;
  if (num < 1) return 1;
  return num;
};

const getPageAndPageSizeFromUrl = (params: URLSearchParams) => {
  const PageSize = normalizePageSize(params.get('PageSize'));
  const Page = normalizePage(params.get('Page'));
  return { Page, PageSize };
};

export const usePagerPropsFromUrl = () => {
  const [params] = useSearchParams();
  return useMemo(() => {
    return getPageAndPageSizeFromUrl(params);
  }, [params]);
};
