import React from 'react';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import CreateUserForm from '../forms/users/CreateUserForm';
import { useTranslation } from 'react-i18next';
import { PaylerModal } from '@payler/ui-components';

const createUserModalOpenedAtom = atom({
  key: createRecoilKey('createUserModalOpenedAtom'),
  default: false,
});

export const useShowCreateUserModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(createUserModalOpenedAtom, true);
      },
    [],
  );

export const useCloseCreateUserModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(createUserModalOpenedAtom, false);
      },
    [],
  );

export const CreateUserModal = () => {
  const open = useRecoilValue(createUserModalOpenedAtom);
  const close = useCloseCreateUserModal();
  const { t } = useTranslation();
  return (
    <PaylerModal close={close} open={open} title={t('users:newUser')}>
      <CreateUserForm onCancel={close} onSubmit={close} />
    </PaylerModal>
  );
};
