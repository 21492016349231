import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  ThinDivider,
} from '@payler/ui-components';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { TFasterPayoutsTemplate } from '@payler/api/merchant-cabinet';
import dayjs from 'dayjs';
import React, { Suspense } from 'react';
import { getNumericRangeKey, getRangeKey } from '../../helpers/ranges';
import { TemplateLabeledValue } from '../../components/TemplateLabeledValue/TemplateLabeledValue';
import { TemplateWrap } from '../../components/TemplateWrap/TemplateWrap';
import { useFasterPayoutsTemplatesQuery } from '../../hooks/faster-payouts/queries';
import {
  useApplyFasterPayoutsTemplate,
  useDeleteFasterPayoutTemplateMutation,
} from '../../hooks/faster-payouts/templates';
import { useUIDateFormat } from '../../hooks/use-ui-dateFormat';

export const Templates = () => (
  <Suspense fallback={null}>
    <Comp />
  </Suspense>
);

const Comp = () => {
  const { data: templates } = useFasterPayoutsTemplatesQuery();
  if (!templates?.length) return null;
  return (
    <Accordion alignSelf="stretch">
      <Header />
      <AccordionBody p={2}>
        <VStack spacing={1} alignItems="stretch">
          {templates.map((template) => (
            <Template template={template} key={template.id} />
          ))}
        </VStack>
      </AccordionBody>
    </Accordion>
  );
};

type WithTemplate = { template: TFasterPayoutsTemplate };

export const Template = ({ template }: WithTemplate) => {
  return (
    <TemplateWrap>
      <Status template={template} />
      <Dates template={template} />
      <Currency template={template} />
      <Amount template={template} />
      <ThinDivider />
      <Buttons template={template} />
    </TemplateWrap>
  );
};

const Buttons = ({ template }: WithTemplate) => {
  const apply = useApplyFasterPayoutsTemplate();
  const deleteMutation = useDeleteFasterPayoutTemplateMutation();
  const { t } = useTranslation();
  const handleApply = () => {
    apply(template);
  };
  const handleDelete = () => {
    deleteMutation.mutate(template.id);
  };
  return (
    <HStack spacing={2}>
      <Button
        variant="link"
        color="primary.500"
        textStyle={TextStyles.Caption12Medium}
        onClick={handleApply}
        disabled={deleteMutation.isPending}
      >
        {t('filters:apply')}
      </Button>
      <Button
        variant="link"
        color="red.500"
        textStyle={TextStyles.Caption12Medium}
        onClick={handleDelete}
        disabled={deleteMutation.isPending}
      >
        {t('filters:delete')}
      </Button>
    </HStack>
  );
};

const Dates = ({ template }: WithTemplate) => {
  const { t } = useTranslation();
  const dateFormat = useUIDateFormat();
  if (!template.dateFrom && !template.dateTo) return null;
  const from = dayjs(template.dateFrom).format(dateFormat);
  const to = dayjs(template.dateTo).format(dateFormat);
  const key = getRangeKey(template.dateFrom, template.dateTo);
  return (
    <TemplateLabeledValue
      label={t('fields:period')}
      value={t(key, { start: from, end: to })}
    />
  );
};

const Currency = ({ template }: WithTemplate) => {
  const { t } = useTranslation();
  if (!template.currencyCode) return null;
  return (
    <TemplateLabeledValue
      label={t('fields:currency')}
      value={t(`currency:${template.currencyCode}`)}
    />
  );
};

const Status = ({ template }: WithTemplate) => {
  const { t } = useTranslation();
  if (!template.statuses) return null;
  const text = template.statuses
    ?.map((x) => t(`fasterPayouts:status.${x}`))
    .join(',\u00a0');
  if (!text) return null;
  return (
    <TemplateLabeledValue label={t(`fields:paymentStatus`)} value={text} />
  );
};

const Amount = ({ template }: WithTemplate) => {
  const { t } = useTranslation();
  const key = getNumericRangeKey(template.amountFrom, template.amountTo);
  if (!key) return null;
  return (
    <TemplateLabeledValue
      label={t('fields:amount')}
      value={t(key, {
        start: Number(template.amountFrom),
        end: Number(template.amountTo),
      })}
    />
  );
};

const Header = () => {
  const { t } = useTranslation();
  return (
    <AccordionHeader>
      <HStack alignItems="center" spacing={2}>
        <Box flex={1}>
          <Text textStyle={TextStyles.tables} color="primary.500">
            {t('filters:templates')}
          </Text>
        </Box>
      </HStack>
    </AccordionHeader>
  );
};
