import { Badge } from '@chakra-ui/react';
import React from 'react';
import { TReportStatuses } from '@payler/api/merchant-cabinet';
import { useTranslation } from 'react-i18next';

export const ReportStatusCell = ({ status }: { status: TReportStatuses }) => {
  const { t } = useTranslation('reportStatus');
  switch (status) {
    case 'Created':
      return <Badge variant="yellow">{t(status)}</Badge>;
    case 'Processing':
      return <Badge variant="yellow">{t(status)}</Badge>;
    case 'Processed':
      return <Badge variant="green">{t(status)}</Badge>;
    case 'Error':
      return <Badge variant="red">{t(status)}</Badge>;
    default:
      return <Badge variant="yellow">unknown</Badge>;
  }
};
