import React, {
  createContext,
  Suspense,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { WatchNavigation } from '../../watchers/WatchNavigation';
import { useMerchantContractsQuery } from '../../hooks/contracts';
import { NotFoundPage } from '../../pages';
import { TExtendedContract } from '@payler/api/merchant-cabinet';
import { Loader } from '../../components/Loader';

type ID = TExtendedContract['id'];

export type TContractBasedPagesContext = {
  selectedContractId: ID | undefined;
  selectedContract: TExtendedContract | undefined;
  setSelectedContractId: (id: ID) => void;
};

export const ContractBasedPagesContext =
  createContext<TContractBasedPagesContext>({
    selectedContract: undefined,
    selectedContractId: undefined,
    setSelectedContractId: (id) =>
      console.log('AuthorizedAppContext.setSelectedContractId', id),
  });

const Inner: React.FC = () => {
  const { data: contracts, isLoading } = useMerchantContractsQuery();
  const [forceId, setForceId] = useState<ID | undefined>();

  const { contractId: urlContractId } = useParams();
  const contractId = useMemo(() => {
    if (forceId) {
      const c = contracts?.find((x) => x.id === forceId);
      if (c) {
        return forceId;
      }
    }
    const tmp = Number(urlContractId);
    const c = contracts?.find((x) => x.id === tmp);
    if (!c) return undefined;
    return tmp;
  }, [contracts, forceId, urlContractId]);

  const ctx = useMemo<TContractBasedPagesContext>(() => {
    return {
      selectedContractId: contractId,
      selectedContract: contracts?.find((x) => x.id === contractId),
      setSelectedContractId: setForceId,
    };
  }, [contractId, contracts]);

  if (isLoading) {
    return <Loader />;
  }

  // ensure contract available
  if (!contractId) {
    return <NotFoundPage />;
  }

  return (
    <ContractBasedPagesContext.Provider value={ctx}>
      <WatchNavigation />
      <Outlet />
    </ContractBasedPagesContext.Provider>
  );
};

export const ContractIdLayout = () => {
  return (
    <Suspense fallback={null}>
      <Inner />
    </Suspense>
  );
};

/**
 * Идентификатор контракта из URL
 */
export const useURLContractId = () =>
  useContext(ContractBasedPagesContext).selectedContractId;

/**
 * Установить идентификатор контракта из URL.
 * Использовать только для установки и params роутера!
 */
export const useSetURLContractId = () =>
  useContext(ContractBasedPagesContext).setSelectedContractId;
