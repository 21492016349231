import { useTranslation } from 'react-i18next';
import { PageTitle } from '../../layouts';
import { Card, CardContent } from '@payler/ui-components';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React, { Suspense } from 'react';
import { AccountSettingsForm } from '../../forms/AccountSettingsForm/AccountSettingsForm';
import { PasswordSettingsForm } from '../../forms/PasswordSettingsForm/PasswordSettingsForm';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';

export const AccountPage = () => {
  return (
    <Wrap>
      <Suspense fallback={null}>
        <AccountPageHeader />
      </Suspense>
      <Form />
    </Wrap>
  );
};

const Wrap = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const Form = () => {
  return (
    <Card>
      <CardContent
        px={{
          base: 2,
          md: 3,
        }}
        py={{
          base: 2,
          md: 3,
        }}
      >
        <AccountSettingsForm />
      </CardContent>
      <CardContent
        px={{
          base: 2,
          md: 3,
        }}
        py={{
          base: 2,
          md: 3,
        }}
      >
        <PasswordSettingsForm />
      </CardContent>
    </Card>
  );
};

const AccountPageHeader = () => {
  const desktop = useBreakpointValue({ base: false, md: true });
  const { t } = useTranslation();
  if (!desktop) return null;
  return (
    <HStack alignItems="flex-end" justifyContent="space-between" mb={5}>
      <Helmet
        title={`${t('titles:settings')} :: ${t('settings:account.title')}`}
      />
      <div>
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/">
              {t('home')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/settings">
              {t('settings:title')}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={Link} to="/settings/account">
              {t('settings:account.title')}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <PageTitle mb={0}>{t('settings:account.title')}</PageTitle>
      </div>
    </HStack>
  );
};

export default AccountPage;
