export const invoicesEn = {
  reinvoiced: 'Reinvoiced',
  email: 'Invoiced by email',
  expired: 'Expired',
  form: {
    amount: 'Amount, {{currency}}',
    days: 'Days',
    description: 'Description',
    email: 'E-mail',
    hours: 'Hours',
    invalidAmount: 'Enter amount',
    invalidEmail: 'Enter a valid e-mail',
    lifetime: 'Lifetime',
    maxLifetime: 'The maximum link lifetime is {{maxLifetime}}.',
    minLifetime: 'The minimum link lifetime is 3\u00a0minutes',
    minutes: 'Minutes',
    notify: 'Notify about payment',
    notifyInfo:
      'A notification will be sent to the email you specified during registration',
    remember: 'Remember form data',
    send: 'Send',
    templates: 'Templates',
    success: 'Invoice issued successfully.',
    invoiceProductField: 'Does not match contract settings',
    paymentPageTypes: 'View of the payment page',
    paymentPageTypeRequired: 'Specify the view of payment page',
  },
  formTitle: 'Create invoice',
  listTitle: 'Previous payments',
  recreate: 'Invoice',
  recreateText: 'Send the invoice again?',
  recreateTitle: 'Send the invoice again',
  remaining: 'Time left',
  remainingText:
    '{{days}}d. {{hours, number(minimumIntegerDigits: 2)}}h. {{minutes, number(minimumIntegerDigits: 2)}}m.',
  sendInvoiceAgain: 'Send the invoice again',
  sendNotification: 'Send reminder notification',
  title: 'Invoice',
  userEmail: 'Issued the invoice',
};

type TInvoicesTranslation = typeof invoicesEn;

export const invoicesRu: TInvoicesTranslation = {
  reinvoiced: 'Счет выставлен повторно.',
  email: 'Выставлен счёт по email',
  expired: 'Истёк',
  form: {
    templates: 'Шаблоны',
    lifetime: 'Время жизни',
    invalidEmail: 'Введите корректный email',
    invalidAmount: 'Введите сумму',
    amount: 'Сумма, {{currency}}',
    remember: 'Запомнить данные формы',
    send: 'Отправить',
    email: 'E-mail',
    description: 'Описание',
    notify: 'Уведомить об оплате',
    notifyInfo:
      'Уведомление будет отправлено на email указанный вами при регистрации',
    minLifetime: 'Минимальное время жизни ссылки - 3\u00a0минуты',
    maxLifetime: 'Максимальное время жизни ссылки - {{maxLifetime}}.',
    days: 'Дней',
    hours: 'Часов',
    minutes: 'Минут',
    success: 'Счет выставлен успешно.',
    invoiceProductField: 'Не соответствует настройкам контракта',
    paymentPageTypes: 'Вид платёжной страницы',
    paymentPageTypeRequired: 'Укажите вид платёжной страницы',
  },
  formTitle: 'Выставить счёт',
  listTitle: 'Предыдущие платежи',
  recreate: 'Выставить счёт',
  recreateText: 'Выставить счёт повторно?',
  recreateTitle: 'Выставить счёт повторно',
  remaining: 'Осталось',
  remainingText:
    '{{days}}д. {{hours, number(minimumIntegerDigits: 2)}}ч. {{minutes, number(minimumIntegerDigits: 2)}}м.',
  sendInvoiceAgain: 'Выставить счёт повторно',
  sendNotification: 'Отправить повторное уведомление',
  title: 'Выставить счёт',
  userEmail: 'Выставил счёт',
};
