import { useMerchantCabinetConfig } from '../../config/ConfigProvider';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { groupByPredicate } from '@payler/utils';
import dayjs from 'dayjs';
import { PaginationProps } from '@payler/ui-components';
import { useFasterPayoutsFiltersFromUrl } from './url';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { usePagerUrlCallbacks } from '../pager/use-pager-url-callbacks';
import { TFasterPayoutComputedHistoryItem } from './types';
import { DEFAULT_PAGER_PROPS } from '../../const';
import { useUIDateFormat } from '../use-ui-dateFormat';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';
import { useApi } from '../../context/ApiContextProvider';

const FASTER_PAYOUTS_STALE_TIME = 60 * 1000;
const FASTER_PAYOUT_DATA_STALE_TIME = 60 * 1000;
const FASTER_PAYOUTS_TEMPLATES_STALE_TIME = 600 * 1000;

export const useFasterPayoutsQuery = () => {
  const api = useApi();
  const { culture } = useMerchantCabinetConfig();
  const contractId = useURLContractId();
  const pagerData = usePagerPropsFromUrl();
  const filters = useFasterPayoutsFiltersFromUrl();
  const dateFormat = useUIDateFormat();
  if (!contractId) throw new Error('no contractId');
  return useQuery({
    queryKey: ['faster-payouts', culture, contractId, pagerData, filters],
    queryFn: async () => {
      const data = await api.getFasterPayouts(
        contractId,
        {
          ...filters,
          ...pagerData,
        },
        dateFormat,
      );

      const grouped = groupByPredicate(data.items, (v) => {
        const dateString = v['created'] as string;
        return dayjs(dateString).format('YYYY-MM-DD');
      });
      return { ...data, grouped };
    },
    staleTime: FASTER_PAYOUTS_STALE_TIME,
  });
};

export const useFasterPayoutDataQuery = (id: number) => {
  const api = useApi();
  const contractId = useURLContractId();
  if (!contractId) throw new Error('no contract id');
  const { culture } = useMerchantCabinetConfig();
  return useQuery({
    queryKey: ['faster-payouts', culture, contractId, id],
    queryFn: async () => {
      const data = await api.getFasterPayoutWithHistory(contractId, id);
      const history: TFasterPayoutComputedHistoryItem[] = [];
      data.operations.forEach((op) => {
        op.history.forEach((h) => {
          history.push({
            type: op.type,
            typeDescription: op.typeDescription,
            ...h,
          });
        });
      });
      return {
        ...data,
        history: history.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1)),
      };
    },
    staleTime: FASTER_PAYOUT_DATA_STALE_TIME,
  });
};

export const useFasterPayoutsQueryPagerProps = () => {
  const payments = useFasterPayoutsQuery();
  const { goToPage, setPageSize } = usePagerUrlCallbacks();
  if (payments.status !== 'success') {
    return { ...DEFAULT_PAGER_PROPS, goToPage, setPageSize } as PaginationProps;
  }
  const { page, pageSize, totalItems } = payments.data.paginationMetadata;
  return {
    goToPage,
    setPageSize,
    pageSize,
    currentPage: page,
    totalRecords: totalItems,
  } as PaginationProps;
};

export const useFasterPayoutsTemplatesQuery = () => {
  const api = useApi();
  const contractId = useURLContractId();
  if (!contractId) throw new Error('no contract Id');
  return useSuspenseQuery({
    queryKey: ['faster-payouts', 'templates', contractId],
    queryFn: async () => api.getFasterPayoutsTemplates(contractId),

    staleTime: FASTER_PAYOUTS_TEMPLATES_STALE_TIME,
  });
};
