import { Helmet } from 'react-helmet-async';
import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TextStyles } from '@payler/ui-theme';
import {
  Breadcrumb,
  BreadcrumbItem,
  HStack,
  VStack,
  Text,
  Box,
  Badge,
  Link,
  useBreakpointValue,
} from '@chakra-ui/react';
import { BreadcrumbBackButton } from '../components/BreadcrumbBackButton/BreadcrumbBackButton';
import { useURLContractId } from '../layouts/ContractIdLayout/ContractIdLayout';
import { PageTitle } from '../layouts';
import { Card, CardContent, ClipboardText } from '@payler/ui-components';
import { FullWidthBlock } from '../layouts';
import dayjs from 'dayjs';
import { TCryptoPaymentStatus } from '@payler/api/merchant-cabinet';
import { useCryptoPaymentSessionQuery } from '../hooks/crypto-payments/queries';

export const CryptoPaymentSessionDetail = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('titles:paymentSessionDetails')} />
      <Head />
      <Content />
    </>
  );
};

const Head = () => {
  const contractId = useURLContractId();
  const { status, data } = useCryptoPaymentSessionQuery();

  if (status !== 'success' || !data) {
    return null;
  }

  return (
    <HStack
      w="full"
      spacing={2}
      alignItems="flex-end"
      justifyContent="space-between"
      mb={{ base: 2, sm: 5 }}
    >
      <VStack w="full" spacing={1} alignItems="flex-start">
        <Breadcrumb separator={<>&mdash;</>}>
          <BreadcrumbItem>
            <BreadcrumbBackButton to={`/${contractId}/crypto-payments`} />
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle mb={0}>{`${data.amount} ${data.currency}`}</PageTitle>
      </VStack>
    </HStack>
  );
};

const Content = () => {
  const { data } = useCryptoPaymentSessionQuery();

  if (!data) {
    return null;
  }

  return (
    <FullWidthBlock>
      <VStack w="full" spacing={2}>
        <SessionInfo />
      </VStack>
    </FullWidthBlock>
  );
};

const SessionInfo = () => {
  const { data } = useCryptoPaymentSessionQuery();
  const { t } = useTranslation('cryptoPayments');
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const getShortUuid = useCallback(
    (uuid: string) => {
      const blocks = uuid.split('-');

      if (!blocks.length || blocks.length <= 2 || !isMobile) {
        return uuid;
      }

      return `${blocks[0]}-...-${blocks[blocks.length - 1]}`;
    },
    [isMobile],
  );

  const displayedData = useMemo(() => {
    const {
      id,
      paymentId,
      clientId,
      currency,
      network,
      amount,
      fee,
      status,
      paymentType,
      createdAt,
      txId,
      txUrl,
      additionalData = {},
    } = data || {};

    const statusBadgeVariant: Record<TCryptoPaymentStatus, string | undefined> =
      {
        Created: 'grey',
        Pending: 'grey',
        Authorized: 'green',
        Rejected: 'red',
        ProviderTechError: 'red',
      };

    return {
      [t('paymentId')]: paymentId && (
        <ClipboardText text={paymentId} shortText={getShortUuid(paymentId)} />
      ),
      [t('sessionId')]: id && (
        <ClipboardText text={id} shortText={getShortUuid(id)} />
      ),
      [t('clientId')]: clientId && (
        <ClipboardText text={clientId} shortText={getShortUuid(clientId)} />
      ),
      [t('currency')]: currency,
      [t('network')]: network,
      [t('amount')]: amount ?? '-',
      [t('fee')]: fee ?? '-',
      [t('statusDescription')]: status && (
        <Badge variant={statusBadgeVariant[status]}>{status}</Badge>
      ),
      [t('paymentType')]: paymentType,
      [t('date')]: dayjs(createdAt).format('DD.MM.YYYY HH:mm:ss'),
      [t('rejectionReason')]: additionalData?.rejectionReason,
      [t('txId')]: txId && (
        <Link href={txUrl} colorScheme="primary">
          {getShortUuid(txId)}
        </Link>
      ),
    };
  }, [data, t, getShortUuid]);

  return (
    <Card w="full">
      <CardContent>
        <VStack spacing={2}>
          {Object.entries(displayedData).map(
            ([k, v]) => k && v && <InfoItem key={k} label={k} value={v} />,
          )}
        </VStack>
      </CardContent>
    </Card>
  );
};

const InfoItem: FC<{ label: string; value: ReactNode }> = ({
  label,
  value,
}) => {
  return (
    <HStack h={3} justifyContent="space-between" width="full">
      <Text textStyle={TextStyles.tables} whiteSpace="nowrap">
        {label}
      </Text>
      <Box
        style={{
          borderBottomStyle: 'dotted',
          borderBottomWidth: 2.5,
          borderBottomColor: 'primary.300',
        }}
        h={1.5}
        w="full"
      />
      <Text textStyle={TextStyles.tables} whiteSpace="nowrap">
        {value}
      </Text>
    </HStack>
  );
};
