import React from 'react';
import { HStack, useBreakpointValue, VStack } from '@chakra-ui/react';
import {
  BalancesIcon,
  NotificationsIcon,
  StaffIcon,
  UserIcon,
} from '@payler/ui-icons';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuLink } from '../../components/Menu/MenuLink';
import ScrollBar from 'react-perfect-scrollbar';
import styled from '@emotion/styled';
import { MENU_WIDTH } from '../BaseLayout/const';
import { breakpoints } from '@payler/ui-theme';
import { css } from '@emotion/react';
import { ThemeSwitcher } from '../../components/Menu/ThemeSwitcher';
import { Helmet } from 'react-helmet-async';
import { NavigationSidebarMobileItem } from './NavigationSidebarMobileItem';
import { ErrorBoundary } from '@payler/ui-components';
import ErrorPage from '../../pages/ErrorPage';

const scrollBarStyles = css`
  height: unset;
  margin-bottom: 16px;
  width: calc(100vw - ${MENU_WIDTH}px - 60px);
  @media (max-width: ${breakpoints.sm}) {
    width: calc(100vw - 36px);
  }
`;

const SettingsLinks = () => {
  const { t } = useTranslation();
  return (
    <VStack
      gridArea="nav"
      position="fixed"
      alignItems="stretch"
      justifyContent="space-between"
      top={0}
      left={250}
      height="100vh"
      width={`${MENU_WIDTH}px`}
      bg="menuBG"
      pb={1}
      pt={11}
      zIndex={10}
    >
      <Helmet title={t('titles:settings')} />
      <VStack alignItems="stretch">
        <MenuLink
          icon={UserIcon}
          route="/settings/account"
          title={t('menu:account')}
        />
        <MenuLink
          icon={BalancesIcon}
          route="/settings/payment"
          title={t('menu:payment')}
        />
        <MenuLink
          icon={NotificationsIcon}
          route="/settings/notifications"
          title={t('menu:notifications')}
        />
        <MenuLink
          icon={StaffIcon}
          route="/settings/users"
          title={t('menu:users')}
        />
      </VStack>
      <VStack alignItems="stretch">
        <ThemeSwitcher />
      </VStack>
    </VStack>
  );
};

const SettingsMobileLinks = () => {
  const { t } = useTranslation();
  return (
    <ScrollBar css={scrollBarStyles}>
      <HStack alignItems="stretch" spacing={2} w="100%">
        <NavigationSidebarMobileItem
          icon={<UserIcon />}
          url="/settings/account"
          title={t('menu:account')}
        />
        <NavigationSidebarMobileItem
          icon={<BalancesIcon />}
          url="/settings/payment"
          title={t('menu:payment')}
        />
        <NavigationSidebarMobileItem
          icon={<NotificationsIcon />}
          url="/settings/notifications"
          title={t('menu:notifications')}
        />
        <NavigationSidebarMobileItem
          icon={<StaffIcon />}
          url="/settings/users"
          title={t('menu:users')}
        />
      </HStack>
    </ScrollBar>
  );
};

const Wrap = styled.main`
  display: grid;
  grid-template-areas: 'nav' 'content';

  * {
    -webkit-tap-highlight-color: transparent;
  }
`;

export function SettingsLayout() {
  const desktop = useBreakpointValue({ base: false, md: true });
  return (
    <Wrap>
      {!desktop && <SettingsMobileLinks />}
      <Content>
        <ErrorBoundary errorPage={<ErrorPage />}>
          <Outlet />
        </ErrorBoundary>
      </Content>
      {desktop ? (
        <ErrorBoundary>
          <SettingsLinks />
        </ErrorBoundary>
      ) : null}
    </Wrap>
  );
}

const Content = styled.div`
  @media (min-width: ${breakpoints.md}) {
    padding-left: ${MENU_WIDTH + 24}px;
  }
`;

export default SettingsLayout;
