export const tableHeaderEn = {
  sessionId: 'Session ID',
  orderId: 'Order ID',
  clientId: 'Client ID',
  paymentType: 'Type',
  paymentMethod: 'Payment method',
  status: 'Status',
  amount: 'Amount',
  currency: 'Currency',
  created: 'Created',
  paid: 'Paid',
  code: 'Country code',
  cardAndOrderId: 'Card number / Order ID',
  period: 'Date range',
  email: 'Email',
  createdAndAmount: 'Created / Amount',
  nameAndAuthor: 'Name / Author',
  download: 'Download',
  delete: 'Remove',
  processingName: 'Processing',
  phoneAndOrderId: 'Phone / Order ID',
  description: 'Description',
  idAndOrderId: 'ID / Order ID',
  publicId: 'Public ID',
  date: 'Date',
  type: 'Type',
  result: 'Result',
  paidAmount: 'Paid amount',
  txHash: 'TX Hash',
};

type TTableTitlesEnTranslation = typeof tableHeaderEn;

export const tableHeaderRu: TTableTitlesEnTranslation = {
  sessionId: 'ID сессии',
  orderId: 'ID платежа',
  clientId: 'ID клиента',
  paymentType: 'Тип оплаты',
  paymentMethod: 'Способ оплаты',
  status: 'Статус',
  amount: 'Сумма',
  currency: 'Валюта',
  created: 'Создано',
  paid: 'Оплачено',
  code: 'Код страны',
  cardAndOrderId: 'Номер карты / ID платежа',
  period: 'Период',
  email: 'Email',
  createdAndAmount: 'Создано / Сумма',
  nameAndAuthor: 'Название / Автор',
  download: 'Скачать',
  delete: 'Удалить',
  processingName: 'Процессинг',
  phoneAndOrderId: 'Телефон / ID платежа',
  description: 'Описание',
  idAndOrderId: 'ID / ID платежа',
  publicId: 'Публичный ID',
  date: 'Дата',
  type: 'Тип',
  result: 'Результат',
  paidAmount: 'Сумма оплаченная',
  txHash: 'TX Hash',
};
