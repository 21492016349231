import { useMemo } from 'react';
import { useContracts, useSelectedContract } from '../contracts';

/**
 * Возвращает доступные APM методы оплаты для конкретного контракта
 */
export const useAvailableApmPaymentsMethods = () => {
  const contract = useSelectedContract();

  return contract?.apmPaymentsMethods;
};

/**
 * Получаем все возможные APM методы оплаты по выбранным контрактам
 * Если передан пустой массив, то будет искать по всем контрактам
 */
export const useApmPaymentsMethods = (ids: number[] = []) => {
  const contracts = useContracts();

  return useMemo(() => {
    const isAllContractsInSelection = !ids.length;

    if (!contracts) {
      return [];
    }

    const allMethods = contracts.reduce<string[]>((acc, contract) => {
      const methods = contract?.apmPaymentsMethods || [];

      if (isAllContractsInSelection) {
        acc.push(...methods);
      } else if (ids.includes(contract.id)) {
        acc.push(...methods);
      }

      return acc;
    }, []);

    return Array.from(new Set(allMethods));
  }, [contracts, ids]);
};
