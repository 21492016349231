import { TPaymentsAnalyticsQueryParams } from '@payler/api/merchant-cabinet';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useMerchantContractsQuery } from '../hooks/contracts';
import dayjs from 'dayjs';

//endregion

//region params and response
/**
 * параметры запроса по умолчанию
 */
const useDefaults = () => {
  const { data: contracts } = useMerchantContractsQuery();
  const currencies =
    contracts
      ?.flatMap((x) => x.currencyCodes)
      .filter((v, i, a) => a.indexOf(v) === i) ?? [];
  const ContractIds = contracts?.map((x) => x.id) ?? [];
  const CurrencyCode = currencies.includes('RUB')
    ? 'RUB'
    : (currencies[0] as string);
  return {
    ContractIds,
    CurrencyCode,
    StartDate: dayjs().add(-1, 'month').add(1, 'day').format('YYYY-MM-DD'),
    EndDate: dayjs().format('YYYY-MM-DD'),
    PaymentMethods: [],
    TransactionStatuses: [],
  };
};

type TAnalyticsPageContext = {
  params: TPaymentsAnalyticsQueryParams;
  setParams: Dispatch<SetStateAction<TPaymentsAnalyticsQueryParams>>;
};

const AnalyticsPageContext = createContext<TAnalyticsPageContext>({
  params: {} as TPaymentsAnalyticsQueryParams,
  setParams: (params) => console.log('AnalyticsPageContext.setParams', params),
});

export const AnalyticsPageContextProvider: FCC = ({ children }) => {
  const defaultParams = useDefaults();
  const [params, setParams] =
    useState<TPaymentsAnalyticsQueryParams>(defaultParams);
  return (
    <AnalyticsPageContext.Provider value={{ params, setParams }}>
      {children}
    </AnalyticsPageContext.Provider>
  );
};

export const useAnalyticsPageContext = () => useContext(AnalyticsPageContext);
