import { useParams, useSearchParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { isNil, omitBy } from 'lodash';
import { getFloatOrUndefined } from '../../helpers/url-params';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { TCryptoPaymentsURLParams } from './types';
import { assert } from '@payler/utils';
import { TCryptoPaymentsFiltersForm } from '../../forms/CryptoPaymentsFilters/types';

const formValuesToParams = (
  values: TCryptoPaymentsFiltersForm,
): TCryptoPaymentsURLParams => {
  const tmp: TCryptoPaymentsURLParams = {
    ClientId: values.ClientId ?? undefined,
    SessionStatus: values.SessionStatus ? values.SessionStatus : undefined,
    EndDate: values.EndDate ? values.EndDate : undefined,
    StartDate: values.StartDate ? values.StartDate : undefined,
    SessionId: values.SessionId ? values.SessionId : undefined,
    Currency: values.Currency ? values.Currency : undefined,
    PaymentType: values.PaymentType ? values.PaymentType : undefined,
    StartAmount: values.StartAmount?.toString() ?? undefined,
    EndAmount: values.EndAmount?.toString() ?? undefined,
  };
  return omitBy(tmp, isNil);
};

const queryToFilters = (params: URLSearchParams) => {
  return {
    ClientId: params.get('ClientId' ?? ''),
    SessionStatus: params.get('SessionStatus') ?? '',
    EndDate: params.get('EndDate') ?? '',
    StartDate: params.get('StartDate') ?? '',
    SessionId: params.get('SessionId') ?? '',
    Currency: params.get('Currency') ?? '',
    PaymentType: params.get('PaymentType') ?? '',
    StartAmount: getFloatOrUndefined(params.get('StartAmount')),
    EndAmount: getFloatOrUndefined(params.get('EndAmount')),
  } as TCryptoPaymentsFiltersForm;
};

export const useSetCryptoPaymentsUrlSearchParams = () => {
  const [, setParams] = useSearchParams();
  const { PageSize } = usePagerPropsFromUrl();

  return useCallback(
    (values: TCryptoPaymentsFiltersForm) => {
      const params = formValuesToParams(values);
      if (PageSize !== 10) {
        params.PageSize = PageSize.toString();
      }
      //Page by default is 0
      setParams(params);
    },
    [PageSize, setParams],
  );
};

export const useCryptoPaymentsFiltersFromUrl = () => {
  const [params] = useSearchParams();
  return useMemo(() => queryToFilters(params), [params]);
};

export const useCryptoPaymentSessionIdFromUrl = () => {
  const { id } = useParams();
  assert(!!id, 'Session id mast be string');
  return id;
};
