import {
  chakra,
  HStack,
  Icon,
  Menu,
  MenuButtonProps,
  MenuList,
  Text,
  useMenuButton,
  useMenuState,
  VStack,
} from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { TextStyles } from '@payler/ui-theme';
import { ChevronDownIcon } from '@payler/ui-icons';
import { useTranslation } from 'react-i18next';
import { ThinDivider } from '@payler/ui-components';
import { TStep, useChartStepContext } from '../../../context/ChartStepContext';

const Btn = forwardRef<HTMLDivElement, MenuButtonProps>((props, ref) => {
  const { isOpen } = useMenuState();
  const { t } = useTranslation('analytics');
  const { step } = useChartStepContext();
  const buttonProps = useMenuButton(props, ref);
  return (
    <HStack
      {...buttonProps}
      userSelect="none"
      justifyContent="space-between"
      color="primary.350"
      _hover={{
        color: 'primary.500',
      }}
      spacing={0.5}
      cursor="pointer"
      transitionDuration="normal"
    >
      <Text textStyle={TextStyles.BodyUI16Medium} color="inherit">
        {t(`analytics:${step}`)}
      </Text>
      <Icon
        as={ChevronDownIcon}
        color="inherit"
        w="20px"
        h="20px"
        transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
        transitionDuration="normal"
      />
    </HStack>
  );
});

/**
 * см. ChartStepContext
 * @constructor
 */
export const StepDropdown = () => {
  return (
    <Menu isLazy preventOverflow placement="bottom">
      <Btn />
      <MenuList
        minW={0}
        py={0}
        bg="secondary.500"
        borderRadius={1.5}
        border="1px solid"
        borderColor="primary.100"
      >
        <Body />
      </MenuList>
    </Menu>
  );
};

const Body = () => {
  return (
    <VStack spacing={0} divider={<ThinDivider />} alignItems="stretch">
      <Item value="days" />
      <Item value="weeks" />
      <Item value="months" />
    </VStack>
  );
};

const Item = ({ value }: { value: TStep }) => {
  const { step, setStep } = useChartStepContext();
  const selected = value === step;
  const { t } = useTranslation();
  const color = selected ? 'primary.500' : 'primary.350';
  const { onClose } = useMenuState();
  return (
    <chakra.div
      cursor="pointer"
      role="button"
      h={6}
      display="flex"
      alignItems="center"
      px={2}
      color={color}
      _hover={{
        color: 'primary.500',
        bg: 'primary.25',
      }}
      transitionDuration="normal"
      textStyle={TextStyles.BodyUI16Regular}
      onClick={() => {
        setStep(value);
        onClose();
      }}
    >
      <span style={{ flex: 1, textAlign: 'center' }}>
        {t(`analytics:${value}`)}
      </span>
    </chakra.div>
  );
};
