import { Box } from '@chakra-ui/react';
import { Pagination, PaginationProps } from '@payler/ui-components';
import React from 'react';

type Props = {
  paginationProps: PaginationProps;
};

export const TableFooter = ({ paginationProps }: Props) => {
  if (paginationProps.totalRecords === 0) {
    return null;
  }

  if (
    paginationProps.totalRecords <= paginationProps.pageSize &&
    paginationProps.pageSize === 10
  ) {
    return null;
  }

  return (
    <Box py={2} px={3}>
      <Pagination {...paginationProps} />
    </Box>
  );
};
