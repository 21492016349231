import React, { Suspense } from 'react';
import { HStack } from '@chakra-ui/react';
import { ContractsDropdown } from './ContractsDropdown';
import { CurrenciesDropdown } from './CurrenciesDropdown';
import { PeriodDropdown } from './PeriodDropdown';

const ChartFiltersComp = () => {
  return (
    <HStack spacing="3" justifyContent="flex-end" flexShrink={0}>
      <PeriodDropdown />
      <CurrenciesDropdown />
      <ContractsDropdown />
    </HStack>
  );
};

export const ChartFilters = () => (
  <Suspense fallback={null}>
    <ChartFiltersComp />
  </Suspense>
);

export default ChartFilters;
