import { useTranslation } from 'react-i18next';
import { Box, HStack, useBreakpointValue, VStack } from '@chakra-ui/react';
import { config } from './config';
import React from 'react';
import { OptionSpan } from '../OptionSpan/OptionSpan';

const spacing = { base: 2, md: 2, xl: 2, '2xl': 5 };

export const PieChartLegend = () => {
  const visible = useBreakpointValue({ base: false, xs: true });
  const { t } = useTranslation('transactionStatus');
  if (!visible) return null;
  return (
    <VStack pt={2}>
      <HStack justifyContent="center" spacing={spacing}>
        <HStack spacing={0.5}>
          <Box
            w="6px"
            h="6px"
            borderRadius="3px"
            bg={config['charged']?.color}
          />
          <OptionSpan color="primary.350">{t('Charged')}</OptionSpan>
        </HStack>
        <HStack spacing={0.5}>
          <Box
            w="6px"
            h="6px"
            borderRadius="3px"
            bg={config['authorized']?.color}
          />
          <OptionSpan color="primary.350">{t('Authorized')}</OptionSpan>
        </HStack>
        <HStack spacing={0.5}>
          <Box
            w="6px"
            h="6px"
            borderRadius="3px"
            bg={config['refunded']?.color}
          />
          <OptionSpan color="primary.350">{t('Refunded')}</OptionSpan>
        </HStack>
      </HStack>
      <HStack justifyContent="center" spacing={spacing}>
        <HStack spacing={0.5}>
          <Box
            w="6px"
            h="6px"
            borderRadius="3px"
            bg={config['rejectedOnlyDebit']?.color}
          />
          <OptionSpan color="primary.350">{t('RejectedOnlyDebit')}</OptionSpan>
        </HStack>
        <HStack spacing={0.5}>
          <Box
            w="6px"
            h="6px"
            borderRadius="3px"
            bg={config['credited']?.color}
          />
          <OptionSpan color="primary.350">{t('Credited')}</OptionSpan>
        </HStack>
      </HStack>
    </VStack>
  );
};
