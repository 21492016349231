import { useTranslation } from 'react-i18next';
import { Controller, Path, useFormContext } from 'react-hook-form';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@payler/ui-components';
import { Box, Checkbox, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { TextStyles } from '@payler/ui-theme';
import { CloseCircle14Icon } from '@payler/ui-icons';
import createLogger from 'debug';
import { OptionSpan } from '../OptionSpan/OptionSpan';
import { FieldValues } from 'react-hook-form/dist/types';

const log = createLogger('components:FiltersOptionsArrayAccordion');

type Props<T, F> = {
  /**
   * ключ локализации названия поля
   */
  fieldNameKey: string;
  /**
   * Поле формы
   */
  formFieldName: Path<F>;
  /**
   * Список опций
   */
  options: T[];
  /**
   * Префикс ключа локализации опций
   */
  optionsPrefix: string;
  /** Разрешать выбирать несколько */
  multi?: boolean;
  /** Скрыть кнопку очистки фильтра */
  hideClearIcon?: boolean;
};

/**
 * Аккордеон со списком опций для отображения в фильтрах
 *
 * @param options - массив опций
 * @param optionsPrefix - префикс ключа локализации опций
 * @param formFieldName - имя поля в форме
 * @param fieldNameKey - ключ локализации поля
 * @param multi - массив или одно значение
 * @param hideClearIcon - скрытие кнопки очистки
 * @constructor
 */
export const FiltersOptionsArrayAccordion = <F extends FieldValues, T>({
  options,
  optionsPrefix,
  formFieldName,
  fieldNameKey,
  multi,
  hideClearIcon,
}: Props<T, F>) => {
  return (
    <Accordion alignSelf="stretch">
      <Header<F>
        fieldNameKey={fieldNameKey}
        formFieldName={formFieldName}
        optionsPrefix={optionsPrefix}
        multi={multi}
        hideClearIcon={hideClearIcon}
      />
      <FilterAccordionBody<T, F>
        options={options}
        formFieldName={formFieldName}
        optionsPrefix={optionsPrefix}
        multi={multi}
      />
    </Accordion>
  );
};

//region header
type HeaderProps<F> = Omit<Props<never, F>, 'options'>;
const Header = <FormType extends FieldValues>({
  fieldNameKey,
  formFieldName,
  optionsPrefix,
  multi,
  hideClearIcon,
}: HeaderProps<FormType>) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<FormType>();
  const value = watch(formFieldName);
  let text;
  let handleClear;
  let showClearButton;
  if (hideClearIcon) {
    showClearButton = false;
  } else {
    showClearButton = multi
      ? !!(value && Array.isArray(value) && value.length)
      : !!value;
  }
  if (multi) {
    text = Array.isArray(value)
      ? value.map((x: string) => t(`${optionsPrefix}${x}`)).join(', ')
      : undefined;
    handleClear = () => setValue(formFieldName, [] as never);
  } else {
    text = value ? t(`${optionsPrefix}${value}`) : undefined;
    handleClear = () => setValue(formFieldName, null as never);
  }

  return (
    <HeaderDisplay
      name={t(fieldNameKey)}
      text={text}
      showClearButton={showClearButton}
      onClear={handleClear}
    />
  );
};
type TFilterAccordionHeaderProps = {
  name: string;
  text?: string;
  showClearButton?: boolean;
  onClear?: VoidFunction;
};
const HeaderDisplay: FC<TFilterAccordionHeaderProps> = ({
  name,
  text,
  showClearButton,
  onClear,
}) => {
  return (
    <AccordionHeader>
      <HStack alignItems="center" spacing={2}>
        <Box flex={1}>
          <Text textStyle={TextStyles.tables} color="primary.500">
            {name}
          </Text>
          <Text textStyle={TextStyles.Caption12Regular} color="primary.300">
            {text}
          </Text>
        </Box>
        {showClearButton && (
          <Icon
            as={CloseCircle14Icon}
            onClick={(e) => {
              e.stopPropagation();
              onClear?.();
            }}
          />
        )}
      </HStack>
    </AccordionHeader>
  );
};
//endregion

//region body
type FilterAccordionOptionsBody<T, F> = {
  options: T[];
  formFieldName: Path<F>;
  optionsPrefix: string;
  multi?: boolean;
};
const FilterAccordionBody = <OptionType, FormType extends FieldValues>({
  options,
  formFieldName,
  optionsPrefix,
  multi,
}: FilterAccordionOptionsBody<OptionType, FormType>) => {
  const { t } = useTranslation();
  const { control } = useFormContext<FormType>();
  return (
    <Controller
      control={control}
      name={formFieldName}
      render={({ field: { value, onChange } }) => {
        return (
          <AccordionBody p={2}>
            <VStack spacing={2} alignItems="flex-start">
              {options.map((x) => {
                const isChecked = multi
                  ? ((Array.isArray(value) && value.includes(x)) ?? false)
                  : value === x;
                const optionValue = x as unknown as string;
                const optionText = t(`${optionsPrefix}${optionValue}`);
                return (
                  <Checkbox
                    key={optionText}
                    variant="rounded"
                    value={optionValue}
                    isChecked={isChecked}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => {
                      log(
                        'onChange value: %s, isChecked: %s, optionValue: %s',
                        value,
                        isChecked,
                        optionValue,
                      );
                      if (!multi) {
                        log('single value: %s', optionValue);
                        onChange(optionValue);
                      } else {
                        const currentVal = !value
                          ? []
                          : Array.isArray(value)
                            ? value
                            : [value];
                        const newValue = !isChecked
                          ? [...currentVal, optionValue]
                          : (currentVal as string[]).filter(
                              (x) => x !== optionValue,
                            );
                        log('multi value: %s, (was: %s)', newValue, currentVal);
                        onChange(newValue);
                      }
                    }}
                  >
                    <OptionSpan>{optionText}</OptionSpan>
                  </Checkbox>
                );
              })}
            </VStack>
          </AccordionBody>
        );
      }}
    />
  );
};
//endregion
