import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  FloatingInputField,
} from '@payler/ui-components';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, HStack, Icon, Radio, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { CloseCircle14Icon } from '@payler/ui-icons';
import { getNumericRangeKey } from '../../helpers/ranges';
import { OptionSpan } from '../OptionSpan/OptionSpan';
import { isNil } from 'lodash';
import { NumberFormatValues } from 'react-number-format';

type Props = {
  /** название поля с суммой ОТ */
  formFieldFrom: string;
  /** название поля с суммой ДО */
  formFieldTo: string;
  /** ключ локализации заголовка суммы ОТ */
  fromKey: string;
  /** ключ локализации заголовка суммы ДО */
  toKey: string;
  /** ключ локализации заголовка */
  titleKey: string;
  /** префикс ключа локализации опций */
  optionsPrefixKey?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
};

/**
 * Аккордеон для выбора диапазона суммы со списком пресетов
 */
export const FiltersAmountRangeAccordion = (props: Props) => {
  return (
    <Accordion alignSelf="stretch">
      <Header {...props} />
      <AccordionBody p={2}>
        <Presets {...props} />
        <NumberRangeFields {...props} />
      </AccordionBody>
    </Accordion>
  );
};

const Presets = ({ formFieldTo, formFieldFrom }: Props) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const [from, to] = watch([formFieldFrom, formFieldTo]);
  const opt1 = isNil(from) && to === 10_000;
  const opt2 = from === 10_000 && to === 100_000;
  const opt3 = from === 100_000 && to === 1_000_000;
  const opt4 = from === 1_000_000 && isNil(to);
  const opt5 = !opt1 && !opt2 && !opt3 && !opt4;

  const setOpt1 = useCallback(() => {
    setValue(formFieldFrom, null);
    setValue(formFieldTo, 10_000);
  }, [formFieldFrom, formFieldTo, setValue]);

  const setOpt2 = useCallback(() => {
    setValue(formFieldFrom, 10_000);
    setValue(formFieldTo, 100_000);
  }, [formFieldFrom, formFieldTo, setValue]);

  const setOpt3 = useCallback(() => {
    setValue(formFieldFrom, 100_000);
    setValue(formFieldTo, 1_000_000);
  }, [formFieldFrom, formFieldTo, setValue]);

  const setOpt4 = useCallback(() => {
    setValue(formFieldFrom, 1_000_000);
    setValue(formFieldTo, null);
  }, [formFieldFrom, formFieldTo, setValue]);

  const setOpt5 = useCallback(() => {
    setValue(formFieldFrom, null);
    setValue(formFieldTo, null);
  }, [setValue, formFieldFrom, formFieldTo]);

  return (
    <VStack alignItems="flex-start" spacing={3} pb={3}>
      <div onClick={setOpt1}>
        <Radio isChecked={opt1}>
          <OptionSpan>{t('filters:opt1')}</OptionSpan>
        </Radio>
      </div>

      <div onClick={setOpt2}>
        <Radio isChecked={opt2}>
          <OptionSpan>{t('filters:opt2')}</OptionSpan>{' '}
        </Radio>
      </div>

      <div onClick={setOpt3}>
        <Radio isChecked={opt3}>
          <OptionSpan>{t('filters:opt3')}</OptionSpan>{' '}
        </Radio>
      </div>
      <div onClick={setOpt4}>
        <Radio isChecked={opt4}>
          <OptionSpan>{t('filters:opt4')}</OptionSpan>{' '}
        </Radio>
      </div>
      <div onClick={setOpt5}>
        <Radio isChecked={opt5}>
          <OptionSpan>{t('filters:opt5')}</OptionSpan>
        </Radio>
      </div>
    </VStack>
  );
};

const NumberRangeFields = (props: Props) => {
  const { t } = useTranslation();
  return (
    <HStack width="100%" justifyContent="stretch" alignItems="baseline">
      <FloatingInputField
        name={props.formFieldFrom}
        label={t(props.fromKey)}
        variant="currency"
        isAllowed={props.isAllowed}
      />
      <FloatingInputField
        name={props.formFieldTo}
        label={t(props.toKey)}
        variant="currency"
        isAllowed={props.isAllowed}
      />
    </HStack>
  );
};

const Header = ({ formFieldTo, formFieldFrom, titleKey }: Props) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const [from, to] = watch([formFieldFrom, formFieldTo]) as (string | null)[];
  const showClearButton = from || to;
  const key = getNumericRangeKey(from, to);
  const text = t(key, { start: from, end: to });

  return (
    <AccordionHeader>
      <HStack alignItems="center" spacing={2}>
        <Box flex={1} minW={0}>
          <Text textStyle={TextStyles.tables} color="primary.500">
            {t(titleKey)}
          </Text>
          {showClearButton && (
            <Text textStyle={TextStyles.Caption12Regular} color="primary.300">
              {text}
            </Text>
          )}
        </Box>
        {showClearButton && (
          <Icon
            as={CloseCircle14Icon}
            onClick={(e) => {
              e.stopPropagation();
              setValue(formFieldFrom, null);
              setValue(formFieldTo, null);
            }}
          />
        )}
      </HStack>
    </AccordionHeader>
  );
};
