import React from 'react';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import RetrievePaymentForm from '../forms/RetrievePaymentForm/RetrievePaymentForm';
import { PaylerModal } from '@payler/ui-components';
import { useTranslation } from 'react-i18next';

const retrievePaymentModalOpenedAtom = atom<number | undefined>({
  key: createRecoilKey('retrievePaymentModalOpenedAtom'),
  default: undefined,
});

export const useShowRetrievePaymentModal = () =>
  useRecoilCallback(
    ({ set }) =>
      (id: number) => {
        set(retrievePaymentModalOpenedAtom, id);
      },
    [],
  );

export const useCloseRetrievePaymentModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(retrievePaymentModalOpenedAtom, undefined);
      },
    [],
  );

export const RetrievePaymentModal = () => {
  const { t } = useTranslation('payments');
  const id = useRecoilValue(retrievePaymentModalOpenedAtom);
  const close = useCloseRetrievePaymentModal();
  return (
    <PaylerModal close={close} open={!!id} title={t('rejectTitle')}>
      {!!id && <RetrievePaymentForm id={id} onSubmit={close} />}
    </PaylerModal>
  );
};
