import { TMerchantCabinetTranslation } from './en';
import { fieldsRu } from './namespaces/fields';
import { transactionStatusRu } from './namespaces/enums/transactionStatus';
import { paymentIndicatorRu } from './namespaces/enums/paymentIndicator';
import { paymentMethodRu } from './namespaces/enums/paymentMethod';
import { rolesRu } from './namespaces/enums/roles';
import { analyticsRu } from './namespaces/analytics';
import { reportsRu } from './namespaces/reports';
import { reportStatusRu } from './namespaces/enums/reportStatus';
import { paymentsRu } from './namespaces/payments';
import { menuRu } from './namespaces/menu';
import { notificationsKindRu } from './namespaces/enums/notificationKind';
import { notificationsRu } from './namespaces/notifications';
import { usersRu } from './namespaces/users';
import { invoicesRu } from './namespaces/invoices';
import { authRu } from './namespaces/auth';
import { fasterPayoutsRu } from './namespaces/faster-payouts';
import { settingsRu } from './namespaces/settings';
import { withdrawalsRu } from './namespaces/withdrawals';
import { commonRu } from './namespaces/common';
import { filtersRu } from './namespaces/filters';
import { titlesRu } from './namespaces/titles';
import { timeRu } from './namespaces/time';
import { fasterPaymentsRu } from './namespaces/faster-payments';
import { apmPaymentsRu } from './namespaces/apm-payments';
import { cryptoPaymentsRu } from './namespaces/crypto-payments';
import { tableHeaderRu } from './namespaces/table-header';

const translationRu: TMerchantCabinetTranslation = {
  analytics: analyticsRu,
  auth: authRu,
  common: commonRu,
  fasterPayouts: fasterPayoutsRu,
  fasterPayments: fasterPaymentsRu,
  apmPayments: apmPaymentsRu,
  cryptoPayments: cryptoPaymentsRu,
  fields: fieldsRu,
  filters: filtersRu,
  invoices: invoicesRu,
  menu: menuRu,
  notificationKind: notificationsKindRu,
  notifications: notificationsRu,
  paymentIndicator: paymentIndicatorRu,
  paymentMethod: paymentMethodRu,
  payments: paymentsRu,
  reports: reportsRu,
  reportStatus: reportStatusRu,
  roles: rolesRu,
  settings: settingsRu,
  transactionStatus: transactionStatusRu,
  users: usersRu,
  withdrawals: withdrawalsRu,
  titles: titlesRu,
  time: timeRu,
  tableHeader: tableHeaderRu,
} as const;

export default translationRu;
