import { ResourceKey } from 'i18next';

export const timeEn = {
  hours_one: '{{count}}\u00a0hour',
  hours_other: '{{count}}\u00a0hours',
  minutes_one: '{{count}}\u00a0minute',
  minutes_other: '{{count}}\u00a0minutes',
  days_one: '{{count}}\u00a0day',
  days_other: '{{count}}\u00a0days',
} as ResourceKey;

export const timeRu = {
  days_one: '{{count}}\u00a0день',
  days_few: '{{count}}\u00a0дня',
  days_many: '{{count}}\u00a0дней',
  hours_one: '{{count}}\u00a0час',
  hours_few: '{{count}}\u00a0часа',
  hours_many: '{{count}}\u00a0часов',
  minutes_one: '{{count}}\u00a0минута',
  minutes_few: '{{count}}\u00a0минуты',
  minutes_many: '{{count}}\u00a0минут',
} as ResourceKey;
