import { useEffect, useState } from 'react';
import { useUIDateFormat } from './use-ui-dateFormat';
import dayjs from 'dayjs';

const getFormattedDate = (fmt: string) => {
  return dayjs().add(-1, 'day').format(fmt);
};

/**
 * Обновляемая предыдущая дата. Для использования в костыле с фильтрами
 */
export const usePreviousDate = () => {
  const dateFormat = useUIDateFormat();
  const [date, setDate] = useState(getFormattedDate(dateFormat));
  useEffect(() => {
    const now = dayjs();
    const nextDay = now.add(1, 'day').startOf('day');
    const nextDayMs = nextDay.valueOf();
    const ms = now.valueOf();
    const diff = nextDayMs - ms;
    setTimeout(() => {
      setDate(getFormattedDate(dateFormat));
    }, diff);
  }, [dateFormat, date]);
  return date;
};
