import { Icon, IconProps } from '@chakra-ui/react';
import { PaylerLogo } from '@payler/ui-icons';

export const Logo = (props: Omit<IconProps, 'css'>) => (
  <Icon
    as={PaylerLogo}
    w="148px"
    h="24px"
    color="primary.500"
    m={{ base: 2, sm: 4 }}
    ml={2}
    flexGrow={0}
    {...props}
  />
);
