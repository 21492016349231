export const commonEn = {
  loading: 'Loading...',
  datePicker: {
    from: 'From',
    to: 'To',
    date: 'Date',
    badValue: 'Please enter a valid date',
    minError: 'Date must be after {{minDate}}',
    maxError: 'Date must be before {{maxDate}}',
    'DD.MM.YYYY': 'DD.MM.YYYY',
  },
  allContracts: 'All contracts selected',
  apply: 'Apply',
  back: 'Back',
  cancel: 'Cancel',
  clickToCopy: 'Copy',
  copied: 'Done',
  credit: 'Credit',
  currencies: 'Currency',
  darkMode: 'Dark theme',
  debit: 'Debit',
  error: 'Error',
  home: 'Home',
  no: 'No',
  noAccess: 'Not enough rights',
  noData: 'No data',
  notFound: {
    header: 'Page not found',
    description: "Looks like we don't have this page",
    linkBack: 'Return to the Home page →',
  },
  pager: {
    of: 'of',
    rowsPerPage: 'Rows\u00a0per\u00a0page',
    showed: 'Showed',
  },
  range: {
    empty: '',
    from: 'From {{start}}',
    fromTo: 'From {{start}} to {{end}}',
    to: 'To {{end}}',
  },
  save: 'Save',
  selectAll: 'Select All',
  selectedCountOf: 'Selected: {{ count }} of {{ total }}',
  serverError: 'Server error {{status}}',
  success: 'Success',
  sumRange: {
    from: 'From {{start, number}}',
    fromTo: 'From {{start, number}} to {{end, number}}',
    to: 'To {{end, number}}',
  },
  unknownError: 'Unknown error {{message}}',
  validate: {
    expired: 'Expired',
    wrongFormat: 'Wrong format',
    required: 'Required',
    minPasswordLength: 'Password length not less than 8 characters',
    notMatchPasswordPattern:
      'Password must contain at least one uppercase letter, one lowercase letter, number and special character (. -?! @ & +)',
    doNotMatchPassword: 'The passwords entered do not match',
    url: 'Specify the return url correctly',
    ips: 'Specify ip-address correctly',
    amount: 'Enter an amount',
  },
  yes: 'Yes',
  errorPage: {
    header: 'Something went wrong',
    description: '',
    linkBack: 'Reload page →',
  },
};

type TCommonTranslation = typeof commonEn;

export const commonRu: TCommonTranslation = {
  loading: 'Загрузка',
  datePicker: {
    from: 'От',
    to: 'До',
    date: 'Дата',
    badValue: 'Пожалуйста введите корректную дату',
    minError: 'Дата должна быть после {{minDate}}',
    maxError: 'Дата должна быть до {{maxDate}}',
    'DD.MM.YYYY': 'ДД.ММ.ГГГГ',
  },
  allContracts: 'Выбраны все контракты',
  apply: 'Применить',
  back: 'Назад',
  cancel: 'Отмена',
  clickToCopy: 'Скопировать',
  copied: 'Готово',
  credit: 'Выплата',
  currencies: 'Валюта',
  darkMode: 'Тёмная тема',
  debit: 'Списание',
  error: 'Ошибка',
  home: 'Главная',
  no: 'Нет',
  noAccess: 'Недостаточно прав',
  noData: 'Нет данных',
  notFound: {
    header: 'Страница не найдена',
    description: 'Похоже, такой страницы не существует',
    linkBack: 'Вернуться на главную →',
  },
  pager: {
    of: 'из',
    rowsPerPage: 'Строк\u00a0на\u00a0странице',
    showed: 'Показано',
  },
  range: {
    empty: '',
    from: 'От {{start}}',
    fromTo: 'От {{start}} до {{end}}',
    to: 'До {{end}}',
  },
  save: 'Сохранить',
  selectAll: 'Выбрать всё',
  selectedCountOf: 'Выбрано: {{ count }} из {{ total }}',
  serverError: 'Ошибка сервера {{status}}',
  success: 'Успех',
  sumRange: {
    from: 'От {{start, number}}',
    fromTo: 'От {{start, number}} до {{end, number}}',
    to: 'До {{end, number}}',
  },
  unknownError: 'Неизвестная ошибка: {{message}}',
  validate: {
    wrongFormat: 'Неверный формат',
    expired: 'Срок действия истёк',
    required: 'Обязательно к заполнению',
    minPasswordLength: 'Длина пароля не мене 8 символов',
    notMatchPasswordPattern:
      'Пароль должен содержать минимум одну заглавную букву, одну строчную букву, цифру и спецсимвол ( . - ? ! @ & +)',
    doNotMatchPassword: 'Введенные пароли не совпадают',
    url: 'Укажите url возврата корректно',
    ips: 'Укажите ip-address корректно',
    amount: 'Введите сумму',
  },
  yes: 'Да',
  errorPage: {
    header: 'Что-то пошло не так',
    description: '',
    linkBack: 'Перезагрузить страницу →',
  },
};
