import { useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import PaymentsFiltersForm from '../forms/PaymentsFilters/PaymentsFiltersForm';
import { PaylerModal } from '@payler/ui-components';

const antifraudPaymentsFiltersModalOpenedAtom = atom({
  key: createRecoilKey('antifraudPaymentsFiltersModalOpenedAtom'),
  default: false,
});

export const useShowAntifraudPaymentsFiltersModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(antifraudPaymentsFiltersModalOpenedAtom, true);
      },
    [],
  );

export const useCloseAntifraudPaymentsFiltersModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(antifraudPaymentsFiltersModalOpenedAtom, false);
      },
    [],
  );

export const AntifraudPaymentsFiltersModal = () => {
  const visible = useBreakpointValue({ base: true, lg: false });
  const open = useRecoilValue(antifraudPaymentsFiltersModalOpenedAtom);
  const close = useCloseAntifraudPaymentsFiltersModal();
  if (!visible) return null;
  return (
    <PaylerModal close={close} open={open}>
      <PaymentsFiltersForm />
    </PaylerModal>
  );
};
