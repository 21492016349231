import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import 'dayjs/locale/ru';
import 'dayjs/locale/es';
import 'dayjs/locale/en';

import dayjs from 'dayjs';

export const useDayjsFormat = () => {
  const {
    i18n: { language },
  } = useTranslation();
  return useCallback(
    (date: dayjs.ConfigType, format: string) =>
      date ? dayjs(date).locale(language).format(format) : undefined,
    [language]
  );
};
