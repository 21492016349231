import { useContracts } from './contracts';
import { useMemo } from 'react';
import { useURLContractId } from '../layouts/ContractIdLayout/ContractIdLayout';

/**
 * Валюты выбранного контракта (контракта из URL)
 */
export const useURLContractCurrencies = () => {
  const allContracts = useContracts();
  const contractId = useURLContractId();
  return useMemo(() => {
    return contractId
      ? (allContracts?.find((c) => c.id === contractId)?.currencyCodes ?? [])
      : [];
  }, [allContracts, contractId]);
};

/**
 * APM Валюты выбранного контракта (контракта из URL)
 */
export const useURLApmContractCurrencies = () => {
  const allContracts = useContracts();
  const contractId = useURLContractId();
  return useMemo(() => {
    return contractId
      ? (allContracts?.find((c) => c.id === contractId)
          ?.apmPaymentsCurrencyCodes ?? [])
      : [];
  }, [allContracts, contractId]);
};
