import { TooltipProps } from 'recharts';
import { Card, CardContent, ThinDivider } from '@payler/ui-components';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { CurrencyDisplay } from '../../CurrencyDisplay/CurrencyDisplay';
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDayjsFormat } from '@payler/utils';
import { useAnalyticsPageContext } from '../../../context/AnalyticsPageContext';
import { useChartStepContext } from '../../../context/ChartStepContext';

export const TooltipSumByStatus = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  const { t } = useTranslation();
  const formatDate = useDayjsFormat();
  const {
    params: { CurrencyCode },
  } = useAnalyticsPageContext();
  const { step } = useChartStepContext();
  if (active && payload && payload.length) {
    return (
      <Card bg="bgTooltip" spacing={2} backdropFilter="blur(6px)">
        <CardContent>
          <VStack spacing={1} alignItems="flex-start">
            <Text textStyle={TextStyles.Caption12Medium}>
              {step === 'days' && formatDate(label, 'DD MMMM YYYY')}
              {step === 'weeks' && (
                <>
                  {t('analytics:weekNumYear', {
                    week: dayjs(label).week(),
                    year: dayjs(label).year(),
                  })}
                </>
              )}
              {step === 'months' && formatDate(label, 'MMMM, YYYY')}
            </Text>
            <ThinDivider />
            <HStack spacing={2}>
              {payload.map((x, i) => (
                <VStack alignItems="flex-start" spacing={0} key={i}>
                  <Text
                    textStyle={TextStyles.Caption12Regular}
                    fontSize="10px"
                    color="primary.350"
                  >
                    {x.name}
                  </Text>
                  <Text
                    textStyle={TextStyles.Caption12Medium}
                    color="primary.500"
                  >
                    <CurrencyDisplay value={x.value} currency={CurrencyCode} />
                  </Text>
                </VStack>
              ))}
            </HStack>
          </VStack>
        </CardContent>
      </Card>
    );
  }

  return null;
};
