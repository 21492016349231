import { Box, Button, Text } from '@chakra-ui/react';
import { InputField } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResetPassword } from '../../hooks/user/auth';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import { FormProvider, useForm } from 'react-hook-form';

export const ResetPasswordForm: React.FC<{
  onSubmitSuccess: () => void;
}> = ({ onSubmitSuccess }) => {
  const [error, setError] = useState('');
  const getError = useGetAxiosError();
  const { t } = useTranslation();
  const { mutate, isPending: isLoading } = useResetPassword();
  const handleSubmit = useCallback(
    async ({ email }: { email?: string }) => {
      setError('');
      mutate(email ?? '', {
        onSuccess: onSubmitSuccess,
        onError: (e) => setError(getError(e)),
      });
    },
    [getError, mutate, onSubmitSuccess],
  );
  const methods = useForm();
  return (
    <Box mt={{ base: 10, sm: 16 }} w="100%">
      <FormProvider {...methods}>
        <Box
          as="form"
          height="100%"
          position="relative"
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <Text as="h1" textStyle={TextStyles.h1}>
            {t('auth:resetPassword.header')}
          </Text>
          <Text
            mt={1}
            mb={5}
            textStyle={TextStyles.BodyUI16Regular}
            color="primary.400"
          >
            {t('auth:resetPassword.description')}
          </Text>
          <InputField name="email" placeholder={t('auth:emailPlaceholder')} />
          {error && <ApiErrorText>{error}</ApiErrorText>}
          <Box
            display="flex"
            mt={5}
            w="100%"
            position={{ base: 'absolute', sm: 'initial' }}
            left="0"
            right="0"
            bottom={1}
          >
            <Button
              isLoading={isLoading}
              isDisabled={isLoading}
              type="submit"
              variant="primary"
              w="100%"
            >
              {t('auth:resetPassword.submitButton')}
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default ResetPasswordForm;
