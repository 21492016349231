import { HStack, Icon, Switch, Text, useColorMode } from '@chakra-ui/react';
import { Moon24Icon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';

export const ThemeSwitcher = () => {
  const { t } = useTranslation();
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      cursor="pointer"
      py={2}
      px={4}
      userSelect="none"
      transitionDuration="normal"
      onClick={toggleColorMode}
    >
      <HStack
        color="primary.400"
        _hover={{ color: 'primary.500', '& > svg': { color: 'primary.500' } }}
        alignItems="center"
        transitionDuration="normal"
        spacing={2}
      >
        <Icon
          as={Moon24Icon}
          color="primary.300"
          w="20px"
          h="20px"
          transitionDuration="normal"
        />
        <Text as="span" textStyle={TextStyles.Subtitle14Medium} color="inherit">
          {t('darkMode')}
        </Text>
      </HStack>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Switch
          isChecked={colorMode === 'dark'}
          size="small"
          onChange={toggleColorMode}
        />
      </div>
    </HStack>
  );
};
