import { TInvoice } from '@payler/api/merchant-cabinet';
import { useTranslation } from 'react-i18next';
import { useInvoiceExpired } from './use-invoice-expired';
import { Badge } from '@chakra-ui/react';
import { getTransactionStatusBadgeVariant } from '../PaymentsTable/getTransactionStatusBadgeVariant';
import React, { useCallback, useEffect, useState } from 'react';
import { useIsMounted } from '@payler/utils';
import dayjs from 'dayjs';
import { LabeledValue } from '../../components/LabeledValue/LabeledValue';
import { INVOICE_TERMINATE_STATUS } from '../../const';

export const InvoiceState = ({ data }: { data: TInvoice }) => {
  const { t } = useTranslation();
  const expired = useInvoiceExpired(data);

  if (INVOICE_TERMINATE_STATUS.includes(data.status)) {
    return (
      <Badge variant={getTransactionStatusBadgeVariant(data.status)}>
        {data.statusDescription}
      </Badge>
    );
  }
  if (expired) {
    return <Badge variant="red">{t('invoices:expired')}</Badge>;
  }

  return <RemainingTime data={data} />;
};
const RemainingTime = ({ data }: { data: TInvoice }) => {
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const getValue = useCallback(() => {
    const dest = dayjs(data.finished);
    const now = dayjs();
    const days = dest.diff(now, 'd');
    const hours = dest.diff(now, 'h') % 24;
    const minutes = dest.diff(now, 'm') % 60;
    return t('invoices:remainingText', { days, hours, minutes });
  }, [data.finished, t]);

  const [value, setValue] = useState(getValue());

  useEffect(() => {
    const interval = setInterval(() => {
      if (isMounted()) {
        setValue(getValue());
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [getValue, isMounted]);

  return <LabeledValue label={t('invoices:remaining')}>{value}</LabeledValue>;
};
