import React, { useCallback, useEffect, useState } from 'react';
import SetNewPasswordForm from '../forms/SetNewPasswordForm/SetNewPasswordForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthSuccess from '../components/AuthSuccess/AuthSuccess';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

export const SetNewPasswordPage = () => {
  const [newPasswordSuccess, setNewPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const code = searchParams.get('Code') ?? '';
  const userId = searchParams.get('UserId') ?? '';
  useEffect(() => {
    if (!code || !userId) {
      navigate('/auth/login');
    }
  }, [code, userId, navigate]);
  const handleSetNewPasswordSuccess = useCallback(() => {
    setNewPassword(true);
  }, [setNewPassword]);
  const handleSuccessSubmitClick = useCallback(() => {
    navigate('/auth/login');
  }, [navigate]);
  return (
    <>
      <Helmet title={t('titles:recovery')} />
      {newPasswordSuccess ? (
        <AuthSuccess
          onSubmitSuccess={handleSuccessSubmitClick}
          title={t('auth:setNewPasswordSuccess.header')}
          text={t('auth:setNewPasswordSuccess.description')}
          submitText={t('auth:setNewPasswordSuccess.submitButton')}
        />
      ) : (
        <SetNewPasswordForm
          onSubmitSuccess={handleSetNewPasswordSuccess}
          code={code}
          userId={userId}
          mode="reset"
        />
      )}
    </>
  );
};
