import { Icon } from '@chakra-ui/react';
import { Close16Icon } from '@payler/ui-icons';
import React, { useCallback } from 'react';
import { useOpenDeleteReportModal } from '../../modals/DeleteReportModal';
import { useUserPermissions } from '../../hooks/user/users';
import { useShowPermissionsWarning } from '../../hooks/use-show-permissions-warning';

export const DeleteReportButton = ({ id }: { id: number }) => {
  const { canDeleteReports } = useUserPermissions();
  const openModal = useOpenDeleteReportModal();
  const showWarning = useShowPermissionsWarning();
  const handleClick = useCallback(() => {
    if (canDeleteReports) {
      openModal(id);
    } else {
      showWarning();
    }
  }, [canDeleteReports, id, openModal, showWarning]);
  return (
    <Icon
      as={Close16Icon}
      w={2}
      h={2}
      color="primary.300"
      _hover={{ color: 'red.500' }}
      cursor="pointer"
      onClick={handleClick}
    />
  );
};
