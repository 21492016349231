import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { DevPanel } from '@payler/ui-components';

import {
  AccountPage,
  AnalyticsPage,
  AntifraudPaymentsPage,
  ApmPaymentsPage,
  AppState,
  AuthLayout,
  BaseLayout,
  ConfigProvider,
  ContractIdLayout,
  CurrencyTranslation,
  FasterPaymentPage,
  FasterPaymentsPage,
  FasterPayoutPage,
  FasterPayoutsPage,
  GoogleReCaptchaComp,
  GoogleTagManager,
  InvoicesPage,
  LoginPage,
  LogoutPage,
  NotFoundPage,
  NotificationsPage,
  PaymentPage,
  PaymentSettingsPage,
  PaymentsPage,
  PayoutsPage,
  queryClient,
  RegisterConfirmPage,
  ReportsPage,
  ResetPasswordPage,
  SetNewPasswordPage,
  SettingsLayout,
  theme,
  useIsAuthorized,
  useNavigateRef,
  UserCultureWatcher,
  UsersPage,
  WithdrawalsPage,
  ApmPaymentSessionDetail,
  CryptoPaymentsPage,
  CryptoPaymentSessionDetail
} from '@payler/ui/merchant-cabinet';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { environment } from '../environments/environment';
import { GlobalFonts } from '@payler/ui-theme';
import { useEffect, useRef } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';

export const App = () => {
  return (
    <ConfigProvider env={environment}>
      <GoogleTagManager />
      <AppState>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <Helmet title="Merchant Cabinet" />
            <ChakraProvider theme={theme}>
              <GlobalFonts />
              <GoogleReCaptchaComp>
                <BrowserRouter>
                  <AuthWatcher />
                  <AppRoutes />
                </BrowserRouter>
                <DevPanel />
              </GoogleReCaptchaComp>
            </ChakraProvider>
          </HelmetProvider>
        </QueryClientProvider>
      </AppState>
    </ConfigProvider>
  );
};

const AppRoutes = () => {
  const authorized = useIsAuthorized();
  if (!authorized) {
    return (
      <Routes>
        <Route path="auth" element={<AuthLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="resetPassword" element={<ResetPasswordPage />} />
        </Route>
        <Route path="recovery" element={<AuthLayout />}>
          <Route path="" element={<SetNewPasswordPage />} />
        </Route>
        <Route path="confirm" element={<AuthLayout />}>
          <Route path="" element={<RegisterConfirmPage />} />
        </Route>
        <Route path="*" element={<Navigate to="/auth/login" />} />
      </Routes>
    );
  }

  return (
    <>
      <UserCultureWatcher />
      <CurrencyTranslation />
      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<AnalyticsPage />} />
          <Route path="reports" element={<ReportsPage />} />
          <Route path=":contractId" element={<ContractIdLayout />}>
            <Route path="pay" element={<PaymentsPage />} />
            <Route path="pay/:id" element={<PaymentPage mode="payment" />} />

            <Route path="alerts" element={<AntifraudPaymentsPage />} />
            <Route
              path="alerts/:id"
              element={<PaymentPage mode="antifraud" />}
            />

            <Route path="credit" element={<PayoutsPage />} />
            <Route path="credit/:id" element={<PaymentPage mode="payout" />} />

            <Route path="bill" element={<InvoicesPage />} />
            <Route path="bill/:id" element={<PaymentPage mode="bill" />} />

            <Route path="makecredit" element={<WithdrawalsPage />} />
            <Route
              path="makecredit/:id"
              element={<PaymentPage mode="makecredit" />}
            />

            <Route path="faster-payouts" element={<FasterPayoutsPage />} />
            <Route path="faster-payouts/:id" element={<FasterPayoutPage />} />

            <Route path="faster-payments" element={<FasterPaymentsPage />} />
            <Route path="faster-payments/:id" element={<FasterPaymentPage />} />

            <Route path="apm-payments" element={<ApmPaymentsPage />} />
            <Route
              path="apm-payments/:id"
              element={<ApmPaymentSessionDetail />}
            />

            <Route path="crypto-payments" element={<CryptoPaymentsPage />} />
            <Route
              path="crypto-payments/:id"
              element={<CryptoPaymentSessionDetail />}
            />

            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path="settings" element={<SettingsLayout />}>
            <Route path="account" element={<AccountPage />} />
            <Route path="payment" element={<PaymentSettingsPage />} />
            <Route path="users" element={<UsersPage />} />
            <Route path="notifications" element={<NotificationsPage />} />
            <Route index element={<Navigate to="account" replace />} />
          </Route>
          <Route path="logout" element={<LogoutPage />} />
          <Route path="auth/*" element={<Navigate to="/" />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </>
  );
};

const AuthWatcher = () => {
  const authorized = useIsAuthorized();
  const navigate = useNavigateRef();
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      navigate.current(authorized ? '/' : '/auth/login');
    }
  }, [authorized, navigate]);

  return null;
};

export default App;
