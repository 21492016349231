export const filtersEn = {
  apply: 'Apply',
  delete: 'Delete',
  opt1: 'Up to 10 thsd',
  opt2: 'From 10 thsd to 100 thsd',
  opt3: 'From 100 thsd to 1 million',
  opt4: 'From 1 million',
  opt5: 'Any amount',
  reset: 'Reset',
  save: 'Save filters',
  selected: 'selected',
  submit: 'Apply',
  templates: 'Saved Filters',
  title: 'Filters',
};

type TFiltersTranslation = typeof filtersEn;

export const filtersRu: TFiltersTranslation = {
  apply: 'Применить',
  delete: 'Удалить',
  opt1: 'До 10 тыс.',
  opt2: 'От 10 тыс. до 100 тыс.',
  opt3: 'От 100 тыс. - 1 млн',
  opt4: 'От 1 млн',
  opt5: 'Любая сумма',
  reset: 'Сбросить',
  save: 'Сохранить фильтры',
  selected: 'выбрано',
  submit: 'Применить',
  templates: 'Сохранённые фильтры',
  title: 'Фильтры',
};
