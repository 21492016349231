import { Box, chakra, Fade, HStack, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TextStyles } from '@payler/ui-theme';
import { CurrencyDisplay } from '../../CurrencyDisplay/CurrencyDisplay';
import { Suspense } from 'react';
import { ScrollBarWithFade, Skeleton } from '@payler/ui-components';
import { config } from '../config';
import { useChartVisibilityState } from './ChartSumByStatus';
import { motion } from 'framer-motion';
import { useCurrencyCallbacks } from '../../../hooks/currencies-info';
import { useChartBySumData } from '../data/sumByStatusData';
import { useAnalyticsPageContext } from '../../../context/AnalyticsPageContext';

const AnalyticsStatsComp = () => {
  const { t } = useTranslation('transactionStatus');
  const { stats } = useChartBySumData();
  if (!stats) return null;
  return (
    <Fade in={true}>
      <HStack spacing={1} justifyContent="flex-start" minW={0}>
        <StatItem
          label={t('Charged')}
          value={stats.charged}
          color={config['charged']?.color ?? ''}
          status="charged"
        />
        {stats.authorized && (
          <StatItem
            label={t('Authorized')}
            value={stats.authorized}
            color={config['authorized']?.color ?? ''}
            status="authorized"
          />
        )}

        <StatItem
          label={t('Refunded')}
          value={stats.refunded}
          color={config['refunded']?.color ?? ''}
          status="refunded"
        />

        <StatItem
          label={t('RejectedOnlyDebit')}
          value={stats.rejectedOnlyDebit}
          color={config['rejectedOnlyDebit']?.color ?? ''}
          status="rejectedOnlyDebit"
        />
        {stats.credited && (
          <StatItem
            label={t('Credited')}
            value={stats.credited}
            color={config['credited']?.color ?? ''}
            status="credited"
          />
        )}
      </HStack>
    </Fade>
  );
};

const StatItemSkeleton = () => (
  <VStack alignItems="flexStart" minW="170px">
    <Skeleton h={2} w="75px" />
    <Skeleton h={3} w={12} />
  </VStack>
);

const StatsSkeleton = () => (
  <HStack pb={2} pr="100px" spacing={4} justifyContent="center">
    <StatItemSkeleton />
    <StatItemSkeleton />
    <StatItemSkeleton />
    <StatItemSkeleton />
    <StatItemSkeleton />
  </HStack>
);

const StatItem = ({
  status,
  label,
  value,
  color,
}: {
  status: string;
  label: string;
  value: number;
  color: string;
}) => {
  const [visible, setVisible] = useChartVisibilityState();
  const {
    params: { CurrencyCode },
  } = useAnalyticsPageContext();
  const { getCurrencySymbol } = useCurrencyCallbacks();
  const onClick = () => {
    setVisible((v) => {
      const newValue = { ...v, [status]: !v[status as keyof typeof visible] };
      if (Object.values(newValue).filter((x) => x).length === 0) return v;
      return newValue;
    });
  };
  const highlight = visible[status as keyof typeof visible];

  return (
    <VStack
      alignItems="flexStart"
      spacing={0}
      userSelect="none"
      transitionProperty="color"
      minW={0}
      flex="128px 1 0"
      onClick={onClick}
      position="relative"
      cursor="pointer"
      pb={2}
    >
      <motion.div
        css={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: 3,
          background: color,
          borderRadius: 3,
          right: 56,
          transformOrigin: 'center left',
        }}
        animate={{ scaleX: highlight ? 1 : 0 }}
        transition={{ duration: 0.2, ease: 'circOut' }}
        initial={false}
      />

      <HStack spacing={0}>
        <Box w="6px" h="6px" borderRadius="3px" bg={color} mr={0.5}></Box>
        <Text
          textStyle={TextStyles.Caption12Regular}
          color="primary.350"
          transitionProperty="color"
          transitionDuration="fast"
        >
          {label}
        </Text>
      </HStack>
      <Text
        textStyle={{ base: TextStyles.Subtitle14Semibold, sm: TextStyles.h4 }}
        color="primary.500"
        transitionProperty="color"
        transitionDuration="slower"
      >
        <CurrencyDisplay
          value={value}
          currency={CurrencyCode}
          showCurrency={false}
        />
        &nbsp;
        <chakra.span
          color="primary.300"
          transitionProperty="color"
          transitionDuration="slower"
        >
          {getCurrencySymbol(CurrencyCode)}
        </chakra.span>
      </Text>
    </VStack>
  );
};

export const StatsSumByStatus = () => (
  <Suspense fallback={<StatsSkeleton />}>
    <ScrollBarWithFade>
      <AnalyticsStatsComp />
    </ScrollBarWithFade>
  </Suspense>
);
