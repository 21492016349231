import { useMerchantCabinetConfig } from '../../config/ConfigProvider';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const GoogleReCaptchaComp: FCC = ({ children }) => {
  const { googleRecaptchaKey } = useMerchantCabinetConfig();
  return (
    <GoogleReCaptchaProvider reCaptchaKey={googleRecaptchaKey ?? ''}>
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default GoogleReCaptchaComp;
