import { useTranslation } from 'react-i18next';
import { useApmPaymentsMethods } from './apm-payments/payment-methods';
import { useMemo } from 'react';

export const useApmPaymentMethodsOptions = (ids: number[] = []) => {
  const { t } = useTranslation();
  const availablePaymentMethods = useApmPaymentsMethods(ids);
  return useMemo(() => {
    const fieldName = t('fields:paymentMethod');
    const options: { label: string; value: string }[] = [];

    options.push(
      ...availablePaymentMethods.map((method) => ({
        value: method,
        label: method,
      })),
    );

    return { fieldName, options };
  }, [t, availablePaymentMethods]);
};
