import {
  Badge,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import {
  ErrorBoundary,
  NoData,
  ScrollBarWithFade,
} from '@payler/ui-components';
import { TableSkeleton } from '../../components/TableSkeleton/TableSkeleton';
import { useTimeAgo } from '../../hooks/use-time-ago';
import { useDayjsFormat } from '@payler/utils';
import { TableFooter } from '../components/TableFooter';
import { tableStyleConfig } from '../tableStyleConfig';
import { GroupRow } from '../PaymentsTable/GroupRow';
import { AmountWithCurrencyCell } from '../PaymentsTable/AmountWithCurrencyCell';
import {
  useApmPaymentsQuery,
  useApmPaymentsQueryPagerProps,
} from '../../hooks/apm-payments/queries';
import { TApmPayment } from '@payler/api/merchant-cabinet';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ApmPaymentsTable = () => {
  const props = useApmPaymentsQueryPagerProps();
  return (
    <>
      <React.Suspense fallback={null}>
        <TableWithData />
      </React.Suspense>
      <ErrorBoundary>
        <React.Suspense fallback={null}>
          <TableFooter paginationProps={props} />
        </React.Suspense>
      </ErrorBoundary>
    </>
  );
};

const TableWithData = () => {
  const { status, data } = useApmPaymentsQuery();
  switch (status) {
    case 'pending':
      return <TableSkeleton />;
    case 'error':
      return <NoData />;
    case 'success':
    default: {
      const empty = data.items.length === 0;
      if (empty) {
        return <NoData />;
      }
      return (
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Header />
              <Tbody>
                {Object.entries(data.grouped)
                  .sort(([a], [b]) => -a.localeCompare(b))
                  .map(([group, rows]) => (
                    <Rows key={group} group={group} payments={rows} />
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      );
    }
  }
};

const Rows = ({
  group,
  payments,
}: {
  group: string;
  payments: TApmPayment[];
}) => {
  const format = useDayjsFormat();
  const timeAgo = useTimeAgo();
  return (
    <>
      <tr>
        <GroupRow colSpan={11}>
          {format(group, 'dddd DD MMMM')}
          <Text as="span" color="primary.300">
            &nbsp;&nbsp;&mdash;&nbsp;&nbsp;{timeAgo.format(new Date(group))}
          </Text>
        </GroupRow>
      </tr>
      {payments.map((row) => (
        <Row data={row} key={row.sessionId} />
      ))}
    </>
  );
};

const Row = ({ data }: { data: TApmPayment }) => {
  const navigate = useNavigate();
  const { t } = useTranslation('apmPayments');
  const handleRowClick = () => {
    navigate(data.sessionId);
  };

  return (
    <Tr onClick={handleRowClick}>
      <Td>{data.sessionId ?? '-'}</Td>
      <Td>{data.orderId ?? '-'}</Td>
      <Td>{data.clientId ?? '-'}</Td>
      <Td>
        {data.paymentType ? (
          <Badge variant="grey">{data.paymentType}</Badge>
        ) : (
          '-'
        )}
      </Td>
      <Td>
        {data.paymentMethod ? (
          <Badge variant="grey">{data.paymentMethod}</Badge>
        ) : (
          '-'
        )}
      </Td>
      <Td>{data.sessionStatus ?? '-'}</Td>
      <Td textAlign="right">
        <AmountWithCurrencyCell
          amount={data.amount ?? 0}
          currencyCode={data.currency}
          amountOfRefunds={0}
        />
      </Td>
      <Td textAlign="right">
        <AmountWithCurrencyCell
          amount={data.settledAmount ?? data.amount}
          currencyCode={data.currency}
          amountOfRefunds={0}
        />
      </Td>
      <Td>{data.currency}</Td>
      <Td>{dayjs(data.createdAt).format('HH:mm:ss DD.MM.YYYY')}</Td>
      <Td>
        {data.closedAt
          ? dayjs(data.closedAt).format('HH:mm:ss DD.MM.YYYY')
          : '-'}
      </Td>
    </Tr>
  );
};

const Header = () => {
  const { t } = useTranslation('tableHeader');
  return (
    <Thead>
      <Tr>
        <Th>{t('sessionId')}</Th>
        <Th>{t('orderId')}</Th>
        <Th>{t('clientId')}</Th>
        <Th>{t('paymentType')}</Th>
        <Th>{t('paymentMethod')}</Th>
        <Th>{t('status')}</Th>
        <Th>{t('amount')}</Th>
        <Th>{t('paidAmount')}</Th>
        <Th>{t('currency')}</Th>
        <Th>{t('created')}</Th>
        <Th>{t('paid')}</Th>
      </Tr>
    </Thead>
  );
};

export default ApmPaymentsTable;
