import React, { useCallback, useState } from 'react';
import ResetPasswordForm from '../forms/ResetPasswordForm/ResetPasswordForm';
import AuthSuccess from '../components/AuthSuccess/AuthSuccess';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

export const ResetPasswordPage: React.FC = () => {
  const [resetSuccessfull, setResetSuccessfull] = useState(false);
  const { t } = useTranslation();
  const handleToggleReset = useCallback(() => {
    setResetSuccessfull(!resetSuccessfull);
  }, [setResetSuccessfull, resetSuccessfull]);
  return (
    <>
      <Helmet title={t('titles:forgotPassword')} />
      {resetSuccessfull ? (
        <AuthSuccess
          onSubmitSuccess={handleToggleReset}
          title={t('auth:resetPasswordSuccess.header')}
          text={t('auth:resetPasswordSuccess.description')}
          submitText={t('auth:resetPasswordSuccess.submitButton')}
        />
      ) : (
        <ResetPasswordForm onSubmitSuccess={handleToggleReset} />
      )}
    </>
  );
};

export default ResetPasswordPage;
