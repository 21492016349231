import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  LoadingBox,
  NoData,
} from '@payler/ui-components';
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import React, { Suspense } from 'react';
import { TooltipSumByStatus } from './TooltipSumByStatus';
import { StatsSumByStatus } from './StatsSumByStatus';
import { config } from '../config';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { createRecoilKey } from '../../../helpers/createKey';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { StepDropdown } from '../filters/Step';
import dayjs from 'dayjs';
import { useDayjsFormat } from '@payler/utils';
import {
  ChartStepContextProvider,
  useChartStepContext,
} from '../../../context/ChartStepContext';
import { useChartBySumData } from '../data/sumByStatusData';
import { TickFormatterFunc } from '../types';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';

const defaultVisibility = {
  charged: true,
  rejectedOnlyDebit: false,
  refunded: true,
  credited: false,
  authorized: false,
};

export const visibilityAtom = atom({
  key: createRecoilKey('visibilityAtom'),
  default: defaultVisibility,
});

export const useChartVisibilityState = () => useRecoilState(visibilityAtom);

const ChartComp = ({ data }: { data?: CategoricalChartProps['data'] }) => {
  const visible = useRecoilValue(visibilityAtom);
  const { step } = useChartStepContext();
  const { t } = useTranslation('analytics');
  const formatDate = useDayjsFormat();
  let dateFormatter: TickFormatterFunc = (v) => formatDate(v, 'DD MMMM') ?? v;
  if (step === 'weeks') {
    dateFormatter = (v) => t('weekNum', { week: dayjs(v).week() });
  }
  if (step === 'months') {
    dateFormatter = (v) => formatDate(v, 'MMM') ?? v;
  }
  return (
    <Box mx={{ base: -2, sm: 0 }}>
      <ResponsiveContainer width="100%" height={320}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            {Object.entries(config).map(([status, { color }]) => (
              <linearGradient
                key={status}
                id={status}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="30%" stopColor={color} stopOpacity={0.3} />
                <stop offset="100%" stopColor={color} stopOpacity={0} />
              </linearGradient>
            ))}
          </defs>
          <XAxis
            dataKey="date"
            interval="preserveStart"
            tickFormatter={dateFormatter}
            tickLine={false}
            height={32}
            tickMargin={16}
          />
          <Tooltip content={TooltipSumByStatus} animationDuration={250} />

          {Object.entries(config)
            .filter(([key]) => visible[key as keyof typeof visible])
            .map(([key, settings]) => {
              const show = visible[key as keyof typeof visible];
              const stroke = show ? settings.color : 'transparent';
              const fill = show ? `url(#${key})` : 'transparent';
              return (
                <Area
                  animationDuration={750}
                  key={key}
                  type="monotone"
                  dataKey={key}
                  stroke={stroke}
                  strokeWidth={2}
                  fillOpacity={1}
                  fill={fill}
                  name={t(`transactionStatus:${settings.status}`)}
                />
              );
            })}
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

const SKELETON_HEIGHT = '378px';

const Content = () => {
  const { data, isLoading } = useChartBySumData();
  if (isLoading) return <LoadingBox h={SKELETON_HEIGHT} />;
  if (!data || data.length === 0) return <NoData h={SKELETON_HEIGHT} />;

  return (
    <>
      <StatsSumByStatus />
      <ChartComp data={data} />
    </>
  );
};

const ChartCard = () => {
  const { t } = useTranslation('analytics');
  const stepVisible = useBreakpointValue({ base: false, md: true });
  return (
    <Card>
      <CardHead alignSelf="stretch">
        <CardTitle>{t('sumByStatusTitle')}</CardTitle>
        {stepVisible && <StepDropdown />}
      </CardHead>
      <CardContent
        sx={{
          '.recharts-cartesian-axis-tick': {
            textStyle: TextStyles.Caption12Medium,
            color: 'primary.350',
          },
        }}
      >
        <Content />
      </CardContent>
    </Card>
  );
};

export const ChartSumByStatus = () => (
  <ChartStepContextProvider>
    <Suspense fallback={null}>
      <ChartCard />
    </Suspense>
  </ChartStepContextProvider>
);
export default ChartSumByStatus;
