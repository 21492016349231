import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  DatesFields,
} from '@payler/ui-components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, HStack, Icon, Text } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { CloseCircle14Icon } from '@payler/ui-icons';
import { getRangeKey } from '../../helpers/ranges';
import dayjs from 'dayjs';
import { getDayjsOrNull } from '@payler/utils';

type Props = {
  /** название поля с датой начала */
  formFieldFrom: string;
  /** название поля с датой окончания */
  formFieldTo: string;
  /** ключ локализации заголовка начальной даты */
  fromKey: string;
  /** ключ локализации заголовка */
  titleKey: string;
  /** ключ локализации заголовка даты окончания */
  toKey: string;
  /** Формат даты */
  dateFormat: string;
};

/**
 * Аккордеон со списком опций для отображения в фильтрах
 */
export const FiltersDateRangeAccordion = (props: Props) => {
  return (
    <Accordion alignSelf="stretch">
      <Header {...props} />
      <AccordionBody p={2}>
        <DatesFields
          fieldNames={[props.formFieldFrom, props.formFieldTo]}
          stackProps={{ direction: 'column' }}
          labelsKeys={[props.fromKey, props.toKey]}
          dateFormat={props.dateFormat}
          maxDate={dayjs()}
          floating
        />
      </AccordionBody>
    </Accordion>
  );
};

type HeaderProps = Pick<
  Props,
  'formFieldFrom' | 'formFieldTo' | 'titleKey' | 'dateFormat'
>;
const Header = ({
  formFieldFrom,
  formFieldTo,
  titleKey,
  dateFormat,
}: HeaderProps) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();
  const from = watch(formFieldFrom) as string | null;
  const to = watch(formFieldTo) as string | null;
  const fromDate = getDayjsOrNull(from, dateFormat);
  const toDate = getDayjsOrNull(to, dateFormat);
  const showClearButton = fromDate || toDate;
  const tKey = getRangeKey(from, to);
  const text = t(tKey, { start: from, end: to });

  return (
    <AccordionHeader>
      <HStack alignItems="center" spacing={2}>
        <Box flex={1}>
          <Text textStyle={TextStyles.tables} color="primary.500">
            {t(titleKey)}
          </Text>
          <Text textStyle={TextStyles.Caption12Regular} color="primary.300">
            {text}
          </Text>
        </Box>
        {showClearButton && (
          <Icon
            as={CloseCircle14Icon}
            onClick={(e) => {
              e.stopPropagation();
              setValue(formFieldFrom, '');
              setValue(formFieldTo, '');
            }}
          />
        )}
      </HStack>
    </AccordionHeader>
  );
};
