import Menu from './Menu';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { createRecoilKey } from '../../helpers/createKey';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Hamburger } from '@payler/ui-components';
import { emotionColors } from '@payler/ui-theme';
import { css } from '@emotion/react';
import { MOBILE_HEADER_HEIGHT } from '../../layouts/BaseLayout/const';

export const mobileMenuOpenedAtom = atom({
  key: createRecoilKey('mobileMenuOpenedAtom'),
  default: false,
  effects: [
    ({ onSet }) => {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      onSet((v) => {
        document.body.style.overflow = v ? 'hidden' : originalStyle;
      });
    },
  ],
});

const openStyle = css({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1000,
});

const closedStyle = css({
  position: 'relative',
  height: MOBILE_HEADER_HEIGHT,
  overflow: 'hidden',
  borderBottom: '0.5px solid',
  borderBottomColor: emotionColors.primary100,
});

export const MobileMenu: React.FC = () => {
  const location = useLocation();
  const hideMenu = useRecoilCallback(
    ({ set }) =>
      () => {
        set(mobileMenuOpenedAtom, false);
      },
    [],
  );
  const toggle = useRecoilCallback(
    ({ set }) =>
      () =>
        set(mobileMenuOpenedAtom, (v) => !v),
    [],
  );
  const menuVisible = useRecoilValue(mobileMenuOpenedAtom);

  useEffect(() => {
    hideMenu();
  }, [hideMenu, location]);

  const style = menuVisible ? openStyle : closedStyle;
  return (
    <nav css={style}>
      <Hamburger
        onClick={toggle}
        isOpen={menuVisible}
        position="absolute"
        top={2}
        right={2}
        w={3}
        h={3}
      />
      <Menu />
    </nav>
  );
};
export default MobileMenu;
