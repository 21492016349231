import { Box, HStack, Icon } from '@chakra-ui/react';
import { CheckedIcon } from '@payler/ui-icons';
import React from 'react';
import { OptionSpan } from '../../OptionSpan/OptionSpan';

type Props = {
  selected: boolean;
  text: string;
  onClick: VoidFunction;
};

export const CustomCheckbox = ({ selected, text, onClick }: Props) => {
  const color = selected ? 'primary.500' : 'primary.350';
  return (
    <HStack
      px={2}
      spacing={2}
      userSelect="none"
      cursor="pointer"
      h={7}
      onClick={onClick}
      justifyContent="space-between"
      color={color}
      transitionDuration="fast"
      transitionProperty="color, background"
      _hover={{
        color: 'primary.500',
        bg: 'primary.25',
      }}
    >
      <OptionSpan color="inherit">{text}</OptionSpan>
      <Box w="20px">
        {selected && <Icon as={CheckedIcon} w="20px" h="20px" />}
      </Box>
    </HStack>
  );
};
