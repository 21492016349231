import { useLayoutEffect } from 'react';
import { useLogout } from '../hooks/user/auth';

export const LogoutPage = () => {
  const logout = useLogout();
  useLayoutEffect(() => {
    logout();
  }, [logout]);
  return <div>Bye!</div>;
};
