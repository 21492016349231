import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

export type TStep = 'days' | 'weeks' | 'months';
type TChartStepsContext = {
  step: TStep;
  setStep: Dispatch<SetStateAction<TStep>>;
};

const ChartStepContext = createContext<TChartStepsContext>({
  step: 'days',
  setStep: (step) => console.log('ChartStepContext setStep', step),
});

/**
 * Для переключалок дни/месяцы/недели в графиках
 * @param children
 * @constructor
 */
export const ChartStepContextProvider: FCC = ({ children }) => {
  const [step, setStep] = useState<TStep>('days');
  return (
    <ChartStepContext.Provider value={{ step, setStep }}>
      {children}
    </ChartStepContext.Provider>
  );
};

export const useChartStepContext = () => useContext(ChartStepContext);
