import { TInvoice } from '@payler/api/merchant-cabinet';
import { HStack, Icon, Tooltip, useClipboard } from '@chakra-ui/react';
import React, { MouseEventHandler, Suspense, useState } from 'react';
import { useIsMounted } from '@payler/utils';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';
import { useTranslation } from 'react-i18next';
import { CopyIcon, ExchangeIcon, Mail24Icon } from '@payler/ui-icons';
import { useInvoiceExpired } from './use-invoice-expired';
import { useUserPermissions } from '../../hooks/user/users';
import { useShowPermissionsWarning } from '../../hooks/use-show-permissions-warning';
import { useShowRecreateInvoiceModal } from '../../modals/RecreateInvoiceModal';
import { useToasts } from '../../hooks/use-toasts';
import { INVOICE_TERMINATE_STATUS } from '../../const';
import { useApi } from '../../context/ApiContextProvider';

const CopyLinkButton = ({ data }: { data: TInvoice }) => {
  const perms = useUserPermissions();
  const warn = useShowPermissionsWarning();
  const expired = useInvoiceExpired(data);
  const { t } = useTranslation();
  const { hasCopied, onCopy } = useClipboard(data.payLink ?? '');
  if (!data.payLink) return null;
  if (expired) return null;
  if (INVOICE_TERMINATE_STATUS.includes(data.status)) return null;

  const label = hasCopied ? t('copied') : t('clickToCopy');
  const handleClick = () => {
    if (!perms.invoices.copyLink) {
      return warn();
    }
    onCopy();
  };
  return (
    <Tooltip
      label={label}
      isOpen={hasCopied || undefined}
      shouldWrapChildren
      closeOnMouseDown
    >
      <Icon
        onClick={handleClick}
        cursor="pointer"
        w={3}
        h={3}
        as={CopyIcon}
        color="primary.300"
        _hover={{ color: 'brands.500' }}
      />
    </Tooltip>
  );
};

const SendInvoiceAgainButton = ({ data }: { data: TInvoice }) => {
  const { invoices } = useUserPermissions();
  const showModal = useShowRecreateInvoiceModal();
  const canRecreateInvoice = INVOICE_TERMINATE_STATUS.includes(data.status);
  const expired = useInvoiceExpired(data);
  const visible = expired || canRecreateInvoice;
  const perms = useUserPermissions();
  const warn = useShowPermissionsWarning();
  const { t } = useTranslation();
  if (!invoices.resend) return null;

  const handleSend: MouseEventHandler<HTMLOrSVGElement> = (e) => {
    e.stopPropagation();
    if (!perms.invoices.resend) {
      return warn();
    }
    showModal(data);
  };
  if (!visible) return null;
  return (
    <Tooltip label={t('invoices:sendInvoiceAgain')} shouldWrapChildren>
      <Icon
        onFocus={(e) => e.preventDefault()}
        cursor="pointer"
        w={3}
        h={3}
        as={ExchangeIcon}
        color="primary.300"
        _hover={{ color: 'brands.500' }}
        onClick={handleSend}
      />
    </Tooltip>
  );
};

const SendNotificationButton = ({ data }: { data: TInvoice }) => {
  const perms = useUserPermissions();
  const warn = useShowPermissionsWarning();
  const expired = useInvoiceExpired(data);
  const isMounted = useIsMounted();
  const [sending, setSending] = useState(false);
  const getError = useGetAxiosError();
  const toasts = useToasts();
  const api = useApi();
  const { t } = useTranslation();
  const handleSend = async () => {
    if (!perms.invoices.notify) {
      return warn();
    }
    setSending(false);
    try {
      const message = await api.resendInvoice(data.id);
      toasts.success(message);
    } catch (e) {
      toasts.error(getError(e));
    } finally {
      if (isMounted()) {
        setSending(false);
      }
    }
  };
  if (expired) {
    return null;
  }
  if (INVOICE_TERMINATE_STATUS.includes(data.status)) {
    return null;
  }
  return (
    <Tooltip
      label={t('invoices:sendNotification')}
      shouldWrapChildren
      closeOnClick
    >
      <Icon
        cursor={sending ? 'not-allowed' : 'pointer'}
        w={3}
        h={3}
        as={Mail24Icon}
        color={sending ? 'primary.100' : 'primary.300'}
        _hover={{ color: sending ? 'brands.100' : 'brands.500' }}
        onClick={handleSend}
      />
    </Tooltip>
  );
};

export const InvoiceButtons = ({ data }: { data: TInvoice }) => (
  <HStack spacing={2} justifyContent="flex-end">
    <Suspense fallback={null}>
      <SendInvoiceAgainButton data={data} />
    </Suspense>
    <SendNotificationButton data={data} />
    <CopyLinkButton data={data} />
  </HStack>
);
