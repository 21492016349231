import { useTranslation } from 'react-i18next';
import { HStack, Icon, Tooltip } from '@chakra-ui/react';
import {
  PaymentApplePay24Icon,
  PaymentGooglePay24Icon,
  PaymentLinkPayment24Icon,
  PaymentRecurrent24Icon,
} from '@payler/ui-icons';
import React from 'react';
import { TPayout } from '@payler/api/merchant-cabinet';
import { ReactComponent as YandexPayIcon } from './yandex-pay.table.svg';

export const Images = ({ payment }: { payment: TPayout }) => {
  const { t } = useTranslation();
  return (
    //TODO: добавить СБП (ждём бэк)
    <HStack
      spacing={0}
      color="brands.500"
      sx={{
        '& svg .hide': { fill: 'none', stroke: 'none' },
        '*': { lineHeight: '15px' },
      }}
    >
      {payment.isInvoicePay && (
        <Tooltip label={t('payments:linkPayment')} shouldWrapChildren>
          <Icon as={PaymentLinkPayment24Icon} w={3} h={3} color="brands.500" />
        </Tooltip>
      )}
      {payment.isRecurrentPay && (
        <Tooltip label={t('payments:recurringPayment')} shouldWrapChildren>
          <Icon as={PaymentRecurrent24Icon} w={3} h={3} color="brands.500" />
        </Tooltip>
      )}
      {payment.paymentMethodName === 'ApplePay' && (
        <Tooltip label="Apple Pay" shouldWrapChildren>
          <Icon as={PaymentApplePay24Icon} w={3} h={3} color="brands.500" />
        </Tooltip>
      )}
      {payment.paymentMethodName === 'GooglePay' && (
        <Tooltip label="Google Pay" shouldWrapChildren>
          <Icon as={PaymentGooglePay24Icon} w={3} h={3} color="brands.500" />
        </Tooltip>
      )}
      {payment.paymentMethodName === 'YandexPay' && (
        <Tooltip label="Google Pay" shouldWrapChildren>
          <Icon as={YandexPayIcon} w={3} h={3} color="brands.500" />
        </Tooltip>
      )}
    </HStack>
  );
};
