import { emotionColors, TextStyles } from '@payler/ui-theme';

const sidePadding = 3;
export const tableStyleConfig = {
  baseStyle: {
    table: {
      minW: '600px',
      width: '100%',
    },
    tr: {
      height: 9,
      borderBottom: `0.5px solid ${emotionColors.primary100}`,
      cursor: 'pointer',
    },
    td: {
      textStyle: TextStyles.tables,
      px: 1,
      '&:first-of-type': {
        pl: sidePadding,
      },
      '&:last-of-type': {
        pr: sidePadding,
      },
    },
    th: {
      textStyle: TextStyles.Caption12Medium,
      color: 'primary.400',
      textAlign: 'left',
      px: 1,
      py: 2,
      '&:first-of-type': {
        pl: sidePadding,
      },
      '&:last-of-type': {
        pr: sidePadding,
      },
    },
  },
};
