import {
  PMDiners16Icon,
  PMJCB16Icon,
  PMMasterCard16Icon,
  PMMir16Icon,
  PMVisa16Icon,
} from '@payler/ui-icons';
import React from 'react';
import { MayBe } from '@payler/api/merchant-cabinet';

/**
 * VISA
 * MASTERCARD
 * DINERS CLUB INTERNATIONAL
 * NSPK MIR
 * MIR
 * MAESTRO
 * JCB
 *
 * @param type
 * @constructor
 */
export const CardTypeIcon = ({ type }: { type: MayBe<string> }) => {
  switch (type) {
    case 'VISA': {
      return <PMVisa16Icon />;
    }
    case 'MASTERCARD': {
      return <PMMasterCard16Icon />;
    }
    case 'DINERS CLUB INTERNATIONAL':
      return <PMDiners16Icon />;
    case 'MAESTRO':
      return <PMMasterCard16Icon />;
    case 'JCB':
      return <PMJCB16Icon />;

    case 'NSPK MIR':
    case 'MIR': {
      return <PMMir16Icon />;
    }
    default:
      return null;
  }
};
