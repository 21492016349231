import {
  Button,
  chakra,
  HStack,
  Icon,
  Menu,
  MenuButtonProps,
  MenuList,
  useMenuButton,
  useMenuState,
  VStack,
} from '@chakra-ui/react';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { TextStyles } from '@payler/ui-theme';
import { ChevronDownIcon } from '@payler/ui-icons';
import { useTranslation } from 'react-i18next';
import {
  PaylerDatepicker,
  PaylerDatepickerProps,
  ThinDivider,
} from '@payler/ui-components';

import { CustomCheckbox } from './CustomCheckbox';
import dayjs, { Dayjs } from 'dayjs';
import { useAnalyticsPageContext } from '../../../context/AnalyticsPageContext';

/** от ТП с боя */
const MIN_DATE = '2017-01-01';

const comparePeriod = (a?: TValue, b?: TValue) => {
  if (!a || !b) return false;
  return (
    (a.start?.isSame(b.start, 'date') && a.end?.isSame(b.end, 'date')) ?? false
  );
};

const getWeekPeriod = (end = dayjs()) => {
  return {
    start: end.add(-6, 'days'),
    end,
  };
};

const getMonthPeriod = (end = dayjs()) => {
  return {
    start: end.add(-1, 'month').add(1, 'day'),
    end,
  };
};

const get3MonthsPeriod = (end = dayjs()) => {
  return {
    start: end.add(-3, 'month').add(1, 'day'),
    end,
  };
};

const Trigger = forwardRef<HTMLDivElement, MenuButtonProps>((props, ref) => {
  const {
    params: { StartDate, EndDate },
  } = useAnalyticsPageContext();
  const { isOpen } = useMenuState();
  const buttonProps = useMenuButton(props, ref);
  const { t } = useTranslation('analytics');

  const title = useMemo<string>(() => {
    const start = StartDate ? dayjs(StartDate) : undefined;
    const end = EndDate ? dayjs(EndDate) : undefined;
    const period = { start, end };

    const weekPeriod = getWeekPeriod();
    const month = getMonthPeriod();
    const month3 = get3MonthsPeriod();
    if (comparePeriod(period, weekPeriod)) {
      return t('periodWeek');
    }
    if (comparePeriod(period, month)) {
      return t('periodMonth');
    }
    if (comparePeriod(period, month3)) {
      return t('period3Month');
    }

    if (!start || !end) {
      return t('periodNotSet');
    }

    const f = start?.format('YYYY.MM.DD\u00a0-\u00a0');
    const to = end?.format('YYYY.MM.DD');
    const text = `${f}${to}`;

    if (!text) return t('periodNotSet');
    return text;
  }, [StartDate, EndDate, t]);

  return (
    <HStack
      {...buttonProps}
      bg="primary.25"
      borderRadius={1.5}
      border="1px solid"
      borderColor="primary.100"
      px={2}
      py={1}
      h="56px"
      minW="240px"
      userSelect="none"
      justifyContent="space-between"
    >
      <VStack alignItems="stretch" spacing={0} minW={0}>
        <Label>{t('period')}</Label>
        <Value>{title}</Value>
      </VStack>
      <Icon
        as={ChevronDownIcon}
        color="primary.350"
        w="20px"
        h="20px"
        transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
        transitionDuration="normal"
      />
    </HStack>
  );
});

const Label = chakra('div', {
  baseStyle: {
    textStyle: TextStyles.Caption12Regular,
    color: 'primary.350',
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
});
const Value = chakra('div', {
  baseStyle: {
    textStyle: TextStyles.BodyUI16Regular,
    color: 'primary.500',
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const PeriodDropdown = () => {
  return (
    <Menu isLazy preventOverflow>
      <Trigger w="150px" />
      <MenuList
        py={0}
        pb={2}
        bg="secondary.500"
        borderRadius={1.5}
        border="1px solid"
        borderColor="primary.100"
      >
        <Body />
      </MenuList>
    </Menu>
  );
};

type TValue = { start?: Dayjs | undefined; end?: Dayjs | undefined };
type TOnChange = Required<
  Extract<PaylerDatepickerProps, { mode: 'range' }>
>['onChange'];

const Body = () => {
  const { onClose } = useMenuState();

  const { t } = useTranslation('analytics');
  const { params, setParams } = useAnalyticsPageContext();
  const { StartDate, EndDate } = params;
  const [activeMonth, setActiveMonth] = useState<Dayjs | undefined>(
    dayjs(EndDate),
  );

  const start = StartDate ? dayjs(StartDate) : undefined;
  const end = StartDate ? dayjs(EndDate) : undefined;
  const [pickerValue, setPickerValue] = useState<{
    start?: Dayjs;
    end?: Dayjs;
  }>({ start, end });

  const handlePickerChange = useCallback<TOnChange>((value) => {
    setPickerValue(value);
    setActiveMonth(value.start);
  }, []);

  const lastWeekPeriod = getWeekPeriod();
  const lastMonthPeriod = getMonthPeriod();
  const threeMonthsPeriod = get3MonthsPeriod();

  const submit = () => {
    setParams((v) => ({
      ...v,
      StartDate: pickerValue.start?.format('YYYY-MM-DD'),
      EndDate: pickerValue.end?.format('YYYY-MM-DD'),
    }));
    onClose();
  };

  const submitDisabled = !pickerValue.start || !pickerValue.end;

  return (
    <>
      <VStack spacing={0} alignItems="stretch" divider={<ThinDivider />}>
        <CustomCheckbox
          selected={comparePeriod(pickerValue, lastWeekPeriod)}
          text={t(`periodWeek`)}
          onClick={() => {
            setPickerValue(lastWeekPeriod);
            setActiveMonth(lastWeekPeriod.end);
          }}
        />
        <CustomCheckbox
          selected={comparePeriod(pickerValue, lastMonthPeriod)}
          text={t(`periodMonth`)}
          onClick={() => {
            setActiveMonth(lastMonthPeriod.end);
            setPickerValue(lastMonthPeriod);
          }}
        />
        <CustomCheckbox
          selected={comparePeriod(pickerValue, threeMonthsPeriod)}
          text={t(`period3Month`)}
          onClick={() => {
            setActiveMonth(threeMonthsPeriod.end);
            setPickerValue(threeMonthsPeriod);
          }}
        />

        <VStack p={2} spacing={2}>
          <PaylerDatepicker
            mode="range"
            activeMonth={activeMonth}
            showBorder={false}
            value={pickerValue}
            maxDate={dayjs()}
            minDate={dayjs(MIN_DATE)}
            onChange={handlePickerChange}
          />
        </VStack>
      </VStack>
      <ThinDivider mb={2} />

      <HStack justifyContent="stretch" px={2} spacing={2}>
        <Button variant="secondary" onClick={onClose} flex={1} size="small">
          {t('common:cancel')}
        </Button>
        <Button
          onClick={submit}
          isDisabled={submitDisabled}
          flex={1}
          size="small"
        >
          {t('common:apply')}
        </Button>
      </HStack>
    </>
  );
};
