import { chakra, Td } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export const GroupRow = chakra(Td, {
  baseStyle: {
    bg: 'primary.25',
    textStyle: TextStyles.Caption12Medium,
    px: 3,
    py: 1,
    color: 'primary.350',
  },
});
