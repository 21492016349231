import { TPaymentsAnalyticsQueryParams } from '@payler/api/merchant-cabinet';
import { useAnalyticsPageContext } from '../../context/AnalyticsPageContext';
import { useChartStepContext } from '../../context/ChartStepContext';
import { useMemo } from 'react';
import dayjs from 'dayjs';

/**
 * Расширение диапазона дат в фильтрах
 * Зависит от ChartStepContext
 */
export const useChartQueryParamsByStep = (): TPaymentsAnalyticsQueryParams => {
  const { params: pageParams } = useAnalyticsPageContext();
  const { step } = useChartStepContext();
  return useMemo(() => {
    switch (step) {
      case 'weeks': {
        return {
          ...pageParams,
          StartDate: dayjs(pageParams.StartDate)
            .startOf('week')
            .format('YYYY-MM-DD'),
          EndDate: dayjs(pageParams.EndDate).endOf('week').format('YYYY-MM-DD'),
        };
      }
      case 'months': {
        return {
          ...pageParams,
          StartDate: dayjs(pageParams.StartDate)
            .startOf('month')
            .format('YYYY-MM-DD'),
          EndDate: dayjs(pageParams.EndDate)
            .endOf('month')
            .format('YYYY-MM-DD'),
        };
      }
      case 'days':
      default:
        return pageParams;
    }
  }, [pageParams, step]);
};
