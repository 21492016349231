import { TInvoice } from '@payler/api/merchant-cabinet';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export const useInvoiceExpired = (invoice: TInvoice) => {
  const [expired, setExpired] = useState(dayjs().isAfter(invoice.finished));
  useEffect(() => {
    const interval = setInterval(() => {
      setExpired(dayjs().isAfter(invoice.finished));
    }, 1000);
    return () => clearInterval(interval);
  }, [invoice]);
  return expired;
};
