import { useCurrencyOptions } from '../../hooks/use-currency-options';
import React, { Suspense, useMemo } from 'react';
import { TCurrency } from '@payler/api/merchant-cabinet';
import { useURLContractCurrencies } from '../../hooks/use-url-contract-currencies';
import { DropdownField } from '@payler/ui-components';

type Props = { name: string };

const Comp = ({ name }: Props) => {
  const { options: allOptions, fieldName } = useCurrencyOptions();
  const currencies = useURLContractCurrencies();
  const options = useMemo(
    () => allOptions.filter((x) => currencies.includes(x.value as TCurrency)),
    [allOptions, currencies],
  );
  return (
    <DropdownField
      options={options}
      label={fieldName}
      floating
      fieldName={name}
      hideClearButton
    />
  );
};

export const SelectCurrency = (props: Props) => (
  <Suspense fallback={null}>
    <Comp {...props} />
  </Suspense>
);
