import { useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { PaylerModal } from '@payler/ui-components';
import PayoutsFiltersForm from '../forms/PaymentsFilters/PayoutsFiltersForm';

const payoutsFiltersModalOpenedAtom = atom({
  key: createRecoilKey('payoutsFiltersModalOpenedAtom'),
  default: false,
});

export const useShowPayoutsFiltersModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(payoutsFiltersModalOpenedAtom, true);
      },
    [],
  );

export const useClosePayoutsFiltersModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(payoutsFiltersModalOpenedAtom, false);
      },
    [],
  );

export const PayoutsFiltersModal = () => {
  const visible = useBreakpointValue({ base: true, lg: false });
  const open = useRecoilValue(payoutsFiltersModalOpenedAtom);
  const close = useClosePayoutsFiltersModal();
  if (!visible) return null;
  return (
    <PaylerModal close={close} open={open}>
      <PayoutsFiltersForm />
    </PaylerModal>
  );
};
