import React from 'react';
import {
  FormOption,
  FormOptionProps,
} from '../components/FormOption/FormOption';
import { Input, InputProps } from '../components/Input/Input';
import { useFormContext } from 'react-hook-form';

export interface InputFieldProps extends InputProps {
  name: string;
  label?: FormOptionProps['label'];
}

export const InputField: React.FC<InputFieldProps> = ({
  name,
  label,
  className,
  ...rest
}) => {
  const {
    register,
    formState: { errors, touchedFields },
  } = useFormContext();
  const errorMessage = errors[name]?.message;
  const isError = !!errorMessage && !!touchedFields;
  return (
    <FormOption
      isInvalid={isError}
      label={label}
      errorMessage={errorMessage as string | undefined}
      className={className}
    >
      <Input {...register(name)} {...rest} />
    </FormOption>
  );
};
InputField.displayName = 'InputField';
