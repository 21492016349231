import { chakra } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';

export const OptionSpan = chakra('span', {
  baseStyle: {
    textStyle: TextStyles.BodyUI16Regular,
    fontSize: '15px',
    color: 'primary.500',
  },
});
