import { useTranslation } from 'react-i18next';
import { Fade, Icon, StackProps, Text, VStack } from '@chakra-ui/react';
import { TimerIcon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';
import React from 'react';

type Props = {
  text?: string;
} & Omit<StackProps, 'children'>;

export const LoadingBox = ({ text, ...rest }: Props) => {
  const { t } = useTranslation();
  return (
    <Fade in={true}>
      <VStack
        spacing={1}
        as="span"
        width="100%"
        alignItems="center"
        h="200px"
        justifyContent="center"
        position="relative"
        {...rest}
      >
        <Icon width={5} height={5} as={TimerIcon} color="primary.200" />
        <Text as="span" textStyle={TextStyles.h3} color="primary.400">
          {text ?? t('loading', { defaultValue: 'Loading...' })}
        </Text>
      </VStack>
    </Fade>
  );
};
