import { useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import { PaylerModal } from '@payler/ui-components';
import { ApmPaymentsFiltersForm } from '../forms/ApmPaymentsFilters/ApmPaymentsFiltersForm';

const apmPaymentsFiltersModalOpenedAtom = atom({
  key: createRecoilKey('apmPaymentsFiltersModalOpenedAtom'),
  default: false,
});

export const useShowApmPaymentsFiltersModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(apmPaymentsFiltersModalOpenedAtom, true);
      },
    [],
  );

export const useCloseApmPaymentsFiltersModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(apmPaymentsFiltersModalOpenedAtom, false);
      },
    [],
  );

export const ApmPaymentsFiltersModal = () => {
  const visible = useBreakpointValue({ base: true, lg: false });
  const open = useRecoilValue(apmPaymentsFiltersModalOpenedAtom);
  const close = useCloseApmPaymentsFiltersModal();
  if (!visible) return null;
  return (
    <PaylerModal close={close} open={open}>
      <ApmPaymentsFiltersForm />
    </PaylerModal>
  );
};
