import { Button, HStack, VStack } from '@chakra-ui/react';
import { t } from 'i18next';
import React, { useCallback } from 'react';
import {
  atom,
  useRecoilCallback,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { createRecoilKey } from '../helpers/createKey';
import { useDeleteUserMutation } from '../hooks/user/users';
import { useToasts } from '../hooks/use-toasts';
import { NotificationWithIcon, PaylerModal } from '@payler/ui-components';
import { DangerBigIcon } from '@payler/ui-icons';
import { useGetAxiosError } from '../hooks/use-get-axios-error';

const deleteUserModalIdAtom = atom<string | null>({
  key: createRecoilKey('deleteUserModalIdAtom'),
  default: null,
});

const useCloseDeleteUserModal = () =>
  useRecoilCallback(({ set }) => () => {
    set(deleteUserModalIdAtom, null);
  });

export const useShowDeleteUserModal = () =>
  useSetRecoilState(deleteUserModalIdAtom);

export const DeleteUserModal = () => {
  const id = useRecoilValue(deleteUserModalIdAtom);
  const isOpen = !!id;
  const close = useCloseDeleteUserModal();

  return (
    <PaylerModal close={close} open={isOpen}>
      <VStack spacing={4} w="100%">
        <NotificationWithIcon
          title={t('users:deleteTitle') as string}
          text={t('users:deleteDescription') as string}
          icon={DangerBigIcon}
        />
      </VStack>
      <Buttons />
    </PaylerModal>
  );
};

const Buttons = () => {
  const id = useRecoilValue(deleteUserModalIdAtom);
  const getError = useGetAxiosError();
  const toasts = useToasts();
  const close = useCloseDeleteUserModal();
  const { mutateAsync } = useDeleteUserMutation();
  const handleDelete = useCallback(async () => {
    if (!id) return;
    close();
    await mutateAsync(id, {
      onSuccess: (message) => {
        toasts.success(message);
      },
      onError: (e) => toasts.error(getError(e)),
    });
  }, [id, close, mutateAsync, toasts, getError]);
  return (
    <HStack spacing={2}>
      <Button flex={1} variant="secondary" onClick={close}>
        {t('users:cancel') as string}
      </Button>
      <Button flex={1} variant="red" onClick={handleDelete}>
        {t('users:delete') as string}
      </Button>
    </HStack>
  );
};
