import { Box, chakra, HStack, Icon } from '@chakra-ui/react';
import { ReactComponent as DotIcon } from './dot.svg';

export const Timeline = chakra('div', {
  baseStyle: {
    px: 3,
    py: 2,
  },
});

type TTimelineItemProps = {
  last?: boolean;
  error?: boolean;
};

export const TimelineItem: FCC<TTimelineItemProps> = ({
  last,
  error,
  children,
}) => {
  const color = error ? 'red.500' : 'brands.500';
  const lineColor = error ? 'red.300' : 'brands.300';
  return (
    <HStack alignItems="stretch">
      <Box pl={3} w={3} position="relative">
        <Icon
          as={DotIcon}
          color={color}
          w="6px"
          h="6px"
          position="absolute"
          top="6px"
          left="5px"
        />
        {!last && (
          <Box
            w={0}
            borderRight="1px dashed"
            borderRightColor={lineColor}
            position="absolute"
            bottom="0px"
            top="18px"
            left="7.5px"
          />
        )}
      </Box>
      <Box flex={1} pb={3}>
        {children}
      </Box>
    </HStack>
  );
};

export default TimelineItem;
