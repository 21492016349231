import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Button, Tooltip, VStack } from '@chakra-ui/react';
import { FloatingInputField } from '@payler/ui-components';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import { useTranslation } from 'react-i18next';
import { SelectCurrency } from '../../components/SelectContractCurrency/SelectCurrency';
import {
  TCreatePayoutRequest,
  TCurrency,
  TExtendedContract,
} from '@payler/api/merchant-cabinet';
import { useSelectedContract } from '../../hooks/contracts';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';
import { TFunction } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';

import {
  useDropPayoutsQueryCache,
  useMakeCreditMutation,
} from '../../hooks/payouts/queries';
import { useUserPermissions } from '../../hooks/user/users';
import { useCurrencyCallbacks } from '../../hooks/currencies-info';
import { useToasts } from '../../hooks/use-toasts';
import { ExpDateField } from '../../components/ExpDateField/ExpDateField';
import { CvvField } from '../../components/CvvField/CvvField';
import { ObjectShape } from 'yup/lib/object';
import dayjs from 'dayjs';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';

type Props = {
  onSubmit?: VoidFunction;
};

type FormData = {
  amount: number | null;
  currency: TCurrency;
  cardNumber: string;
  password: string;
  expirationDate?: string;
  secureCode?: string;
};

const useGetDefaultValues = (): (() => FormData) => {
  const contract = useSelectedContract();

  return useCallback(() => {
    const currencies = contract?.currencyCodes ?? [];
    let currency: TCurrency = 'RUB';
    if (currencies.length > 0) {
      currency = currencies.includes('RUB') ? 'RUB' : (currencies[0] as string);
    }
    return {
      amount: null,
      currency,
      cardNumber: '',
      password: '',
    };
  }, [contract]);
};

function createResolver(t: TFunction, contract?: TExtendedContract) {
  const shape: ObjectShape = {
    amount: yup
      .number()
      .typeError(t('form.invalidAmount'))
      .required(t('form.invalidAmount'))
      .positive(t('form.invalidAmount')),
    password: yup.string().required(t('form.invalidPassword')),
    cardNumber: yup
      .string()
      .nullable()
      .required(t('validate.required', { ns: 'common' }))
      .min(16, t('form.invalidCard')),
  };
  if (contract?.octDetails?.expDateRequired) {
    shape.expirationDate = yup
      .string()
      .nullable()
      .test((value, ctx) => {
        if (!value)
          return ctx.createError({
            message: t('validate.required', { ns: 'common' }),
          });
        if (!value.match(/^\d{4}$/)) {
          return ctx.createError({
            message: t('validate.wrongFormat', { ns: 'common' }),
          });
        }
        const m = Number(value.slice(0, 2));
        const y = Number(value.slice(-2));
        if (m < 1 || m > 12) {
          return ctx.createError({
            message: t('validate.wrongFormat', { ns: 'common' }),
          });
        }
        const now = dayjs();
        const cardExpDate = dayjs(`${2000 + y}${m}`, 'YYYYMM').endOf('month');

        if (cardExpDate.isBefore(now)) {
          return ctx.createError({
            message: t('validate.expired', { ns: 'common' }),
          });
        }
        return true;
      });
  }
  if (contract?.octDetails?.cvvRequired) {
    shape.secureCode = yup
      .string()
      .nullable()
      .test((value, ctx) => {
        if (!value)
          return ctx.createError({
            message: t('validate.required', { ns: 'common' }),
          });
        if (!value.match(/^\d{3}$/)) {
          return ctx.createError({
            message: t('validate.wrongFormat', { ns: 'common' }),
          });
        }
        return true;
      });
  }

  return yupResolver(yup.object(shape));
}

export const FormComp = ({ onSubmit: onSubmitOuter }: Props) => {
  const [error, setError] = useState('');
  const contractId = useURLContractId();
  const contract = useSelectedContract();
  const getDefaultValues = useGetDefaultValues();
  const refreshPayouts = useDropPayoutsQueryCache();
  const makeCredit = useMakeCreditMutation();
  const toasts = useToasts();
  const getError = useGetAxiosError();
  const { t } = useTranslation('withdrawals');
  const methods = useForm<FormData>({
    defaultValues: getDefaultValues(),
    resolver: createResolver(t, contract),
  });
  const {
    reset,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const onSubmit = methods.handleSubmit(async (values) => {
    if (!contractId) return;
    const { expirationDate, secureCode, ...commonValues } = values;
    setError('');

    const data: TCreatePayoutRequest = {
      contractId,
      ...commonValues,
      amount: commonValues.amount ?? 0,
    };

    if (contract?.octDetails?.cvvRequired) {
      data.secureCode = secureCode;
    }

    if (contract?.octDetails?.expDateRequired && expirationDate) {
      data.expiredMonth = Number(expirationDate.slice(0, 2));
      data.expiredYear = Number(expirationDate.slice(-2));
    }

    await makeCredit.mutateAsync(data, {
      onError: (e: unknown) => {
        setError(getError(e));
        throw e;
      },
      onSuccess: () => {
        toasts.success(t('form.success'));
        refreshPayouts();
        onSubmitOuter?.();
      },
    });
  });
  const { canWithdraw } = useUserPermissions();
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <VStack alignItems="stretch" spacing={2}>
          <SelectCurrency name="currency" />
          <Amount />
          <FloatingInputField
            name="cardNumber"
            label={t('form.cardNumber')}
            variant="card"
            autoComplete="off"
            role="presentation"
          />
          {contract?.octDetails?.expDateRequired && (
            <ExpDateField
              name="expirationDate"
              label={t('form.expirationDate')}
              autoComplete="off"
              role="presentation"
            />
          )}
          {contract?.octDetails?.cvvRequired && (
            <CvvField
              name="secureCode"
              label="CVV"
              autoComplete="off"
              placeholder=" "
              role="presentation"
            />
          )}
          <FloatingInputField
            name="password"
            label={t('form.password')}
            type="password"
            autoComplete="new-password"
          />
          {error && <ApiErrorText>{error}</ApiErrorText>}
          <Tooltip
            label={!canWithdraw ? t('form.noAccess') : ''}
            shouldWrapChildren
          >
            <Button
              type="submit"
              isLoading={makeCredit.isPending}
              disabled={!canWithdraw}
            >
              {t('form.send')}
            </Button>
          </Tooltip>
        </VStack>
      </form>
    </FormProvider>
  );
};

const Amount = () => {
  const { watch } = useFormContext();
  const currencyValue = watch('currency');
  const { getCurrencyDecimalsCount } = useCurrencyCallbacks();
  const { getCurrencySymbol } = useCurrencyCallbacks();
  const { t } = useTranslation();
  return (
    <FloatingInputField
      currencySuffix={getCurrencySymbol(currencyValue)}
      variant="currency"
      name="amount"
      currencyDecimals={getCurrencyDecimalsCount(currencyValue)}
      label={t('withdrawals:form.amount', {
        currency: getCurrencySymbol(currencyValue),
      })}
      autoFocus
    />
  );
};

export const WithdrawForm = (props: Props) => {
  return (
    <Suspense fallback={null}>
      <FormComp {...props} />
    </Suspense>
  );
};
