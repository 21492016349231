import { useTranslation } from 'react-i18next';
import {
  Badge,
  ComponentStyleConfig,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import {
  Card,
  CardContent,
  CardHead,
  CardTitle,
  ScrollBarWithFade,
} from '@payler/ui-components';
import { emotionColors, TextStyles } from '@payler/ui-theme';
import { MayBe, TPaymentAntifraudData } from '@payler/api/merchant-cabinet';

const tableStyleConfig: ComponentStyleConfig = {
  baseStyle: {
    table: {
      minW: { base: '300px', xl: '800px' },
      maxW: { base: '100%', sm: 'unset' },
      width: '100%',
    },
    tr: {
      height: { base: '54px', sm: 9 },
      borderBottom: `0.5px solid ${emotionColors.primary100}`,
    },
    td: {
      textStyle: TextStyles.tables,
      px: 1,
      '&:first-of-type': {
        pl: { base: 2, sm: 3 },
        minW: '128px',
        w: '20%',
      },
      '&:last-of-type': {
        pr: { base: 2, sm: 3 },
      },
    },
  },
};

const badgeVariant = (result: string) => {
  switch (result) {
    case 'REJECT':
      return 'red';
    case 'ACCEPT':
      return 'green';
    default:
      return 'yellow';
  }
};

export const PaymentAntifraudData = ({
  antifraudDetails,
}: {
  antifraudDetails: MayBe<TPaymentAntifraudData>;
}) => {
  const { t } = useTranslation();
  if (!antifraudDetails) return null;
  return (
    <Card
      borderLeftWidth={{ base: 0, sm: 0.5 }}
      borderRightWidth={{ base: 0, sm: 0.5 }}
    >
      <CardHead>
        <CardTitle>{t('payments:antifraud.label')}</CardTitle>
      </CardHead>
      <CardContent p={0}>
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Tbody>
                <Tr>
                  <Td>{t('payments:antifraud.decision')}</Td>
                  <Td>
                    <Badge variant={badgeVariant(antifraudDetails.result)}>
                      {t(`payments:antifraud.${antifraudDetails.result}`)}
                    </Badge>
                  </Td>
                </Tr>
                {antifraudDetails.reason && (
                  <Tr>
                    <Td>{t('payments:antifraud.reason')}</Td>
                    <Td>{antifraudDetails.reason}</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      </CardContent>
    </Card>
  );
};

export default PaymentAntifraudData;
