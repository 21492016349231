import { chakra } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import dayjs from 'dayjs';
import React from 'react';

export const ReportDateCell = ({ date }: { date: string }) => {
  return (
    <chakra.span textStyle={TextStyles.tables}>
      {dayjs(date).format('HH:mm DD.MM.YYYY')}
    </chakra.span>
  );
};
