import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  chakra,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import NumberFormat from 'react-number-format';
import { RequiredStyledProvider } from '@payler/ui-components';

export interface InputFieldProps
  extends Omit<InputProps, 'defaultValue' | 'type'> {
  name: string;
  label: string;
  helpText?: string;
}

export const ExpDateFieldComp: React.FC<InputFieldProps> = ({
  name,
  label,
  className,
  helpText,
  ...rest
}) => {
  const { control, getFieldState, formState } = useFormContext();
  const errorMessage = getFieldState(name, formState).error?.message;
  const isError = !!errorMessage && !!formState.touchedFields;
  return (
    <FormControl isInvalid={isError} {...rest}>
      <RequiredStyledProvider>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value, name, ref } }) => (
            <NumberFormat
              getInputRef={ref}
              customInput={Input}
              format="##/##"
              variant="floating"
              placeholder="MM/YY"
              {...rest}
              name={name}
              value={value ?? ''}
              onValueChange={(v) => onChange(v.floatValue ?? null)}
            />
          )}
        />
        {label && <FormLabel variant="floating">{label}</FormLabel>}
        {helpText && (
          <FormHelperText
            color="primary.350"
            mt={1}
            textStyle={TextStyles.Caption12Regular}
            px={1.5}
          >
            {helpText}
          </FormHelperText>
        )}
        {isError && typeof errorMessage === 'string' && !rest.isDisabled && (
          <FormHelperText
            color="red.500"
            mt={1}
            textStyle={TextStyles.Caption12Regular}
            px={1.5}
          >
            {errorMessage}
          </FormHelperText>
        )}
      </RequiredStyledProvider>
    </FormControl>
  );
};
ExpDateFieldComp.displayName = 'ExpDateField';

/**
 * Поле для ввода срока действия карты
 */
export const ExpDateField = chakra(ExpDateFieldComp);
ExpDateField.displayName = 'ExpDateField';
