import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useCallback } from 'react';

/**
 * Сбросить фильтры (в урл и форме)
 */
export const useResetFormAndUrlFilters = () => {
  const [, setParams] = useSearchParams();
  const { reset } = useFormContext();
  return useCallback(() => {
    setParams(new URLSearchParams());
    reset();
  }, [reset, setParams]);
};
