import { useApi } from '../../context/ApiContextProvider';
import { useURLContractId } from '../../layouts/ContractIdLayout/ContractIdLayout';
import { usePagerPropsFromUrl } from '../pager/use-pager-props-from-url';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { groupByPredicate, assert } from '@payler/utils';
import dayjs from 'dayjs';
import { usePagerUrlCallbacks } from '../pager/use-pager-url-callbacks';
import { DEFAULT_PAGER_PROPS } from '../../const';
import { PaginationProps } from '@payler/ui-components';
import {
  useCryptoPaymentSessionIdFromUrl,
  useCryptoPaymentsFiltersFromUrl,
} from './url';

const CRYPTO_PAYMENTS_STALE_TIME = 60 * 1000;

export const useCryptoPaymentsQuery = () => {
  const api = useApi();
  const contractId = useURLContractId();
  const pagerData = usePagerPropsFromUrl();
  const filters = useCryptoPaymentsFiltersFromUrl();

  assert(!!contractId, 'Contract ID cannot be empty or undefined');

  return useQuery({
    queryKey: ['crypto-payments', contractId, pagerData, filters],
    queryFn: async () => {
      const data = await api.getCryptoPayments(contractId, {
        ...filters,
        ...pagerData,
      });

      const grouped = groupByPredicate(data.items, (v) => {
        const dateString = v['createdAt'] as string;
        return dayjs(dateString).format('YYYY-MM-DD');
      });
      return { ...data, grouped };
    },
    staleTime: CRYPTO_PAYMENTS_STALE_TIME,
  });
};

export const useCryptoPaymentsQueryPagerProps = () => {
  const payments = useCryptoPaymentsQuery();
  const { goToPage, setPageSize } = usePagerUrlCallbacks();
  if (payments.status !== 'success') {
    return { ...DEFAULT_PAGER_PROPS, goToPage, setPageSize } as PaginationProps;
  }
  const { page, pageSize, totalItems } = payments.data.paginationMetadata;
  return {
    goToPage,
    setPageSize,
    pageSize,
    currentPage: page,
    totalRecords: totalItems,
  } as PaginationProps;
};

export const useCryptoPaymentSessionQuery = () => {
  const api = useApi();
  const sessionId = useCryptoPaymentSessionIdFromUrl();
  return useSuspenseQuery({
    queryKey: ['session', sessionId],
    queryFn: async () => {
      const data = await api.getCryptoPaymentSession(sessionId);

      return data.session;
    },

    staleTime: CRYPTO_PAYMENTS_STALE_TIME,
  });
};
