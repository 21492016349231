export const reportsEn = {
  title: 'Reports',
  newReport: 'New report',
  reportCreated: 'Report added to processing',
  goToReports: 'Go to reports',
  create: 'Create',
  deleteReportModal: {
    cancel: 'Cancel',
    delete: 'Delete',
    description: 'Are you sure you want to delete this report?',
    title: 'Delete report',
  },
};

type TReportsTranslation = typeof reportsEn;

export const reportsRu: TReportsTranslation = {
  reportCreated: 'Отчет добавлен в обработку',
  goToReports: 'Перейти в отчёты',
  title: 'Отчёты',
  newReport: 'Новый отчёт',
  create: 'Создать',
  deleteReportModal: {
    cancel: 'Отмена',
    delete: 'Удалить',
    description: 'Вы действительно хотите удалить этот отчёт?',
    title: 'Удаление отчёта',
  },
};
