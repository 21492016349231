import React, { FC, PropsWithChildren } from 'react';
import {
  Box,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import { MobileModalCloseButton } from '../MobileModalCloseButton/MobileModalCloseButton';
import { ModalTitle } from '../ModalTitle/ModalTitle';

type PaylerModalProps = PropsWithChildren<{
  title?: string;
  close: VoidFunction;
  open: boolean;
  finalFocusRef?: ModalProps['finalFocusRef'];
  header?: JSX.Element;
  motionPreset?: ModalProps['motionPreset'];
}>;

export const PaylerModal: FC<PaylerModalProps> = ({
  close,
  open,
  title,
  children,
  finalFocusRef,
  header,
  motionPreset,
}) => {
  const modalSize = useBreakpointValue({ base: 'full', sm: 'md' });
  const padding = useBreakpointValue({ base: 2, sm: 4 });

  return (
    <Modal
      isOpen={open}
      onClose={close}
      size={modalSize}
      finalFocusRef={finalFocusRef}
      motionPreset={motionPreset}
    >
      <ModalOverlay />
      <ModalContent bg="secondary.500">
        {header ?? <MobileModalCloseButton close={close} />}
        <Box p={padding}>
          {title && <ModalTitle title={title} />}
          {children}
        </Box>
      </ModalContent>
    </Modal>
  );
};
