import { useTranslation } from 'react-i18next';
import React from 'react';
import { MultiDropdownField, TDropdownOption } from '@payler/ui-components';

export const Triggers = () => {
  const { t } = useTranslation('notifications');
  const options = useTriggerOptions();
  return (
    <MultiDropdownField
      fieldName="triggers"
      label={t('triggers')}
      options={options}
      selectAllLabel={t('allTriggers')}
      preset="mc1"
    />
  );
};

const useTriggerOptions = (): TDropdownOption[] => {
  const { t } = useTranslation('notificationKind');
  return [
    { value: 'Block', label: t('Block') },
    { value: 'Unblock', label: t('Unblock') },
    { value: 'Refund', label: t('Refund') },
    { value: 'Charge', label: t('Charge') },
    { value: 'Repeatpay', label: t('Repeatpay') },
    { value: 'Credit', label: t('Credit') },
    { value: 'Receipt', label: t('Receipt') },
    { value: 'Check', label: t('Check') },
    { value: 'Declined', label: t('Declined') },
    { value: 'CardSaved', label: t('CardSaved') },
  ];
};
