import React, { FC } from 'react';
import {
  Box,
  Breadcrumb,
  Button,
  ButtonProps,
  chakra,
  HStack,
  Tooltip,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { FullWidthBlock, PageTitle } from '../layouts';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { breakpoints, TextStyles } from '@payler/ui-theme';
import { NoData, Skeleton } from '@payler/ui-components';
import { PaymentTransaction } from '../components/PaymentTransaction/PaymentTransaction';
import { PaymentOperationsTable } from '../tables/PaymentOperationsTable/PaymentOperationsTable';
import { PaymentHistory } from '../components/PaymentHistory/PaymentHistory';
import ReactNumberFormat from 'react-number-format';
import { TTransaction } from '@payler/api/merchant-cabinet';
import TableSkeleton from '../components/TableSkeleton/TableSkeleton';
import { PaymentAntifraudData } from '../components/PaymentAntifraudData/PaymentAntifraudData';
import {
  ChargePaymentModal,
  useShowChargePaymentModal,
} from '../modals/ChargePaymentModal';
import {
  RefundPaymentModal,
  useShowRefundPaymentModal,
} from '../modals/RefundPaymentModal';
import {
  RetrievePaymentModal,
  useShowRetrievePaymentModal,
} from '../modals/RetrievePaymentModal';
import NotFoundPage from './NotFoundPage';
import { usePaymentDataQuery } from '../hooks/payments/queries';
import { useCurrencyCallbacks } from '../hooks/currencies-info';
import { useUserPermissions } from '../hooks/user/users';
import { Helmet } from 'react-helmet-async';
import { useURLContractId } from '../layouts/ContractIdLayout/ContractIdLayout';
import { useApi } from '../context/ApiContextProvider';
import { BreadcrumbBackButton } from '../components/BreadcrumbBackButton/BreadcrumbBackButton';

const Wrap = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: 'data' 'antifraud' 'operations' 'history';
  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 320px;
    grid-template-areas: 'data history' 'antifraud history' 'operations history' '. history';
  }
`;

const PageSkeleton = () => {
  return (
    <>
      <Skeleton h={4} w="200px" m={3} />
      <Wrap>
        <Box gridArea="data">
          <TableSkeleton />
        </Box>
        <Box gridArea="history">
          <Skeleton w={30} h={2} mb={2} />
          <Skeleton w={60} h={2} mb={2} />
          <Skeleton w={30} h={2} mb={2} />
          <Skeleton w={70} h={2} mb={2} />
        </Box>
      </Wrap>
    </>
  );
};

type PaymentPageMode =
  | 'payment'
  | 'antifraud'
  | 'payout'
  | 'makecredit'
  | 'bill';

type WithMode = { mode: PaymentPageMode };

export const PaymentPage: React.FC<WithMode> = ({ mode }) => {
  const { t } = useTranslation();
  const { id: routerId } = useParams<{ id?: string }>();
  const id = Number(routerId);
  const validId = id !== undefined && !isNaN(id);
  return (
    <>
      <Helmet title={t('titles:payment')} />
      {validId && <Header id={id} mode={mode} />}
      {validId ? <Contents id={id} /> : <NoData />}
      <ChargePaymentModal />
      <RefundPaymentModal />
      <RetrievePaymentModal />
    </>
  );
};

const Contents = ({ id }: { id: number }) => {
  const { status, data } = usePaymentDataQuery(id);
  switch (status) {
    case 'error':
      return <NotFoundPage />;
    case 'pending':
      return <PageSkeleton />;
    case 'success':
      return (
        <Wrap>
          <FullWidthBlock gridArea="data">
            <MobileButtons transaction={data.transaction} />
            <PaymentTransaction transaction={data.transaction} />
          </FullWidthBlock>
          <FullWidthBlock gridArea="antifraud">
            <PaymentAntifraudData antifraudDetails={data.antifraudDetails} />
          </FullWidthBlock>
          <FullWidthBlock gridArea="operations">
            <PaymentOperationsTable operations={data.operations} />
          </FullWidthBlock>
          <FullWidthBlock gridArea="history">
            <PaymentHistory history={data.history} />
          </FullWidthBlock>
        </Wrap>
      );
  }
};

const useBackUrl = (mode: PaymentPageMode) => {
  const contractId = useURLContractId();
  const location = useLocation();
  switch (mode) {
    case 'antifraud':
      return `/${contractId}/alerts/${location.search}`;
    case 'payout':
      return `/${contractId}/credit/${location.search}`;
    case 'makecredit':
      return `/${contractId}/makecredit/${location.search}`;
    case 'bill':
      return `/${contractId}/bill/${location.search}`;
    case 'payment':
    default:
      return `/${contractId}/pay/${location.search}`;
  }
};

const Header = ({ id, mode }: { id: number } & WithMode) => {
  const backUrl = useBackUrl(mode);
  const { getCurrencyUnitRate, getCurrencyDecimalsCount } =
    useCurrencyCallbacks();
  const { getCurrencySymbol } = useCurrencyCallbacks();

  const { status, data } = usePaymentDataQuery(id);
  const { t } = useTranslation();
  switch (status) {
    case 'pending':
      return null;
    case 'error':
      return null;
    case 'success': {
      const {
        transaction: { totalAmount, amountOfRefunds, currencyCode },
      } = data;
      const suffix = currencyCode
        ? `\u00a0${getCurrencySymbol(currencyCode)}`
        : '';
      return (
        <HStack
          alignItems="flex-end"
          justifyContent="space-between"
          mb={{ base: 2, sm: 5 }}
        >
          <div>
            <Breadcrumb separator={<>&mdash;</>}>
              <BreadcrumbBackButton to={backUrl} />
            </Breadcrumb>
            {currencyCode && (
              <VStack alignItems="flexStart" spacing={0}>
                <PageTitle mb={0}>
                  <ReactNumberFormat
                    suffix={suffix}
                    value={totalAmount / getCurrencyUnitRate(currencyCode)}
                    displayType="text"
                    isNumericString={true}
                    decimalSeparator={'.'}
                    decimalScale={getCurrencyDecimalsCount(currencyCode)}
                    allowLeadingZeros={true}
                    thousandSeparator={' '}
                    fixedDecimalScale={true}
                  />
                </PageTitle>
                {amountOfRefunds && (
                  <div>
                    <chakra.span
                      textStyle={TextStyles.Caption12Regular}
                      color="primary.350"
                    >
                      {t('payments:refunded')}:{' '}
                    </chakra.span>
                    <chakra.span
                      textStyle={TextStyles.Caption12Medium}
                      color="primary.500"
                    >
                      <ReactNumberFormat
                        suffix={suffix}
                        value={
                          amountOfRefunds / getCurrencyUnitRate(currencyCode)
                        }
                        displayType="text"
                        isNumericString={true}
                        decimalSeparator={'.'}
                        decimalScale={getCurrencyDecimalsCount(currencyCode)}
                        allowLeadingZeros={true}
                        thousandSeparator={' '}
                        fixedDecimalScale={true}
                      />
                    </chakra.span>
                  </div>
                )}
              </VStack>
            )}
          </div>
          <HeaderButtons transaction={data.transaction} />
        </HStack>
      );
    }
  }
};

const MobileButtons = ({ transaction }: { transaction: TTransaction }) => {
  const visible = useBreakpointValue({ base: true, md: false });
  const showChargeModal = useShowChargePaymentModal();
  const showRefundModal = useShowRefundPaymentModal();
  const showRetrieveModal = useShowRetrievePaymentModal();
  const { canDoPayments } = useUserPermissions();
  const { t } = useTranslation();
  if (!visible) return null;

  return (
    <HStack justifyContent="space-around" bg="brands.500" p={2}>
      {transaction.canCharge && canDoPayments && (
        <Button
          variant="link"
          color="secondary.500"
          onClick={() => showChargeModal(transaction.id)}
        >
          {t('payments:charge')}
        </Button>
      )}
      {transaction.canRetrieve && canDoPayments && (
        <Button
          variant="link"
          color="secondary.500"
          onClick={() => showRetrieveModal(transaction.id)}
        >
          {t('payments:reject')}
        </Button>
      )}
      {transaction.canRefund && canDoPayments && (
        <Button
          variant="link"
          color="secondary.500"
          onClick={() => showRefundModal(transaction.id)}
        >
          {t('payments:refund')}
        </Button>
      )}
      <DownloadPDFButton
        transactionId={transaction.id}
        filename={`${transaction.merchantOrderId}.pdf`}
        variant="link"
        color="secondary.500"
      />
    </HStack>
  );
};

const HeaderButtons = ({ transaction }: { transaction: TTransaction }) => {
  const { canDoPayments } = useUserPermissions();
  const showChargeModal = useShowChargePaymentModal();
  const showRefundModal = useShowRefundPaymentModal();
  const showRetrieveModal = useShowRetrievePaymentModal();
  const visible = useBreakpointValue({ base: false, md: true });
  const { t } = useTranslation();
  if (!visible) return null;

  return (
    <HStack spacing={2}>
      {transaction.canCharge && (
        <Tooltip label={!canDoPayments ? t('noAccess') : ''} shouldWrapChildren>
          <Button
            disabled={!canDoPayments}
            onClick={() => showChargeModal(transaction.id)}
          >
            {t('payments:charge')}
          </Button>
        </Tooltip>
      )}
      {transaction.canRetrieve && (
        <Tooltip label={!canDoPayments ? t('noAccess') : ''} shouldWrapChildren>
          <Button
            disabled={!canDoPayments}
            onClick={() => showRetrieveModal(transaction.id)}
          >
            {t('payments:reject')}
          </Button>
        </Tooltip>
      )}
      {transaction.canRefund && (
        <Tooltip label={!canDoPayments ? t('noAccess') : ''} shouldWrapChildren>
          <Button
            disabled={!canDoPayments}
            onClick={() => showRefundModal(transaction.id)}
          >
            {t('payments:refund')}
          </Button>
        </Tooltip>
      )}
      <DownloadPDFButton
        transactionId={transaction.id}
        filename={`${transaction.merchantOrderId}.pdf`}
      />
    </HStack>
  );
};

const DownloadPDFButton: FC<
  { transactionId: number; filename: string } & ButtonProps
> = ({ filename, transactionId, ...rest }) => {
  const { t } = useTranslation();
  const api = useApi();

  const handleDownloadButtonClick = async () => {
    await api.downloadPaymentPDF(transactionId, filename);
  };

  return (
    <Button type="button" onClick={handleDownloadButtonClick} {...rest}>
      {t('payments:downloadPDF')}
    </Button>
  );
};

export default PaymentPage;
