import React from 'react';
import { ReactComponent } from '*.svg';
import { Icon } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { emotionColors, emotionTextStyles } from '@payler/ui-theme';
import { ClassNames } from '@emotion/react';

const linkStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '16px 32px',
  alignSelf: 'stretch',
  color: emotionColors.primary500,
  ...emotionTextStyles.subtitle14medium,
  '&:hover': {
    textDecoration: 'none',
    outline: 'none',
    color: emotionColors.brands500,
  },
  '&:focus': {
    outline: 'none',
  },
};

const activeLinkStyle = {
  ...linkStyle,
  color: emotionColors.brands500,
};

const bottomLinkStyle = {
  ...linkStyle,
  color: emotionColors.primary400,
  '&:hover': {
    ...linkStyle['&:hover'],
    color: emotionColors.primary500,
    '& svg': {
      color: emotionColors.primary500,
    },
  },
};

const bottomLinkActiveStyle = {
  ...bottomLinkStyle,
  color: emotionColors.brands500,
  '&:hover': {
    color: emotionColors.brands500,
  },
  '& svg': {
    color: emotionColors.brands500,
  },
};

export const MenuLink: React.FC<{
  route: string;
  title: string;
  icon: typeof ReactComponent;
}> = ({ icon, route, title }) => {
  return (
    <ClassNames>
      {({ css }) => {
        const active = css(activeLinkStyle);
        const normal = css(linkStyle);
        return (
          <NavLink
            to={route}
            className={({ isActive }) => (isActive ? active : normal)}
          >
            <Icon as={icon} mr={2} w="20px" h="20px" color={'brands.500'} />
            {title}
          </NavLink>
        );
      }}
    </ClassNames>
  );
};

export const BottomMenuLink: React.FC<{
  route: string;
  title: string;
  icon: typeof ReactComponent;
}> = ({ icon, route, title }) => {
  return (
    <ClassNames>
      {({ css }) => {
        const active = css(bottomLinkActiveStyle);
        const normal = css(bottomLinkStyle);
        return (
          <NavLink
            to={route}
            className={({ isActive }) => (isActive ? active : normal)}
          >
            <Icon as={icon} mr={2} w="20px" h="20px" color={'primary.300'} />
            {title}
          </NavLink>
        );
      }}
    </ClassNames>
  );
};
