import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ALL_PAYMENT_INDICATORS } from '../const';

/**
 * Опции признаков платежа
 */
export const usePaymentIndicatorsOptions = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    const fieldName = t('fields:paymentIndicator');
    const options = ALL_PAYMENT_INDICATORS.map((x) => ({
      value: x,
      label: t(`paymentIndicator:${x}`),
    }));
    return { fieldName, options };
  }, [t]);
};
