import { TPayout } from '@payler/api/merchant-cabinet';
import {
  Badge,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import {
  ErrorBoundary,
  NoData,
  ScrollBarWithFade,
} from '@payler/ui-components';
import { TableSkeleton } from '../../components/TableSkeleton/TableSkeleton';
import { PaymentDatesCell } from './ReportDateCell';

import dayjs from 'dayjs';
import { useTimeAgo } from '../../hooks/use-time-ago';
import { useMerchantCabinetConfig } from '../../config/ConfigProvider';
import { getTransactionStatusBadgeVariant } from './getTransactionStatusBadgeVariant';
import { CardAndOrderIdCell } from './CardAndOrderIdCell';
import { AmountWithCurrencyCell } from './AmountWithCurrencyCell';
import { Images } from './Images';
import { TableFooter } from '../components/TableFooter';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Alert } from './Alert';
import { tableStyleConfig } from '../tableStyleConfig';
import { GroupRow } from './GroupRow';
import {
  usePaymentsQuery,
  usePaymentsQueryPagerProps,
} from '../../hooks/payments/queries';
import { useTranslation } from 'react-i18next';

export const PaymentsTable = () => {
  const props = usePaymentsQueryPagerProps();
  return (
    <>
      <TableWithData />
      <ErrorBoundary>
        <React.Suspense fallback={null}>
          <TableFooter paginationProps={props} />
        </React.Suspense>
      </ErrorBoundary>
    </>
  );
};

const TableWithData = () => {
  const { status, data } = usePaymentsQuery();
  switch (status) {
    case 'pending':
      return <TableSkeleton />;
    case 'error':
      return <NoData />;
    case 'success':
    default: {
      const empty = Object.entries(data.grouped).length === 0;
      if (empty) {
        return <NoData />;
      }
      return (
        <ScrollBarWithFade>
          <TableContainer position="relative">
            <Table styleConfig={tableStyleConfig}>
              <Header />
              <Tbody>
                {Object.entries(data.grouped)
                  .sort(([a], [b]) => -a.localeCompare(b))
                  .map(([group, rows]) => (
                    <Rows key={group} group={group} payments={rows} />
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </ScrollBarWithFade>
      );
    }
  }
};

const Rows = ({ group, payments }: { group: string; payments: TPayout[] }) => {
  const timeAgo = useTimeAgo();
  const { language } = useMerchantCabinetConfig();
  return (
    <>
      <tr>
        <GroupRow colSpan={5}>
          {dayjs(group).locale(language).format('dddd DD MMMM')}
          <Text as="span" color="primary.300">
            &nbsp;&nbsp;&mdash;&nbsp;&nbsp;{timeAgo.format(new Date(group))}
          </Text>
        </GroupRow>
      </tr>
      {payments.map((row) => (
        <Row data={row} key={row.id} />
      ))}
    </>
  );
};

const Row = ({ data }: { data: TPayout }) => {
  const { contractId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Tr
      onClick={() =>
        navigate(`/${contractId}/pay/${data.id}${location.search}`)
      }
    >
      <Td>
        <PaymentDatesCell
          bottomDate={data.firstOperationDate}
          topDate={data.lastOperationDate}
        />
      </Td>
      <Td>
        <Badge variant={getTransactionStatusBadgeVariant(data.status)}>
          {data.transactionStatusDescription}
        </Badge>
      </Td>
      <Td>
        <HStack spacing={0.5} alignItems="center">
          <span>{data.bankCountryCode}</span>
          <Images payment={data} />
        </HStack>
      </Td>
      <Td>
        <CardAndOrderIdCell data={data} />
      </Td>
      <Td textAlign="right">
        <HStack alignItems="center" spacing={1} justifyContent="flex-end">
          <AmountWithCurrencyCell
            amount={data.amount ?? 0}
            currencyCode={data.currencyCode}
            amountOfRefunds={data.refundsAmount ?? 0}
          />
          <Alert data={data} />
        </HStack>
      </Td>
    </Tr>
  );
};

const Header = () => {
  const { t } = useTranslation('tableHeader');
  return (
    <Thead>
      <Tr>
        <Th>{t('period')}</Th>
        <Th>{t('status')}</Th>
        <Th>{t('code')}</Th>
        <Th textAlign="right">{t('cardAndOrderId')}</Th>
        <Th textAlign="right">{t('amount')}</Th>
      </Tr>
    </Thead>
  );
};

export default PaymentsTable;
