import { TEditUserForm } from './types';
import { FormProvider, useForm } from 'react-hook-form';
import {
  DropdownField,
  FloatingInputField,
  LoadingBox,
  MultiDropdownField,
  ThinDivider,
} from '@payler/ui-components';
import { useTranslation } from 'react-i18next';
import { Button, Grid, GridItem, HStack, Text, VStack } from '@chakra-ui/react';
import { useMerchantContractsOptions } from '../../hooks/contracts';
import React, { FC, memo, Suspense, useEffect, useMemo, useState } from 'react';
import { TextStyles } from '@payler/ui-theme';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateChildUserDataMutation } from '../../hooks/user/users';
import { ApiErrorText } from '../../components/ApiErrorText/ApiErrorText';
import {
  TApplicationUser,
  TUpdateUserRequest,
  TUserClaimType,
  TUserClaimValue,
} from '@payler/api/merchant-cabinet';
import { useToasts } from '../../hooks/use-toasts';
import { useGetAxiosError } from '../../hooks/use-get-axios-error';

type Props = {
  onCancel?: VoidFunction;
  onSubmit?: VoidFunction;
  user: TApplicationUser;
};

const useResolver = () => {
  const { t } = useTranslation();
  return useMemo(() => {
    return yupResolver(
      yup.object({
        name: yup
          .string()
          .required(t('users:invalidName'))
          .min(2, t('uses:invalidName'))
          .max(120, t('users:invalidName')),
        email: yup
          .string()
          .email(t('users:invalidEmail'))
          .required(t('users:invalidEmail')),
      }),
    );
  }, [t]);
};

const Comp: FC<Props> = ({ onCancel, onSubmit, user }) => {
  const [error, setError] = useState('');
  const getError = useGetAxiosError();
  const { t } = useTranslation('users');
  const toasts = useToasts();
  const { mutateAsync, isPending: isLoading } =
    useUpdateChildUserDataMutation();
  const resolver = useResolver();
  const methods = useForm<TEditUserForm>({
    defaultValues: userToFormData(user),
    resolver,
  });

  useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      onSubmit?.();
    }
  }, [methods.formState.isSubmitSuccessful, onSubmit]);

  const handleSubmit = methods.handleSubmit(async (values) => {
    setError('');
    await mutateAsync(
      { id: user.id, data: prepareRequest(values) },
      {
        onSuccess: () => {
          toasts.success(t('updated'));
          onSubmit?.();
        },
        onError: (e) => {
          const error = getError(e);
          setError(error);
          toasts.error(error);
          throw new Error(error);
        },
      },
    );
  });

  const claimOptions = useMemo(() => {
    return [
      {
        label: t('roles:claimValues.ALL'),
        value: 'ALL',
      },
      {
        label: t('roles:claimValues.READ'),
        value: 'READ',
      },
    ];
  }, [t]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit}>
        <VStack alignItems="stretch" spacing={2}>
          <Grid
            gridTemplateColumns={{
              base: 'minmax(0, 1fr)',
              xsm: 'repeat(2, minmax(0, 1fr))',
            }}
            gridGap={2}
          >
            <GridItem>
              <FloatingInputField
                name="name"
                label={t('name')}
                autoComplete="off"
                autoFocus
              />
            </GridItem>
            <GridItem>
              <FloatingInputField
                name="email"
                label={t('email')}
                autoComplete="off"
                isDisabled
              />
            </GridItem>
            <GridItem gridColumn={{ base: 1, xsm: '1 /span 2' }}>
              <Contracts />
            </GridItem>
          </Grid>
          <ThinDivider mx={-3} />
          <Text textStyle={TextStyles.h4}>{t('options')}</Text>
          <Grid
            gridTemplateColumns={{
              base: 'minmax(0,1fr)',
              xsm: 'repeat(2, minmax(0, 1fr))',
              md: 'minmax(0,1fr)',
              lg: 'repeat(2, minmax(0, 1fr))',
              xl: 'repeat(3, minmax(0, 1fr))',
            }}
            gridGap={2}
          >
            <DropdownField
              fieldName="newCredit"
              floating
              label={t('roles:claims.NEW_CREDIT')}
              options={claimOptions}
              hideClearButton
            />
            <DropdownField
              fieldName="newPayment"
              floating
              label={t('roles:claims.NEW_PAYMENT')}
              options={claimOptions}
              hideClearButton
            />
            <DropdownField
              fieldName="reports"
              floating
              label={t('roles:claims.REPORTS')}
              options={claimOptions}
              hideClearButton
            />
            <DropdownField
              fieldName="payments"
              floating
              label={t('roles:claims.PAYMENTS')}
              options={claimOptions}
              hideClearButton
            />
            <DropdownField
              fieldName="settingsNotifications"
              floating
              label={t('roles:claims.SETTING_NOTIFICATION')}
              options={claimOptions}
              hideClearButton
            />
            <DropdownField
              fieldName="settingsPayment"
              floating
              label={t('roles:claims.SETTING_PAYMENT')}
              options={claimOptions}
              hideClearButton
            />
          </Grid>
          {error && <ApiErrorText>{error}</ApiErrorText>}
          <HStack
            pt={3}
            alignSelf={{ base: 'stretch', sm: 'flex-start' }}
            spacing={2}
          >
            <Button
              flex={{ base: 1, sm: undefined }}
              onClick={handleSubmit}
              isLoading={isLoading}
              isDisabled={!methods.formState.isDirty}
            >
              {t('updateUser')}
            </Button>
            <Button
              flex={{ base: 1, sm: undefined }}
              variant="secondary"
              onClick={onCancel}
            >
              {t('cancel')}
            </Button>
          </HStack>
        </VStack>
      </form>
    </FormProvider>
  );
};

export const EditUserForm = memo((props: Props) => (
  <Suspense fallback={<LoadingBox h="700px" />}>
    <Comp {...props} />
  </Suspense>
));

export default EditUserForm;

const Contracts = () => {
  const { t } = useTranslation();
  const options = useMerchantContractsOptions();
  return (
    <MultiDropdownField
      options={options}
      preset="mc3"
      fieldName="contractIds"
      selectAllLabel={t('allContracts')}
      label={t('users:contracts')}
    />
  );
};

const prepareRequest = (formData: TEditUserForm): TUpdateUserRequest => {
  return {
    name: formData.name,
    options: {
      NEW_CREDIT: formData.newCredit,
      NEW_PAYMENT: formData.newPayment,
      PAYMENTS: formData.payments,
      REPORTS: formData.reports,
      SETTING_NOTIFICATION: formData.settingsNotifications,
      SETTING_PAYMENT: formData.settingsPayment,
    },
    contractIds: formData.contractIds,
  };
};

const getClaimValue = (
  user: TApplicationUser,
  type: TUserClaimType,
): TUserClaimValue => {
  return user.claims.find((x) => x.type === type)?.value ?? 'READ';
};

const userToFormData = (user: TApplicationUser): TEditUserForm => {
  return {
    name: user.name,
    email: user.email,
    contractIds: user.contractIds,
    newCredit: getClaimValue(user, 'NEW_CREDIT'),
    newPayment: getClaimValue(user, 'NEW_PAYMENT'),
    settingsPayment: getClaimValue(user, 'SETTING_PAYMENT'),
    settingsNotifications: getClaimValue(user, 'SETTING_NOTIFICATION'),
    reports: getClaimValue(user, 'REPORTS'),
    payments: getClaimValue(user, 'PAYMENTS'),
  };
};
