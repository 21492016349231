import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Button, HStack, Icon } from '@chakra-ui/react';
import { FloatingInputField } from '@payler/ui-components';
import { CrossIcon } from '@payler/ui-icons';
import React, { useEffect } from 'react';

export const Emails = () => {
  const { t } = useTranslation('notifications');
  const { control, watch } = useFormContext();
  const visible = watch('notificationType') === 'Email';
  const { fields, append, remove } = useFieldArray({ name: 'emails', control });
  const cursor = fields.length > 1 ? 'pointer' : 'not-allowed';
  const colorDeleteHover = fields.length > 1 ? 'red.500' : 'primary.300';
  useEffect(() => {
    if (visible && fields.length === 0) {
      append('');
    }
  }, [visible, fields, append]);
  if (!visible) return null;
  return (
    <>
      {fields.map((x, i) => (
        <HStack key={`${i}.${x}`} alignItems="flex-start">
          <FloatingInputField
            name={`emails.${i}`}
            label={t('recipients')}
            autoComplete="off"
            autoFocus
          />
          {fields.length > 1 && (
            <Box pt={2}>
              <Icon
                role="button"
                as={CrossIcon}
                w={3}
                h={3}
                cursor={cursor}
                color="primary.300"
                _hover={{ color: colorDeleteHover }}
                onClick={() => remove(i)}
              />
            </Box>
          )}
        </HStack>
      ))}
      <Button
        alignSelf="flex-start"
        justifySelf="flex-start"
        variant="link"
        onClick={() => append('')}
        _hover={{ textDecoration: 'none' }}
      >
        {t('addEmail')}
      </Button>
    </>
  );
};
