import { TStep, useChartStepContext } from '../../../context/ChartStepContext';
import { useChartQueryParamsByStep } from '../useChartQueryParamsByStep';
import { usePaymentAnalyticsQuery } from '../../../hooks/analytics';
import { useMemo } from 'react';
import { TAnalyticsDataResponse } from '@payler/api/merchant-cabinet';
import dayjs from 'dayjs';

/**
 * Данные для ChartAverageCharge (по дням, неделям и месяцам)
 */
export const useAverageChargeChartData = () => {
  const { step } = useChartStepContext();
  const params = useChartQueryParamsByStep();

  const { data: response, isLoading } = usePaymentAnalyticsQuery(params);

  return useMemo(() => {
    const data = responseToData(response, step);
    return {
      data,
      currency: params.CurrencyCode,
      isLoading,
    };
  }, [isLoading, params.CurrencyCode, response, step]);
};

type T = Record<string, { count: number; sum: number }>;

function responseToData(
  response: TAnalyticsDataResponse | undefined,
  step: TStep,
) {
  switch (step) {
    case 'weeks': {
      const reduced = response?.dailyPaymentsAnalytics?.reduce<T>((acc, v) => {
        const week = dayjs(v.date).endOf('week').format('YYYY-MM-DD');
        let count = acc[week]?.count ?? 0;
        let sum = acc[week]?.sum ?? 0;
        count += v.charged.totalCount;
        sum += v.charged.totalSum;
        acc[week] = { count, sum };
        return acc;
      }, {});
      const data = reduced
        ? Object.entries(reduced).map(([week, v]) => ({
            date: week,
            avg: v.count > 0 ? v.sum / v.count : 0,
          }))
        : undefined;
      const totalAvg = responseToTotalChargeAverage(response);
      return { data, totalAvg };
    }
    case 'months': {
      const reduced = response?.dailyPaymentsAnalytics?.reduce<T>((acc, v) => {
        const month =
          dayjs(v.date).get('year') +
          '-' +
          (dayjs(v.date).get('month') + 1) +
          '-01';
        let count = acc[month]?.count ?? 0;
        let sum = acc[month]?.sum ?? 0;
        count += v.charged.totalCount;
        sum += v.charged.totalSum;
        acc[month] = { count, sum };
        return acc;
      }, {});
      const data = reduced
        ? Object.entries(reduced)
            .map(([month, v]) => ({
              date: month,
              avg: v.count > 0 ? v.sum / v.count : 0,
            }))
            .sort((a, b) => {
              return dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1;
            })
        : undefined;
      const totalAvg = responseToTotalChargeAverage(response);
      return { totalAvg, data };
    }
    case 'days':
    default: {
      const data = response?.dailyPaymentsAnalytics?.map((x) => ({
        date: x.date,
        avg:
          x.charged.totalCount > 0
            ? Math.round(x.charged.totalSum / x.charged.totalCount)
            : 0,
      }));
      const totalAvg = responseToTotalChargeAverage(response);
      return { data, totalAvg };
    }
  }
}

function responseToTotalChargeAverage(
  response: TAnalyticsDataResponse | undefined,
) {
  const tmp = response?.dailyPaymentsAnalytics?.reduce(
    (acc, current) => {
      acc.sum += current.charged.totalSum;
      acc.count += current.charged.totalCount;
      return acc;
    },
    {
      sum: 0,
      count: 0,
    },
  );
  if (!tmp || tmp.count === 0) return 0;
  const { sum, count } = tmp;
  return sum / count;
}
