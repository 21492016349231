import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RecoilRoot } from 'recoil';
import { ApiContextProvider } from '../context/ApiContextProvider';
import {
  AuthContextProvider,
  useIsAuthorized,
} from '../context/AuthContextProvider';

const RecoilRefreshContext = createContext({
  reset: () => {
    /** noop */
  },
});

export const useResetRecoil = () => useContext(RecoilRefreshContext).reset;

/**
 * Сбросить состояние recoil при логауте
 * @constructor
 */
const WatchNotAuthorized = () => {
  const isAuthorized = useIsAuthorized();
  const prev = useRef(isAuthorized);
  const reset = useResetRecoil();

  useEffect(() => {
    if (!isAuthorized && prev.current !== isAuthorized) {
      reset();
    }
    prev.current = isAuthorized;
  }, [isAuthorized, reset]);

  return null;
};

export const AppState: FCC = ({ children }) => {
  const [ts, setTs] = useState(() => new Date().getTime());

  const reset = useCallback(() => {
    setTs(new Date().getTime());
  }, []);

  return (
    <ApiContextProvider>
      <AuthContextProvider>
        <RecoilRefreshContext.Provider value={{ reset }}>
          <RecoilRoot key={ts}>
            <WatchNotAuthorized />
            {children}
          </RecoilRoot>
        </RecoilRefreshContext.Provider>
      </AuthContextProvider>
    </ApiContextProvider>
  );
};
