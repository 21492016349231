import {
  Button,
  chakra,
  HStack,
  Icon,
  Menu,
  MenuButtonProps,
  MenuList,
  useMenuButton,
  useMenuState,
  VStack,
} from '@chakra-ui/react';
import React, { forwardRef, useState } from 'react';
import { TextStyles } from '@payler/ui-theme';
import { ChevronDownIcon } from '@payler/ui-icons';
import { useTranslation } from 'react-i18next';
import { ThinDivider } from '@payler/ui-components';
import { useContracts } from '../../../hooks/contracts';
import { CustomCheckbox } from './CustomCheckbox';
import { useAnalyticsPageContext } from '../../../context/AnalyticsPageContext';

const Btn = forwardRef<HTMLDivElement, MenuButtonProps>((props, ref) => {
  const { isOpen } = useMenuState();
  const { t } = useTranslation();
  const {
    params: { CurrencyCode },
  } = useAnalyticsPageContext();
  const buttonProps = useMenuButton(props, ref);
  return (
    <HStack
      {...buttonProps}
      bg="primary.25"
      borderRadius={1.5}
      border="1px solid"
      borderColor="primary.100"
      px={2}
      py={1}
      h="56px"
      userSelect="none"
      justifyContent="space-between"
    >
      <VStack alignItems="stretch" spacing={0} minW={0}>
        <Label>{t('fields:currency')}</Label>
        <Value>{t(`currency:${CurrencyCode}`)}</Value>
      </VStack>
      <Icon
        as={ChevronDownIcon}
        color="primary.350"
        w="20px"
        h="20px"
        transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
        transitionDuration="normal"
      />
    </HStack>
  );
});

const Label = chakra('div', {
  baseStyle: {
    textStyle: TextStyles.Caption12Regular,
    color: 'primary.350',
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
});
const Value = chakra('div', {
  baseStyle: {
    textStyle: TextStyles.BodyUI16Regular,
    color: 'primary.500',
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const CurrenciesDropdown = () => {
  return (
    <Menu isLazy preventOverflow>
      <Btn w="150px" />
      <MenuList
        py={0}
        pb={2}
        bg="secondary.500"
        borderRadius={1.5}
        border="1px solid"
        borderColor="primary.100"
      >
        <Body />
      </MenuList>
    </Menu>
  );
};

const Body = () => {
  const contracts = useContracts();
  const {
    params: { ContractIds, CurrencyCode },
  } = useAnalyticsPageContext();
  const allCurrencies =
    contracts
      ?.filter((x) => ContractIds.includes(x.id))
      .flatMap((x) => x.currencyCodes)
      .filter((x, i, a) => i === a.indexOf(x)) ?? [];

  const { onClose } = useMenuState();
  const { t } = useTranslation();
  const { setParams } = useAnalyticsPageContext();
  const [value, setValue] = useState(() =>
    allCurrencies.includes(CurrencyCode)
      ? CurrencyCode
      : (allCurrencies[0] as string),
  );

  const submit = () => {
    setParams((v) => ({ ...v, CurrencyCode: value }));
    onClose();
  };

  return (
    <>
      <VStack spacing={0} alignItems="stretch" divider={<ThinDivider />}>
        {allCurrencies.map((c) => (
          <CustomCheckbox
            key={c}
            selected={value === c}
            text={t(`currency:${c}`)}
            onClick={() => setValue(c)}
          />
        ))}
      </VStack>
      <ThinDivider mb={2} />

      <HStack justifyContent="stretch" px={2} spacing={2}>
        <Button variant="secondary" size="small" onClick={onClose} flex={1}>
          {t('cancel')}
        </Button>
        <Button
          onClick={submit}
          flex={1}
          size="small"
          isDisabled={value.length === 0}
        >
          {t('apply')}
        </Button>
      </HStack>
    </>
  );
};
