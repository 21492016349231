import { TTransactionStatus } from '@payler/api/merchant-cabinet';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { ALL_PAYMENT_TRANSACTION_STATUSES } from '../const';

/**
 * Опции статусов транзакции для выпадающего списка фильтров
 * @param allowed - опции которые показывать, иначе все
 */
export const useTransactionsStatusOptions = (
  allowed?: TTransactionStatus[],
) => {
  const { t } = useTranslation();
  return useMemo(() => {
    const fieldName = t('fields:transactionStatus');
    const selectAllText = t('transactionStatus:allStatuses');
    const statusesToShow = allowed
      ? allowed.filter((x) => ALL_PAYMENT_TRANSACTION_STATUSES.includes(x))
      : ALL_PAYMENT_TRANSACTION_STATUSES;
    const options = statusesToShow.map((x) => ({
      value: x,
      label: t(`transactionStatus:${x}`),
    }));
    return { options, fieldName, selectAllText };
  }, [allowed, t]);
};
