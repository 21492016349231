export const getNumericRangeKey = (from: unknown, to: unknown) => {
  if (!from && !to) {
    return '';
  }
  let key = 'sumRange.fromTo';
  if (!to) {
    key = 'sumRange.from';
  }
  if (!from) {
    key = 'sumRange.to';
  }
  return key;
};

export const getRangeKey = (from: unknown, to: unknown, emptyKey?: string) => {
  let key = 'range.fromTo';
  if (!from) {
    key = 'range.to';
  }
  if (!to) {
    key = 'range.from';
  }
  if (!from && !to) {
    key = emptyKey ?? 'range.empty';
  }
  return key;
};
