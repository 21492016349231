import { chakra } from '@chakra-ui/react';
import { MENU_WIDTH } from '../BaseLayout/const';

export const FullWidthSettingsBlock = chakra('div', {
  baseStyle: {
    mx: { base: -2, sm: 0 },
    boxSizing: 'content-box',
    maxW: {
      base: '100vw',
      md: `calc(100vw - ${MENU_WIDTH * 2}px - 48px)`,
    },
    overflowX: 'hidden',
  },
});
