import { useMerchantCabinetConfig } from '../../config/ConfigProvider';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';

export const GoogleTagManager = () => {
  const config = useMerchantCabinetConfig();
  useEffect(() => {
    TagManager.initialize({
      gtmId: config.googleTagManagerId,
    });
  }, [config.googleTagManagerId]);
  return null;
};

export default GoogleTagManager;
