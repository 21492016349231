import { t } from 'i18next';
import React from 'react';
import { createRecoilKey } from '../helpers/createKey';
import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import ChargePaymentForm from '../forms/ChargePaymentForm/ChargePaymentForm';
import { PaylerModal } from '@payler/ui-components';

const chargePaymentModalOpenedAtom = atom<number | undefined>({
  key: createRecoilKey('chargePaymentModalOpenedAtom'),
  default: undefined,
});

export const useShowChargePaymentModal = () =>
  useRecoilCallback(
    ({ set }) =>
      (id: number) => {
        set(chargePaymentModalOpenedAtom, id);
      },
    [],
  );

export const useCloseChargePaymentModal = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        set(chargePaymentModalOpenedAtom, undefined);
      },
    [],
  );

export const ChargePaymentModal = () => {
  const id = useRecoilValue(chargePaymentModalOpenedAtom);
  const close = useCloseChargePaymentModal();
  return (
    <PaylerModal close={close} open={!!id} title={t('payments:chargeTitle')}>
      {!!id && <ChargePaymentForm id={id} onSubmit={close} />}
    </PaylerModal>
  );
};
