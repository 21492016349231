import { useSelectedContract } from '../hooks/contracts';
import { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigateRef } from '../hooks/use-navigate-ref';
import { useURLContractId } from '../layouts/ContractIdLayout/ContractIdLayout';

const NavWatcher = () => {
  const { pathname } = useLocation();
  const navigate = useNavigateRef();
  const contract = useSelectedContract();
  const contractId = useURLContractId();

  useEffect(() => {
    if (contract && contractId === contract.id) {
      const id = contract.id;
      const navigateToFirstLink = () => {
        console.log('navigateToFirstLink', pathname, contract);
        if (contract.debitEnabled) {
          navigate.current(`/${id}/pay`);
        } else if (contract.creditEnabled) {
          navigate.current(`/${id}/credit`);
        } else if (contract.antifraudEnabled) {
          navigate.current(`/${id}/alerts`);
        } else if (contract.fasterPaymentsEnabled) {
          navigate.current(`/${id}/faster-payments`);
        } else if (contract.apmPaymentsEnabled) {
          navigate.current(`/${id}/apm-payments`);
        } else {
          navigate.current('/');
        }
      };
      if (pathname.endsWith('/pay') && !contract.debitEnabled) {
        navigateToFirstLink();
      } else if (pathname.endsWith('/bill') && !contract.debitEnabled) {
        navigateToFirstLink();
      } else if (pathname.endsWith('/makecredit') && !contract.creditEnabled) {
        navigateToFirstLink();
      } else if (pathname.endsWith('/credit') && !contract.creditEnabled) {
        navigateToFirstLink();
      } else if (
        pathname.endsWith('/faster-payments') &&
        !contract.fasterPaymentsEnabled
      ) {
        navigateToFirstLink();
      } else if (pathname.endsWith('/alerts') && !contract.antifraudEnabled) {
        navigateToFirstLink();
      }
    }
  }, [contract, navigate, pathname, contractId]);
  return null;
};

/**
 * Пока так, пока не определимся с навигацией
 * @constructor
 */
export const WatchNavigation = () => {
  return (
    <Suspense fallback={null}>
      <NavWatcher />
    </Suspense>
  );
};
